import React from "react";

const CareerAdvancement = () => {
  const benefits = [
    {
      title: "Flexbility",
      content:
        "VigiLearn offers you the opportunity to learn at your own pace. Whether you are a working-class individual or a student who cannot attend physical classes, courses scheduled on VigiLearn are flexible enough to fit your daily life.",
    },
    {
      title: "Accessibility",
      content:
        "Literally, distance is no barrier to learning on VigiLearn. You can take classes from the comfort of your phone wherever you are and at any time of the day. All you need is a smart device and a stable internet connection",
    },
    {
      title: "Lower Cost",
      content:
        "VigiLearn helps you save time and resources spent commuting to and from brick-and-mortar institutions. Degrees and courses offered are generally less expensive than traditional institutions plus you can choose the course to pay for",
    },
    {
      title: "Work and Earn",
      content:
        "Want to get another degree to improve your resume without quitting work? VigiLearn is your best bet! You can work while you learn.",
    },
  ];
  return (
    <>
      <p>
        When it comes to learning online, there are loads of choices. However,
        as a professional and with hectic work and life schedules, finding one
        that speaks your language and which you can take at your pace can be
        overwhelming. Vigilearn is here to help.
      </p>
      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">
        What is VigiLearn?{" "}
      </h4>
      <p className="mt-4">
        VigiLearn is an online learning management platform that gives you
        access to an array of Bachelors and Masters degree courses, diplomas as
        well as professional certification courses. Even better, courses are
        offered by distinguished universities around the world so everyone can
        access quality education that answers their needs irrespective of their
        age, location, or gender.{" "}
      </p>
      <p className="mt-4">
        Although work and experiences are important, getting extra
        certifications often gives you an edge and statistics have shown that
        getting the right kind of certification can boost your pay by at least
        50 percent.
      </p>
      <p className="mt-4">
        While deciding what course to take might not really be a concern,
        finances can also be a deterring factor. Not to worry, through
        VigiLearn, you have access to{" "}
        <a
          href="https://educollectfinance.com"
          target="_blank"
          rel="noreferrer"
        >
          EduCollect
        </a>
        , our partner platform where there are various loans available
        specifically for continuing education and you ensure uninterrupted
        access to your selected course content and classes.
      </p>
      <h5 className="mt-5 mb-3 font-weight-bold">
        Benefits Of Studying On VigiLearn
      </h5>
      <ul>
        {benefits.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>
      <p className="mt-5">
        New professional courses on occupational safety, sales &amp; marketing,
        dynamic leadership, and much more, are now also available on VigiLearn.
        Get certified by the prestigious MENA College of Management, Dubai in
        under five weeks.
        {/* under five weeks. Visit our website{" "}
        <a href="https://mcm.vigilearnlms.com" target="_blank" rel="noreferrer">
          here
        </a>{" "}
        to learn more.{" "} */}
      </p>
    </>
  );
};

export default CareerAdvancement;
