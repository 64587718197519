import React from "react";
import "../../assets/styles/pageStyles/programs.scss";
import CoursesComponent from "../../components/CoursesComponent";

const Programs = (props) => {

  return (
    <div style={{ marginBottom: "4rem" }}>
      <div className="programs-banner bg-blue-primary">
        <div className="container ">
          <div className="intro-text" style={{ color: "#FFFFFF" }}>
            <h2 className="">Programs</h2>
            <p >
              Increase your value by studying for a globally acclaimed degree
              program or certification course through our user-friendly learning
              platform
            </p>
          </div>
        </div>
      </div>
      <CoursesComponent existingData={props.location?.state} />
    </div>
  );
};

export default Programs;
