import React, { useState } from "react";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { Modal } from "react-bootstrap";

import "../assets/styles/pageStyles/manipalProgram.scss";

const ManipalProgram = (props) => {
  const { learnMore, name, firstJotForm, secondJotForm } =
    props.location?.state;

  const [showForm, setShowForm] = useState(null);

  console.log("jot jot", firstJotForm, secondJotForm);

  return (
    <div id="manipal-moreInfo">
      <div className="bg-blue-primary program-banner">
        <div className="container">
          <Link to="/">
            <p
              className="back-cta"
              style={{ cursor: "pointer", color: "#FFFFFF" }}
            >
              <MdArrowBack fontSize="1.6rem" />
              &nbsp; Back
            </p>
          </Link>

          <div className=" course-intro">
            <div className="course-intro-header">
              <h2>{name}</h2>
              <div className="d-flex justify-content-between align-items-center course-intro-ctas">
                <div className="col-12 col-sm-6">
                  <Button
                    label="Apply"
                    size="sm"
                    fullWidth
                    variant="secondary"
                    onClick={() => {
                      if (firstJotForm) {
                        setShowForm("jotform-1");
                      } else if (secondJotForm) {
                        setShowForm("jotform-2");
                      } else {
                        setShowForm("jotform-3");
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-sm-6">
                  {/* change the link to what is specified */}
                  <a href={"/"} download>
                    <Button
                      label="Brochure"
                      size="sm"
                      fullWidth
                      variant="primary"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="course-details container">
        <div className="course-overview">
          <div>
            <h3>About us</h3>
            <p>{learnMore.aboutUs}</p>
          </div>

          <div className="whyUs">
            <h3>Why study with us </h3>
            <ul>
              {learnMore.whyUs.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="programmes">
            <h3>Programmes</h3>
            {learnMore.programme.map((item) => {
              return (
                <>
                  <h5 className="program-title">{item.title}</h5>
                  <p>{item.aboutProgramme}</p>
                  {item.programGroups &&
                    item.programGroups.map((s) => {
                      return (
                        <>
                          <h6 className="programGroup-title">{s.title}</h6>
                          {s.programmes.length > 1 ? (
                            <ul>
                              {s.programmes.map((j, index) => (
                                <li key={index} className="custom-list">{j}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>{s.programmes[0]}</p>
                          )}
                        </>
                      );
                    })}
                </>
              );
            })}
          </div>

          <div className="payment">
            <h3>Mode of Payment</h3>
            <ul>
              {learnMore.modeOfPayment.map((item, index) => (
                <li key={index} style={{listStyle:"disc"}}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-1"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836780080557"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836780080557"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-2"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836377964570"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836377964570"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-3"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836592062558"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836592062558"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default ManipalProgram;
