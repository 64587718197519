import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { GoChevronRight, GoChevronDown } from "react-icons/go";
import { Collapse } from "@material-ui/core";

const NavMenus = ({ mobile, ...rest }) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const path = location.pathname;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const history = useHistory();

  const getSubMenu = () => {
    return (
      <div className="sub-menu">
        <Link to="/student-information-system">
          <div
            className={`sub-menu-item  ${
              path === "/student-information-system"
                ? "sub-menu-item--active"
                : ""
            }`}
          >
            <span>Student Information System</span>
          </div>
        </Link>

        <Link to="/learning-management-system">
          <div
            className={`sub-menu-item ${
              path === "/learning-management-system"
                ? "sub-menu-item--active"
                : ""
            }`}
          >
            <span>Learning Management System</span>
          </div>
        </Link>

        <Link to="/application-portal">
          <div
            className={`sub-menu-item ${
              path === "/application-portal" ? "sub-menu-item--active" : ""
            }`}
          >
            <span>Application Portal</span>
          </div>
        </Link>

        <Link to="/examination-portal">
          <div
            className={`sub-menu-item ${
              path === "/examination-portal" ? "sub-menu-item--active" : ""
            }`}
          >
            <span>Examination Portal</span>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div {...rest}>
      <ul className={`nav d-flex nav-items ${mobile ? "flex-column" : ""}`}>
        <Link to="/about">
          <li
            className={`nav-item header-item ${
              path === "/about" ? "nav-item--active" : ""
            }`}
          >
            About Us
          </li>
        </Link>
        {!mobile ? (
          <div className="dropdown nav-text header-item">
            <Link
              to="/products"
              className={`nav-item ${
                path === "/products" ? "nav-item--active" : ""
              }`}
            >
              Products
            </Link>
            <span data-toggle="dropdown">
              <RiArrowDropDownLine size="24px" className="drop-down-arrow" />
            </span>
            <ul className="dropdown-menu">{getSubMenu()}</ul>
          </div>
        ) : (
          <div className="dropdown">
            <li className="nav-text">
              <span
                className={`nav-item ${
                  path === "/products" ? "nav-item--active" : ""
                }`}
                onClick={() => history.push("/products")}
              >
                Products{" "}
              </span>
              <span
                className="d-xs-block d-md-none"
                onClick={handleExpandClick}
              >
                {expanded ? (
                  <GoChevronDown size="20px" />
                ) : (
                  <GoChevronRight size="20px" />
                )}
              </span>
            </li>
            <div aria-labelledby="dropdownMenuTrigger">
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className="mobile-sub-menu">{getSubMenu()}</div>
              </Collapse>
            </div>
          </div>
        )}

        <Link to="/programs">
          <li
            className={`nav-item header-item ${
              path === "/programs" ? "nav-item--active" : ""
            }`}
          >
            Programs
          </li>
        </Link>

        <Link to="/partners">
          <li
            className={`nav-item header-item ${
              path === "/partners" ? "nav-item--active" : ""
            }`}
          >
            Partners
          </li>
        </Link>

        <Link to="/referral">
          <li
            className={`nav-item header-item ${
              path === "/referral" ? "nav-item--active" : ""
            }`}
          >
            Refer Us
          </li>
        </Link>

        <Link to="/contact">
          <li
            className={`nav-item  header-item ${
              path === "/contact" ? "nav-item--active" : ""
            }`}
          >
            Contact Us
          </li>
        </Link>

        <Link to="/blog">
          <li
            className={`nav-item ${path === "/blog" ? "nav-item--active" : ""}`}
          >
            Blog
          </li>
        </Link>
      </ul>
    </div>
  );
};
NavMenus.propTypes = {
  mobile: PropTypes.bool,
};

export default NavMenus;
