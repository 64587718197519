import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import ManipalUni from '../assets/images/manipaluni.png'
import ManipalLogo from '../assets/images/manipal-logo.png'
import { useHistory } from "react-router";


const PartnerInstitutionsCard = ({institution, logo, id}) => {
    const history = useHistory();
    return (
        <div className="multiple-instituion-info-container">
            <div className="multiple-institution-info-img">
                <img src={institution.image} />
                <div className="multiple-institution-partner-logo">
                    <img src={institution.logo} />
                </div>
            </div>
            <div className="multiple-institution-info-text">
                <p>{institution.learnMore.aboutUs}</p>
            </div>
            <div className="multiple-learn-more">
                <button onClick={() => {
                    history.push({
                        pathname: `/partner/institutions/${id}/program`,
                        state: {
                            program: institution
                        }
                    })
                }}>Learn More</button>
                {/* <a href={brochure} download={`${institution.name}-brochure"`}>
                    <button>Download Brochure</button>
                </a> */}
            </div>
        </div>);
};

export default PartnerInstitutionsCard;
