import React, { useState, useEffect } from "react";
import { courses, institutions } from "../data/instututionsAndCourses";
import ProgramCard from "./ProgramCard";
import Pagination from "./Pagination";
// import ApplicantInformationForm from "./ApplicantInformationForm";
import Button from "./Button";
import { useLocation } from "react-router-dom";
import { makeLowerCase, makeUpperCase } from "../helpers";
import { BiSearch } from "react-icons/bi";
import { MdTune } from "react-icons/md";
import { Modal } from "react-bootstrap";
import "../assets/styles/pageStyles/programs.scss";

const CoursesComponent = (props) => {
  const { institutionId, existingData } = props;
  const [showForm, setShowForm] = useState(null);
  // const [applicantCourse, setApplicantCourse] = useState(null);

  const location = useLocation();

  const getData = () => {
    if (!institutionId) {
      return courses;
    }

    return courses.filter(
      (course) => course.institution === Number(institutionId)
    );
  };

  const dataToUse = existingData?.data || getData();

  const [data, setData] = useState(dataToUse);
  const [openModal, setOpenModal] = useState(false);

  const initialDataSize = { from: 0, to: 9 };
  const initialPageNumber = 1;

  const initialState = {
    filterParams: {
      selectedInstitutions: [],
      selectedStatus: [],
      selectedDegree: [],
    },
    programName: "",
    dataSize: initialDataSize,
    pageNumber: initialPageNumber,
  };

  const degrees = ["undergraduate", "postgraduate", "professional"];

  const pageSize = 9;
  const [payload, setPayload] = useState(existingData?.payload || initialState);

  const {
    filterParams,
    programName,
    dataSize: { from, to },
    pageNumber,
  } = payload;

  const { selectedInstitutions, selectedStatus, selectedDegree } = filterParams;

  const handlePageClick = (page) => {
    let dataTo = page * pageSize;
    let dataFrom = dataTo - pageSize;
    setPayload({
      ...payload,
      pageNumber: page,
      dataSize: { from: dataFrom, to: dataTo },
    });
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setData(dataToUse);

    //eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    return window.history.replaceState({}, document.title);
  }, []);

  const handleSearch = (e) => {
    let value = e.target.value;
    setPayload({ ...initialState, programName: value });
    setData(
      getData().filter((course) =>
        makeLowerCase(course.title).includes(makeLowerCase(value))
      )
    );
  };

  const handleShow = () => {
    setOpenModal(!openModal);
  };

  const handleInstitutionClick = (e) => {
    const institutionId = Number(e.target.id);

    if (selectedInstitutions.includes(institutionId)) {
      const filtered = selectedInstitutions.filter(
        (institution) => institution !== institutionId
      );
      setPayload({
        ...payload,
        filterParams: { ...filterParams, selectedInstitutions: filtered },
      });
    } else {
      setPayload({
        ...payload,
        filterParams: {
          ...filterParams,
          selectedInstitutions: [...selectedInstitutions, institutionId],
        },
      });
    }
  };

  const handleStatusChange = (e) => {
    const statusName = e.target.name;
    if (selectedStatus.includes(statusName)) {
      const filtered = selectedStatus.filter((status) => status !== statusName);
      setPayload({
        ...payload,
        filterParams: { ...filterParams, selectedStatus: filtered },
      });
    } else {
      setPayload({
        ...payload,
        filterParams: {
          ...filterParams,
          selectedStatus: [...selectedStatus, statusName],
        },
      });
    }
  };

  const handleDegreeChange = (e) => {
    const degreeName = e.target.name;
    if (selectedDegree.includes(degreeName)) {
      const filtered = selectedDegree.filter((degree) => degree !== degreeName);
      setPayload({
        ...payload,
        filterParams: { ...filterParams, selectedDegree: filtered },
      });
    } else {
      setPayload({
        ...payload,
        filterParams: {
          ...filterParams,
          selectedDegree: [...selectedDegree, degreeName],
        },
      });
    }
  };

  const filterData = () => {
    //Reset pageNumber and dataSize to avoid wrong display on UI
    setPayload({
      ...payload,
      pageNumber: initialPageNumber,
      dataSize: initialDataSize,
    });

    let newData = getData();
    if (selectedInstitutions.length) {
      newData = newData.filter((program) =>
        selectedInstitutions.includes(program.institution)
      );
    }

    //At this point, user probably selected both or none.
    if (selectedStatus.length === 1) {
      if (selectedStatus[0] === "closed") {
        newData = newData.filter(
          (course) => course.availabilityStatus === false
        );
      } else {
        newData = newData.filter(
          (course) => course.availabilityStatus !== false
        );
      }
    }

    if (selectedDegree.length) {
      newData = newData.filter((program) =>
        selectedDegree.includes(makeLowerCase(program.category))
      );
    }

    setData(newData);
    handleShow();
  };

  const clearFilters = () => {
    setPayload(initialState);
    setData(courses);
    //   handleShow();
  };

  const institution = institutions.find(
    (institution) => institution.id === Number(institutionId)
  );

  return (
    <>
      {institution?.institutions ? (
        <div className="container">
          <div className="row no-gutters">
            {institution?.institutions.map((institution, index) => (
              <div className="col-md-6 col-xl-4" key={index}>
                <div style={{ margin: "1rem .7rem" }}>
                  <ProgramCard
                    program={institution}
                    setShowForm={setShowForm}
                    isInstitution={true}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <div
            className="programs-header"
            style={{ margin: "4rem .5rem 4rem" }}
          >
            <p>
              <span className="all-courses">All Programs</span> &nbsp;
              <span className="courses-count">{data.length}</span>
            </p>
            <div className="course-sort-actions">
              <div
                className="form-group has-feedback"
                style={{ position: "relative" }}
              >
                <input
                  type="text"
                  className={`form-control search-input-field`}
                  name="programName"
                  id="programName"
                  placeholder="Search Programs"
                  value={programName}
                  onChange={handleSearch}
                />
                <div className="search-icon">
                  <BiSearch fontSize="20px" />
                </div>
              </div>
              {!institutionId && (
                <div className="filter-trigger" onClick={handleShow}>
                  <span>Filter by</span>
                  <MdTune fontSize="22px" />
                </div>
              )}
            </div>
          </div>
          <>
            {data.length ? (
              <>
                <div className="row no-gutters">
                  {data.slice(from, to).map((course, index) => (
                    <div className="col-md-6 col-xl-4" key={index}>
                      <div style={{ margin: "1rem .7rem" }}>
                        <ProgramCard
                          program={course}
                          programsState={{
                            payload,
                            data,
                            redirect: location.pathname,
                          }}
                          setShowForm={setShowForm}
                          // setApplicantCourse={setApplicantCourse}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {getData().length > pageSize && (
                  <div className="space-top-2">
                    <Pagination
                      dataSize={data?.length}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={pageNumber}
                    />
                  </div>
                )}
              </>
            ) : (
              <h5 className="space-top-2">
                No program matches your search criteria
              </h5>
            )}
          </>
        </div>
      )}

      <Modal
        style={{ zIndex: 2000 }}
        show={openModal}
        onHide={handleShow}
        className="modal-open"
        centered
      >
        <div className="modal-content modal-open">
          <div className="modal-header">
            <h5 className="modal-title" id="partnerModalLabel">
              Filter Programs
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={handleShow}>
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "1.5rem 1rem" }}>
            {!institutionId && (
              <>
                <div className="space-bottom-1 filter-parameters row">
                  <div className="col-3">
                    <span>Institution</span>
                  </div>

                  <div className="col-9">
                    <div className="institution-filter">
                      {institutions
                        .filter((institution) => !institution.institutions)
                        .map(({ id, name, accronym }, key) => (
                          <div className="col-3" key={key}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={name}
                                id={id}
                                checked={selectedInstitutions.includes(id)}
                                onChange={handleInstitutionClick}
                              />
                              <label
                                className="form-check-label filter-label"
                                htmlFor={name}
                              >
                                {accronym}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <hr />
              </>
            )}

            <div
              className={`space-bottom-1 filter-parameters row ${
                !institutionId ? "space-top-1" : ""
              }`}
            >
              <div className="col-3">
                <span>Status</span>
              </div>

              <div className="col-3">
                <div className="form-check ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="active"
                    onChange={handleStatusChange}
                    checked={selectedStatus.includes("active")}
                  />
                  <label
                    className="form-check-label filter-label"
                    htmlFor="Active"
                  >
                    Active
                  </label>
                </div>
              </div>

              <div className="col-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="closed"
                    onChange={handleStatusChange}
                    checked={selectedStatus.includes("closed")}
                  />
                  <label
                    className="form-check-label filter-label"
                    htmlFor="Closed"
                  >
                    Closed
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <div className="d-flex space-bottom-1 space-top-1 filter-parameters row">
              <div className="col-3">
                <span>Degree</span>
              </div>

              <div className="col-9 row">
                {degrees.map((degree, index) => (
                  <div className="col-12" key={index}>
                    <div
                      className="form-check "
                      style={{ marginBottom: ".8rem" }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={handleDegreeChange}
                        name={degree}
                        checked={selectedDegree.includes(degree)}
                      />
                      <label
                        className="form-check-label filter-label"
                        htmlFor="undergraduate"
                      >
                        {makeUpperCase(degree.charAt(0)) + degree.slice(1)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <hr />

            <div className="space-top-1 d-flex align-items-center justify-content-end">
              <span
                style={{ cursor: "pointer", marginRight: "1rem" }}
                onClick={() => clearFilters()}
              >
                Clear Filters
              </span>
              <Button
                label="Apply"
                onClick={() => filterData()}
                size="little"
                variant="outlined"
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-1"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836780080557"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836780080557"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-2"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836377964570"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836377964570"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-3"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836592062558"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836592062558"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>
    </>
  );
};

export default CoursesComponent;
