import React from "react";
// import NewsLetter from "./NewsLetter";
import { Link } from "react-router-dom";
import { RiTwitterFill } from "react-icons/ri";
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { RiLinkedinFill } from "react-icons/ri";

import "../assets/styles/componentStyles/footer.scss";
import vigilearnLogo from "../assets/images/vigilearn-logo.svg";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          {/* <NewsLetter /> */}
          <div className="footer--one space-top-2 ">
            <div className="row">
              <div className="col-md-4">
                <div className="mb-2">
                  <Link to="/" aria-label="Front">
                    <img
                      src={vigilearnLogo}
                      className="vigilearn-logo img-fluid"
                      alt="Vigilearn"
                    />
                  </Link>
                </div>
                <span>Headquarters</span>
                <ul
                  className="flex-column nav"
                  style={{ marginBottom: "2rem" }}
                >
                  <li className="footer-item">
                    Dubai Digital Park, A5 Building DTEC,<br />
                    Dubai Silicon Oasis, Dubai, UAE.<br />

                  </li>
                </ul>
                <span>Nigeria Office</span>
                <ul className="flex-column nav"
                  style={{ marginBottom: "2rem" }}>
                  <li className="footer-item">
                    Vibranium Valley <br />
                    42, Local Airport Road, Ikeja, Lagos<br />
                  </li>
                </ul>

                <span>North America Office</span>
                <ul className="flex-column nav"
                  style={{ marginBottom: "2rem" }}>
                  <li className="footer-item">
                    6611 W 14th Street, Austin, 78701, <br />
                    Texas, U.S.<br />
                  </li>
                </ul>

                <span>Enquiries</span>
                <ul className="flex-column nav">
                  <li className="footer-item">
                    <a
                      href="tel:+1 (512) 879-1684"
                      className="footer-link"
                    >
                      <span className="footer-link"> +1 (512) 879-1684</span>
                    </a>
                    <br/>
                    <a
                      href="tel:+234 (0908) 748-2267"
                      className="footer-link"
                    >
                      <span className="footer-link"> +234 (0908) 748-2267</span>
                    </a>
                  </li>
                </ul>

              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4 space-top">
                    <h6>PRODUCTS</h6>

                    <ul className="nav flex-column">
                      <li>
                        <Link to="learning-management-system">
                          <span className="footer-link">
                            Learning Management System
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/student-information-system">
                          <span className="footer-link">
                            Student Information System
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/application-portal">
                          <span className="footer-link">Apply Portal</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/examination-portal">
                          <span className="footer-link">Exam Portal</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-4 space-top">
                    <h6>COMPANY</h6>

                    <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                      {/* <li>
                        <Link to="/partners">
                          <span className="footer-link">Institutions</span>
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/about">
                          <span className="footer-link">About Us</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <span className="footer-link">Contact</span>
                        </Link>
                      </li>
                      {/* <li>
                    <a href="https://edutech.global/privacy-policy" target="_blank" rel="noopener noreferrer">
                      <span className="footer-link">Privacy Policy</span>
                    </a>
                  </li> */}
                      <li>
                        <Link to="/faq">
                          <span className="footer-link">FAQ</span>
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://www.edutech.global/security-awareness"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="footer-link">Security Awareness</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-4 space-top">
                    <h6>CONTACT US</h6>
                    <ul className="flex-column nav">
                      {/* <li>
                    <a href="tel:+(234) 806 199 6118">
                      <span className="footer-link">+234 [0] 806 199 6118</span>
                    </a>
                  </li> */}
                      <li>
                        <a
                          href="mailto:contact@vigilearn.com"
                          className="footer-link"
                        >
                          <span className="footer-link">support@vigilearn.com</span>
                        </a>
                      </li>
                      <li className="footer-item">Follow Us</li>

                      <li>
                        <div className="icons">
                          <a
                            href="https://twitter.com/VigiLearn"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RiTwitterFill />
                          </a>

                          <a
                            href="https://www.facebook.com/Vigilearn"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RiFacebookBoxFill />
                          </a>
                          <a
                            href="https://www.instagram.com/vigilearnsuite/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RiInstagramLine />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/vigilearn/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RiLinkedinFill />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer--two">
            <p className="footer-item">
              © {new Date().getFullYear()} Vigilearn. All Rights Reserved.
            </p>
            <p className="footer-policy">
              <a
                style={{ color: "#FFFFFF" }}
                href="https://www.edutech.global/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="footer-link">Privacy Policy</span>
              </a>
              {/* <Link to="/terms-conditions">
                <span className="footer-link">Terms &amp; conditions</span>
              </Link> */}
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
