import React, { useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import HomeBanner from "./HomeBanner";
import Impact from "./Impact";
import Partners from "./Partners";
import PartnerWithUs from "./PartnerWithUs";
import Statistics from "./Statistics";
import WhatWeDo from "./WhatWeDo";
import Testimonials from "./Testimonials";
import PopUpVideo from "../../components/popUpVideo";

const Home = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const data = [
    {
      value: "30+",
      title: "Programmes",
      class: "green",
    },
    {
      value: "20000",
      title: "Enrollments",
      class: "yellow",
    },
    {
      value: "5000",
      title: "Graduates",
      class: "orange",
    },
    {
      value: "$10m",
      title: "Money Raised",
      class: "blue",
    },
  ];
  return (
    <>
      <HomeBanner />
      <Statistics data={data}/>
      <WhatWeDo />
      <Impact />
      <PartnerWithUs />
      <Testimonials setPopUpOpen={setPopUpOpen} open={popUpOpen} />
      <Partners />
      <PopUpVideo
        open={popUpOpen}
        channel="vimeo"
        videoId="573921250"
        onClose={() => setPopUpOpen(false)}
      />
    </>
  );
};

export default Home;
