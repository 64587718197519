import React, { useState, useEffect, useRef } from "react";
import "../assets/styles/pageStyles/employabilityStats.scss";

const EmployabilityStatistics = ({ statsSlides }) => {
    const delay = 2500;
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === statsSlides.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div className="wrapper">
            <div
                className="stats-container"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {statsSlides.map((stat, index) => (
                    <div className="stat-box px-0 col-12"
                        key={index}>
                        <div className="col-md-4 px-0">
                            <div className="overlay" style={{ background: `linear-gradient(180deg, rgba(11, 47, 122, 0) 0%, #0b2f7a 100%), url(${stat.type.image})`, borderBottomLeftRadius: '15px' }}>
                                <div id="text">
                                    {stat.type.label}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 px-0">
                            <div className="stats-card" style={{
                                borderTopStyle: "none",
                                borderRight: "1px solid #D7E0E9",
                                borderBottom: "1px solid #D7E0E9",
                                borderLeftStyle: "none"
                            }}>
                                <h2 style={{ color: "#F26527", fontWeight: "bold" }}>
                                    {stat.info[0].percentage}
                                </h2>
                                <div className="">
                                    {stat.info[0].description}
                                </div>
                            </div>
                            <div className="stats-card">
                                <h2 style={{ color: "#51B74B", fontWeight: "bold" }}>
                                    {stat.info[1].percentage}
                                </h2>
                                <div className="">
                                    {stat.info[1].description}
                                </div>
                            </div>
                        </div>
                        {stat.employers ?
                            <div className="col-md-4 px-0">
                                <div className="stats-card">
                                    <h3 style={{ color: "#C99300" }}>
                                        {stat.salary.title}
                                    </h3>
                                    <div className="">
                                        {stat.salary.description}
                                    </div>
                                </div>
                                {stat.employers && (<div className="stats-card" style={{
                                    borderTop: "1px solid #D7E0E9",
                                    borderRightStyle: "none",
                                    borderBottomStyle: "none",
                                    borderLeft: "1px solid #D7E0E9"
                                }}>
                                    <h3 style={{ color: "#505D67" }}>
                                        {stat.employers.title}
                                    </h3>
                                    <div className="image-box">
                                        {stat.employers.group.map((img) => (
                                            <img src={img} alt="accreditation" />
                                        ))}
                                    </div>
                                </div>)}
                            </div>
                            :
                            <div className="col-md-4 px-0" style={{ alignItems: 'center' }}>
                                <div className="box-3">
                                    <h3 style={{ color: "#C99300" }}>
                                        {stat.salary.title}
                                    </h3>
                                    <div className="">
                                        {stat.salary.description}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>

            <div className="slideshowDots">
                {statsSlides.map((_, idx) => (
                    <div
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                            setIndex(idx);
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
}
export default EmployabilityStatistics;