import React from "react";
import PartnerBanner from "./PartnerBanner";
import PartnerInstitutions from "./PartnerInstitutions";
import PartnerForm from "./PartnerForm";

import "../../assets/styles/pageStyles/partner.scss";


const Partners = () => {
    return (
        <>
            <PartnerBanner />
            <PartnerInstitutions />
            <PartnerForm />
        </>
    )
}

export default Partners
