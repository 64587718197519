import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  const [cookieShow, setCookieShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCookieShow(true);
    }, 3000);
  }, []);
  return (
    <div style={{ display: cookieShow ? "block" : "none" }}>

      <CookieConsent
        expires={365}
        sameSite="strict"
        location="bottom"
        buttonText="Accept"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="cookie-container"
        declineButtonStyle={{
          backgroundColor: "inherit",
          color: "#117AF3",
          fontWeight: "bold",
          fontSize: "1rem",
        }}
        buttonClasses="cookie-btn"
        buttonStyle={{
          backgroundColor: "#F26527",
          borderRadius: "4px",
          color: "#ffffff",
          fontSize: "14px",
          fontWeight: "bold",
          padding: ".8rem 1.2rem",
        }}
      >
        <div className="contai">
          <p style={{ fontWeight: "bold", fontSize: "16px" }}>
            This website uses cookies
          </p>
          <span style={{ fontSize: "12px" }}>
            These cookies are used to collect information about how you interact
            with our website and allow us to remember you. We use this
            information in order to improve and customize your browsing
            experience and for analytics and metrics. For information about our
            cookies, see our{" "}
            <a
              href="https://edutech.global/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000", textDecoration: "underline" }}
            >
              Cookie policy
            </a>
            . Read about our Privacy policy{" "}
            <a
              href="https://edutech.global/privacy-policy"
              rel="noopener noreferrer"
              style={{ color: "#000000", textDecoration: "underline" }}
            >
              here.
            </a>
          </span>
        </div>
      </CookieConsent>
      </div>
    
  );
};

export default Cookie;
