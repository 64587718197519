import React, { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { RiCloseFill } from "react-icons/ri";
import { Formik, Form } from "formik";

import Button from "../components/Button";
import "../assets/styles/pageStyles/contact.scss";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    variant: "",
    msg: "",
  });

  const removeAlert = () => setAlert({ msg: "", variant: "" });

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const makeAlert = (msg, variant) => {
    setLoading(false);
    setAlert({ msg, variant });
    setTimeout(() => removeAlert(), 5000);
  };

  const onSubmit = async ({ name, email, message }, resetForm) => {
    setLoading(true);
    try {
      const payload = {
        recipientEmail: "support@edutechng.com",
        name: "Vigilearn Support",
        message: `Name :   ${name}
                    <br/><br/>
                    Email:   ${email}
                    <br/><br/>
                    Message: ${message}`,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/MailingList/sendmail`,
        payload
      );
      if (res.data.requestSuccessful === true) {
        resetForm();
        makeAlert("Message sent successfully", "success");
      } else {
        makeAlert("An error occured", "danger");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
              message: "Invalid name",
              path: "name",
            })
            : true;
        },
      })
      .min(3, "Not less than 3 characters")
      .required("Name is empty"),

    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),

    message: Yup.string()
      .min(50, "Not less than 50 characters")
      .max(300, "Not more than 300 characters")
      .required("Message is empty"),
  });

  return (
    <>
      <div className="bg-blue-secondary parent">
        <h1 className="text-center ">Contact Us</h1>
      </div>

      <div className="container form-parent">
        <div className="form-container card-hover">
          <div className="row ">
            <div className="col-md-6">
              <h2>
                Need Information?
                <br />
                Ask Questions!
              </h2>
              <p className="contact-text">
                In order to best serve you, please note that our support team is
                available from Monday to Friday between the hours of 9 am and 5
                pm (WAT). All inquiries will be responded to in one business
                day.
              </p>
            </div>

            <div className="col-md-6">
              {alert.msg !== "" && (
                <div
                  class={`alert alert-${alert.variant} fade show mb-4 d-flex justify-content-between `}
                  role="alert"
                >
                  {alert.msg}
                  <RiCloseFill
                    className="icon"
                    size="20px"
                    style={{ cursor: "pointer" }}
                    onClick={() => removeAlert()}
                  />
                </div>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) =>
                  onSubmit(values, resetForm)
                }
              >
                {({ errors, touched, getFieldProps, values }) => (
                  <Form noValidate autoComplete="off">
                    <div className="form-group">
                      <label htmlFor="name" className="input-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control input-field ${errors.name && touched.name ? "form-error" : ""
                          }`}
                        name="name"
                        {...getFieldProps("name")}
                        id="name"
                        placeholder="Jane Doe"
                      />
                      <small className="text-danger">
                        {errors.name && touched.name && errors.name}
                      </small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className="input-label">
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control input-field ${errors.email && touched.email ? "form-error" : ""
                          }`}
                        name="email"
                        {...getFieldProps("email")}
                        id="email"
                        placeholder="janedoe@example.com"
                      />
                      <small className="text-danger">
                        {errors.email && touched.email && errors.email}
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="message" className="input-label">
                        How Can We help you?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        className={`form-control ${errors.message && touched.message ? "form-error" : ""
                          }`}
                        name="messagee"
                        {...getFieldProps("message")}
                        id="message"
                        placeholder="Enter message here"
                      />
                      <small>This Field is limited to 300 characters</small>
                      <small className="text-danger d-block">
                        {errors.message && touched.message && errors.message}
                      </small>
                    </div>
                    <div className="submit">
                      <Button
                        fullWidth
                        disabled={loading}
                        variant="tetiary"
                        type="submit"
                        label="Send Message"
                        disabledText="Submitting..."
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
