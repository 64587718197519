import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import { Modal, NavItem } from "react-bootstrap";
// import { institutions } from "../data/instututionsAndCourses";
import LinesEllipsis from "react-lines-ellipsis";
import "../assets/styles/componentStyles/program-card.scss";

const PaymentMode = ({ payment }) => {
  const { image, name, description, action, link, info } = payment;
  const [showForm, setShowForm] = React.useState(false);
  // const childInstitution = institutions.find(
  //   (institution) => institution.id === program
  // );

  return (
    <div className="payment-card">
      <div className={`text-left`}>
        {showForm &&
          <Modal
            style={{ zIndex: 2000 }}
            show={showForm}
            size={"lg"}
            onHide={() => setShowForm(false)}
            className="modal-open"
            centered
          >
            <>
              <div className="payment-modal-heading">
                <div className="title ml-5 mt-4">Bank Details</div>
                <button
                  type="button"
                  className="close text-align-right jot-form-modal"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => setShowForm(false)}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="embed-responsive embed-responsive-1by1 modal-body">
                { info[0].title && 
                  
                <div className="payment-modal">
                  <div className="modal-title">
                    {info[0].title}
                  </div>
                  <div className="modal-content">
                    <div className="grid-container my-4">
                      <div className="content-label">
                        Beneficiary:
                      </div>
                      <div className="content-body">
                        {info[0].beneficiary}
                      </div>
                      <div className="content-label">
                        Beneficiary Account Number (USD):
                      </div>
                      <div className="content-body">
                        {info[0].benAcctNo}
                      </div>
                      <div className="content-label">
                        Account Name:
                      </div>
                      <div className="content-body">
                        {info[0].acctName}
                      </div>
                      <div className="content-label">
                        Zenith Bank Swift:
                      </div>
                      <div className="content-body">
                        {info[0].zenSwift}
                      </div>
                    </div></div>
                  <div className="modal-title" style={{ fontWeight: "400", textDecoration: "underline" }}>
                    {info[0].intermediaryTitle}
                  </div>
                  <div className="modal-content">
                    <div className="grid-container my-4">
                      <div className="content-label">
                        Bank:
                      </div>
                      <div className="content-body">
                        {info[0].bank}
                      </div>
                      <div className="content-label">
                        Sort Code:
                      </div>
                      <div className="content-body">
                        {info[0].sortCode}
                      </div>
                      <div className="content-label">
                        Swift Address:
                      </div>
                      <div className="content-body">
                        {info[0].swiftAddr}
                      </div>
                      <div className="content-label">
                        Iban No.:
                      </div>
                      <div className="content-body">
                        {info[0].IBanNumber}
                      </div>
                      <div className="content-label">
                        Account No.:
                      </div>
                      <div className="content-body">
                        {info[0].acctNo}
                      </div>
                    </div>
                    <div className="notice">
                      *Convenience fee of $35 applies
                    </div>
                  </div>
                </div>
                }

                {info[1] && <div className="payment-modal">
                  <div className="modal-title">
                    {info[1].title}
                  </div>
                  <div className="modal-content">
                    <div className="grid-container my-4">
                      <div className="content-label">
                        Beneficiary:
                      </div>
                      <div className="content-body">
                        {info[1].beneficiary}
                      </div>
                      <div className="content-label">
                        Bank Name:
                      </div>
                      <div className="content-body">
                        {info[1].bankName}
                      </div>
                      <div className="content-label">
                        Account no:
                      </div>
                      <div className="content-body">
                        {info[1].acctNo}
                      </div>
                      <div className="content-label">
                        Bank Address:
                      </div>
                      <div className="content-body">
                        {info[1].bankAddress}
                      </div>
                      <div className="content-label">
                        {info[1].IBanNumber ? "I-Ban Number:" : "Branch Name:"}
                      </div>
                      <div className="content-body">
                        {info[1].IBanNumber ? info[1].IBanNumber : info[1].branchName}
                      </div>
                      { info[1].branchCode &&
                        <>
                          <div className="content-label">
                            Branch code:
                          </div>
                          <div className="content-body">
                            {info[1].branchCode}
                          </div>
                        </>
                      }
                      <div className="content-label">
                        Swift code:
                      </div>
                      <div className="content-body">
                        {info[1].swiftCode}
                      </div>
                      { info[1].payDetails &&
                        <>
                          <div className="content-label">
                            Payment Details:
                          </div>
                          <div className="content-body">
                            {info[1].payDetails}
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>}
              </div>
            </>
          </Modal>
        }
        <img src={image} alt="" className="img-fluid" />
      </div>
      <h5 className="mt-2">{name}</h5>

      <div>
        <LinesEllipsis
          text={description}
          maxLine="5"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
      <div className="">
        <div className="row mt-4">
          <div className="col-7">
            {link ? <a href={link} target="_blank" rel="noreferrer">
              <Button label={action} size="sm" fullWidth variant="secondary" />
            </a> :
              <Button onClick={() => setShowForm(true)} label={action} size="sm" fullWidth variant="secondary" />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMode;
