import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Programs from "../pages/Programs";
import Program from "../pages/Program";
import ManipalProgram from "../pages/ManipalProgram";
import AllProducts from "../pages/products/AllProducts";
import StudentInformationSystem from "../pages/products/StudentInformationSystem";
import ExaminationPortal from "../pages/products/ExaminationPortal";
import LearningManagementSystem from "../pages/products/LearningManagementSystem";
import ApplicationPortal from "../pages/products/ApplicationPortal";
import Partners from "../pages/Partners";
import Partner from "../pages/Partner";
import FAQ from "../pages/FAQ";
import Blogs from "../pages/Blogs";
import Blog from "../pages/Blog"
import NotFound from "../pages/NotFound";

import ScrollToTop from "./ScrollToTop";
import MultipleSchPartner from "../pages/MulitpleSchPartner";
import PartnerInstitutionProgram from "../pages/partnerInstitutionProgram";
import Referral from "../pages/Referral";

const Routes = () => {
  const routes = [
    {
      path: "/about",
      component: AboutUs,
    },
    {
      path: "/contact",
      component: ContactUs,
    },
    {
      path: "/programs",
      component: Programs,
    },
    {
      path: "/program",
      component: Program
    },
    {
      path: "/manipal-program",
      component: ManipalProgram
    },
    {
      path: "/",
      component: Home,
    },
    {
      path: "/products",
      component: AllProducts
    },
    {
      path: "/student-information-system",
      component: StudentInformationSystem,
    },
    {
      path: "/examination-portal",
      component: ExaminationPortal,
    },
    {
      path: "/learning-management-system",
      component: LearningManagementSystem,
    },
    {
      path: "/application-portal",
      component: ApplicationPortal,
    },
    {
      path: "/partners",
      component: Partners
    },
    {
      path: "/partner/institutions/:id",
      component: MultipleSchPartner
    },
    {
      path: "/partner/institutions/:id/program",
      component: PartnerInstitutionProgram
    },
    {
      path: "/partner/:id",
      component: Partner
    },
    {
      path: "/referral",
      component: Referral,
    },
    {
      path: "/faq",
      component: FAQ
    },
    {
      path: "/blog",
      component: Blogs
    },
    {
      path: `/blog/:id`,
      component: Blog
    },
    {
      path: "",
      component: NotFound,
    },
  ];
  return (
    <div style={{ marginTop: "40px" }}>
      <ScrollToTop />
      <Switch>
        {routes.map(({ path, component }, key) => (
          <Route path={path} component={component} exact key={key} />
        ))}
      </Switch>
    </div>
  );
};

export default Routes;
