import React from "react";
import { Link } from "react-router-dom";

const MscInternationalAffairs = () => {
  const reasons = [
    {
      title:
        "Opportunity to contribute to global decisions while shaping foreign policies",
      content:
        "The constant domestic and global shift in power and policy has presented the need for good diplomats. Earning a masters degree in International Affairs and Diplomacy provides you with the opportunity to contribute to global decisions while shaping foreign policies. ",
    },
    {
      title: "You get well suited for all job sectors",
      content:
        "In addition, a postgraduate degree in International Affairs and Diplomacy will empower learners with problem-solving skills and help them gain an understanding of diverse cultures. This makes learners well suited for private and public sector jobs and drives value for different organisations.  ",
    },
    {
      title: "Become versatile",
      content:
        "Individuals who hold a Masters degree in International Affairs and Diplomacy can function in a range of positions including diplomacy, political analyses, foreign affairs, international journalism, political science, among others. ",
    },
  ];
  return (
    <>
      <p>
        Politics is global. From social events in East London to political
        happenings in Nigeria, the world is looking for ambitious individuals to
        make a positive impact. A masters in International Affairs and Diplomacy
        provides a great opportunity to navigate global issues and impact.
        intergovernmental relations. This gives students of diplomacy a bigger
        picture of world events: from public policies to social issues, economic
        trends and international law. The broad nature of a postgraduate degree
        in International Affairs and Diplomacy makes the course a popular choice
        for professionals who desire the flexibility to pursue roles, projects
        and activities that drive global participation.
      </p>

      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">
        Why Earn a Masters in International Affair and Diplomacy?
      </h4>

      <ul className="mt-4">
        {reasons.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>

      <p>
        So, looking to apply for a masters in International Affairs and don’t
        know where to start? VigiLearn is here to help. At VigiLearn we believe
        that your education should reflect your unique ambitions and goals. For
        this, we have partnered with the prestigious Ahmadu Bello University to
        offer online masters in International Affairs and Diplomacy for learners
        who do not have the luxury of taking physical classes.
      </p>

      <p className="mt-5">
        Applications are open for new intakes of this program and to qualify,
        learners must be university graduates with a minimum of Second Class
        Lower Degree (2:2) with an NYSC Certificate. Click{" "}
        <Link to="/partner/1">here</Link>
      </p>

      <p className="mt-5">
        Not interested in studying this program? We also have a variety of
        undergraduate and graduate courses you can choose from. Visit our
        website{" "}
        <a href="https://vigilearn.com/" target="_blank" rel="noreferrer">
          here
        </a>{" "}
        to get started.
      </p>
    </>
  );
};

export default MscInternationalAffairs;
