import React from "react";
import PropTypes from "prop-types";

import "../assets/styles/componentStyles/detail-card.scss";

const DetailCard = ({ data: { image, title, content, bgColor, ...rest } }) => {
  return (
    <div
      className={`detail-card ${
        !bgColor ? "detail-card--light" : ""
      }`}
      style={{ backgroundColor: bgColor }}
      {...rest}
    >
      <img src={image} alt=""  className="img-fluid" />
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  );
};

DetailCard.defaultProps = {
  isLight: true,
  content: "Content",
  title: "Title",
  bgColor: "#FFFFFF",
};

DetailCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  bgColor: PropTypes.string,
};

export default DetailCard;
