import React, { useState } from "react";
import PopUpVideo from "../../components/popUpVideo";
import playImage from "../../assets/images/play-image.png";
import "../../assets/styles/pageStyles/products.scss";

import { displayData } from "../../helpers";

const ApplicationPortal = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);

  const data = [
    {
      title: "Big Data Analytics",
      content:
        "By using Big data as a core tooling resource for revealing insights from multiple data points, the platform aids assessment and planning activities of administrators",
    },
    {
      title: "Payment Integration",
      content:
        "By integrating with reliable fintech platforms, the portal facilitates the ease of billing and fund collection.",
    },
    {
      title: "User Management",
      content:
        "VigiLearnApply provides simple interfaces for all user instances. Whether you're an Admin or an Applicant, you'll find our interfaces user-friendly and easy to navigate. For any type of institution, managing users is as simple as a click or two.",
    },

    {
      title: "User Screening",
      content:
        "Administrators have the capability to assess active user profiles based on set parameters.",
    },
  ];

  const showData = (start, number) => displayData(data, start, number);

  return (
    <div className="products-container product-wrapper">
      <div className="bg-blue-primary">
        <div className="product-banner container text-center ">
          <div className="product-banner-text banner-text-light">
            <h1 data-aos="fade-down">Application Portal</h1>
            <p  data-aos="fade-up">
            Our application portal is tailor made to accept and process
              applications of any form. With our intuitive design and fully
              automated process, the portal serves as an entry point for anyone
              who wishes to apply for programmes for respective schools who
              adopt this technology.
            </p>
          </div>
          <div className="product-image-box d-flex align-items-center justify-content-center">
            <div
              className="product-play-container"
              onClick={() => setPopUpOpen(!popUpOpen)}
            >
              <img src={playImage} alt="" width="35px" height="40px" />
            </div>
          </div>
        </div>
      </div>

      <div className="product-key-features product-key-features--light-bg d-flex justify-content-center">
        <div className="features-content--light">
          <h2 className="text-center" style={{ color: "#3A434B" }}>
            Key Features
          </h2>
          <div className="feature-cards row">
            <div className="col-md-6">{showData(0, 2)}</div>
            <div className="col-md-6">{showData(2, 4)}</div>
          </div>
        </div>
      </div>
      <PopUpVideo
        open={popUpOpen}
        channel="vimeo"
        videoId="549197147"
        onClose={() => setPopUpOpen(false)}
      />
    </div>
  );
};

export default ApplicationPortal;
