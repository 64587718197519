import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  // useScrollTrigger,
  // Slide,
} from "@material-ui/core";
import { RiMenuLine } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import vigilearnLogoLight from "../assets/images/vigilearn-logo-white.png";
import vigilearnLogo from "../assets/images/vigilearn-default.png";
import NavMenus from "./NavMenus";

// const HideOnScroll = (props) => {
//   const { children } = props;

//   const trigger = useScrollTrigger();

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// };

const Header = (props) => {
  const { toggleMobileNav, open, isLight, isBlueHeader, ...rest } = props;
  return (
    // <HideOnScroll {...props}>
    <AppBar
      position="fixed"
      // style={{ zIndex: 1400 }}
      className={`root ${
        isLight ? "root--light" : isBlueHeader ? "root--blue-bg" : ""
      }`}
      {...rest}
    >
      <Toolbar disableGutters className="h-100">
        <div className="container d-flex justify-content-between d-flex align-items-center">
          <div>
            <Link to="/">
              <img
                src={
                  isBlueHeader
                    ? vigilearnLogo
                    : !isLight
                    ? vigilearnLogoLight
                    : vigilearnLogo
                }
                alt="Vigilearn"
                className="vigilearn-logo"
              />
            </Link>
          </div>

          <Hidden smDown>
            <NavMenus />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileNav}
            >
              {open ? (
                <RiCloseFill
                  size="25px"
                  color={isLight ? "#3a434b" : "#FFFFFFF"}
                />
              ) : (
                <RiMenuLine
                  size="28px"
                  color={isLight || isBlueHeader ? "#3a434b" : "#FFFFFFF"}
                />
              )}
            </IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
    // </HideOnScroll>
  );
};

Header.propTypes = {
  toggleMobileNav: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLight: PropTypes.bool,
};

export default Header;
