import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { blogs } from "../../data/blog";
import BlogsComponent from "../../components/BlogsComponent";
import CareerAdvancement from "./CareerAdvancement";
import NursingDegree from "./NursingDegree";
import SoftSkills from "./SoftSkills";
import Power from "./Power";
import MastersDegree from "./MastersDegree";
import Accounting from "./Accounting";
import MscInternationalAffairs from "./MscInternationalAffairs";
import DataScience from "./DataScience";

const Blog = () => {
  const { id } = useParams();

  const blog = blogs.find((blog) => blog.id.toString() === id);

  const getBlogContent = () => {
    switch (id) {
      case "1":
        return <CareerAdvancement />;

      case "2":
        return <NursingDegree />;

      case "3":
        return <SoftSkills />;

      case "4":
        return <Power />;

      case "5":
        return <MastersDegree />;

      case "6":
        return <Accounting />;

      case "7":
        return <MscInternationalAffairs />;

      case "8":
        return <DataScience />;

      default:
        break;
    }
  };

  if (!blog) return <Redirect to="/blog" />;

  return (
    <div className="blogs-container ">
      <div className="blogs-header blogs-header--single bg-blue-secondary">
        <h3
          className="single-blog-content text-center dark-text"
          data-aos="fade"
        >
          {blog.title}
        </h3>
      </div>
      <div className="single-blog-content single-blog-body">
        <img src={blog.fullImage} alt="" />
        <div>{getBlogContent()}</div>
      </div>

      <div className="blog-content-wrapper other-posts">
        <h3 className="container dark-text">Other Posts</h3>
        <BlogsComponent blogId={id} />
      </div>
    </div>
  );
};

export default Blog;
