import React, { useState } from "react";
import { faq } from "../data/faq";
import { HiOutlinePlus, HiOutlineMinus } from "react-icons/hi";

import "../assets/styles/pageStyles/faq.scss";

const FAQ = () => {
  const [openAccordions, setOpenAccordions] = useState([]);

  const checkExpanded = (index) =>
    openAccordions.includes(index)
      ? setOpenAccordions(
          openAccordions.filter((accordionIndex) => accordionIndex !== index)
        )
      : setOpenAccordions([...openAccordions, index]);

  return (
    <div className="faq-root">
      <div className="faq-container">
        <div className="container ">
          <h3 className="text-center" data-aos="fade-down">Frequently Asked Questions</h3>
          <div className="question-section ">
            {faq.map(({ question, answer }, index) => (
              <div className="faq-bar" key={index}>
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => checkExpanded(index)}
                >
                  <h4>{question}</h4>
                  <span className="expand-cta">
                    {!openAccordions.includes(index) ? (
                      <HiOutlinePlus />
                    ) : (
                      <HiOutlineMinus />
                    )}
                  </span>
                </div>

                <p
                  className={`mt-2 answer-section ${
                    openAccordions.includes(index) ? "answer-section--open" : ""
                  }`}
                >
                  {answer}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
