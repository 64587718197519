import babcockLogo from "../assets/images/new-babcock.png";
// import cbmLogo from "../assets/images/cipm-logo.png";
import menaLogo from "../assets/images/mena-logo.png";
import oauLogo from "../assets/images/oau-plain-logo.png";
import abuLogo from "../assets/images/new-abu-logo.png";
import hultLogo from "../assets/images/hult-logo.png";
import ritLogo from "../assets/images/rit--logo.png";
import ritNewyorkLogo from "../assets/images/ritNewyork.png";
import iXperienceLogo from "../assets/images/iXperience-logo.png";
import manipalLogo from "../assets/images/manipal-logo.png";
import oauPartner from "../assets/images/oau-partner.jpg";
import babcockPartner from "../assets/images/babcock-partner.jpg";
import abuPartner from "../assets/images/abu-partner.png";
import menaPartner from "../assets/images/mena-partner.png";
import iXperiencePartner from "../assets/images/ixperience.png";
import manipalPartner from "../assets/images/manipal-partner.jpg";
import manipalIU from "../assets/images/manipaluni.png";
import manipalIUsq from "../assets/images/manipalunisq.png";
import manipalGlobalNxt from "../assets/images/manipalglobalnxt.png";
import manipalGlobalNxtSq from "../assets/images/manipalglobalnxtsq.png";
import manipalAHE from "../assets/images/manipalahe.png";
import manipalIULogo from "../assets/images/ManipalIULogo.png";
import manipalGNLogo from "../assets/images/ManipalGlobalNxtLogo.png";
import hultPartner from "../assets/images/hult-partner.png";
import ritPartner from "../assets/images/rit-partner.png";
import ritPartner2 from "../assets/images/rit2-partner.png";
import manipalAHELogo from "../assets/images/ManipalAHELogo.png";
import satyaNadella from "../assets/images/manipalAheAlumni/satya-nadella.png";
import rajeevSuri from "../assets/images/manipalAheAlumni/rajeev-suri.png";
import paymentIcon from "../assets/images/card.svg";
import USA from "../assets/images/USA.png";
import UAE from "../assets/images/UAE.png";
import Boston from "../assets/images/Boston.svg";
import Dubai from "../assets/images/Dubai.svg";
import SanFransisco from "../assets/images/San Fransisco.svg";
import London from "../assets/images/London.svg";
import NewYork from "../assets/images/New York.svg";
import Shanghai from "../assets/images/Shanghai.svg";
import datukSheikhMuszaphar from "../assets/images/manipalAheAlumni/datuk-sheikh-muszaphar.png";
import mscheLogo from "../assets/images/msche_logo.png"
import {
  manipalAHEImages,
  manipalGlobalNxtImages,
  manipalIUImages,
  hultAccreditationImages,
  ritAccreditationImages,
} from "./accreditations";
import hultBrochure from "../assets/documents/HultBrochure.pdf";
import ritBrochure from "../assets/documents/RITBrochure.pdf";
import { hultImages, hultImages2 } from "./hultEmployers"
import { manipalAHEIntImages } from "./internships";
import manipalAward1 from "../assets/images/manipalAheAwards/1.png";
import manipalAward2 from "../assets/images/manipalAheAwards/2.png";
import manipalAward3 from "../assets/images/manipalAheAwards/3.png";
import manipalAward4 from "../assets/images/manipalAheAwards/4.png";
import manipalAward5 from "../assets/images/manipalAheAwards/5.png";
import number1 from '../assets/images/number1.png';
import number2 from '../assets/images/number2.png';
import number3 from '../assets/images/number3.png';
import number4 from '../assets/images/number4.png';
import number5 from '../assets/images/number5.png';
import number6 from '../assets/images/number6.png';
import stats from '../assets/images/employability-stat.png';
import stats2 from '../assets/images/employability-stat3.png';
import stats3 from '../assets/images/employability-stat2.png';
import hultApply from '../assets/images/hult-apply.png';
import ritApply from '../assets/images/rit-apply.png';
// import babcockAccounting from "../assets/documents/course-curriculums/babcock/Babcock-Accounting.pdf";
// import oauAccounting from "../assets/documents/course-curriculums/oau/OAU-Accounting.pdf";
// import oauNursing from "../assets/documents/course-curriculums/oau/OAU-Nursing.pdf";
// import abuAccounting from "../assets/documents/course-curriculums/abu/Accounting.pdf";
// import abuBusAdmin from "../assets/documents/course-curriculums/abu/BusAdmin.pdf";
// import abuEconomics from "../assets/documents/course-curriculums/abu/Economics.pdf";
// import abuIntStudies from "../assets/documents/course-curriculums/abu/International-Studies.pdf";
// import abuMBA from "../assets/documents/course-curriculums/abu/MBA.pdf";
// import abuMIAD from "../assets/documents/course-curriculums/abu/MIAD.pdf";
// import abuMIM from "../assets/documents/course-curriculums/abu/MIM.pdf";
// import abuMLCJ from "../assets/documents/course-curriculums/abu/MLCJ.pdf";
// import abuMPA from "../assets/documents/course-curriculums/abu/MPA.pdf";
// import abuMPH from "../assets/documents/course-curriculums/abu/MPH.pdf";
// import abuNursing from "../assets/documents/course-curriculums/abu/Nursing.pdf";
// import abuPGDE from "../assets/documents/course-curriculums/abu/PGDE.pdf";
// import abuPGDM from "../assets/documents/course-curriculums/abu/PGDM.pdf";
// import abuPoliticalScience from "../assets/documents/course-curriculums/abu/Political-Science.pdf";
// import abuPublicAdmin from "../assets/documents/course-curriculums/abu/Public-Admin.pdf";
// import abuSociology from "../assets/documents/course-curriculums/abu/Sociology.pdf";

export const institutions = [
  {
    id: 1,
    name: "Ahmadu Bello University",
    accronym: "ABU",
    logo: abuLogo,
    image: abuPartner,
    otherProgram: true,
    about: [
      "The Ahmadu Bello University was established in October 1962 and presently has a population of about 30,000 undergraduate and 10,000 postgraduate students drawn from various parts of Africa and the rest of the world.",
      "The Distance Learning Centre was set up to ensure qualitative, accessible and timely delivery of approved academic programmes to its students without distinction based on geographic location, race, sex, religion or other affiliations.",
    ],
    whyUs: [
      {
        title: "Flexible Learning:",
        details: "With our online learning platform, you get to study at your pace, whenever, wherever."
      },
      {
        title: "24hrs access to content:",
        details: "You can easily login to access learning content at any time of the day from our online learning platform."
      },
      {
        title: "Same degree as on-campus:",
        details: "You earn the same degree as those who studied on campus. No differentiation in degree."
      },
      {
        title: "Fast track learning:",
        details: "You can fast track your study with us as we operate a 3 semester window in an academic year. This way, you get to complete your degree in time."
      },
      {
        title: "Continuous Learning:",
        details: "Our programs are not affected by strike or any industrial action that pertain to the delay of educational services. You get to continue your learning and complete your degree at the stipulated time period you have set for yourself."
      },
    ],
  },
  {
    id: 2,
    name: "Obafemi Awolowo University",
    accronym: "OAU",
    logo: oauLogo,
    image: oauPartner,
    about: [
      "The Obafemi Awolowo University was established in 1961 as one of the first varsities in Nigeria. As the University has proven itself to be a citadel of quality learning, its student population has risen steadily from 244 in 1962/63 to over 30,000 at present.",
      "Established in 2002 by the Governing Council of Obafemi Awolowo University, the Centre for DIstance Learning was charged solely with the responsibility to facilitate development and co-ordinate the delivery of degree and sub-degree programmes by open and distance learning mode.",
    ],
  },
  {
    id: 3,
    name: "Babcock",
    accronym: "Babcock",
    logo: babcockLogo,
    image: babcockPartner,
    otherProgram: true,
    about: [
      "Established in 1999, Babcock University is a private higher-education institution located in the suburban setting of the large town of Ilishan-Remo, Ogun.",
      "Being a pioneer private University in Nigeria, Babcock has continued this legacy of upholding a cutting-edge excellence in education. Babcock now has a postgraduate school which took off in the third quarter of 2010 and a medical school which took off in January 2012.",
    ],
    whyUs: [
      {
        title: "Flexible Learning:",
        details: "With our online learning platform, you get to study at your pace, whenever, wherever."
      },
      {
        title: "24hrs access to content:",
        details: "You can easily login to access learning content at any time of the day from our online learning platform."
      },
      {
        title: "Same degree as on-campus:",
        details: "You earn the same degree as those who studied on campus. No differentiation in degree."
      },
      {
        title: "Continuous Learning:",
        details: "Our programs are not affected by strike or any industrial action that pertain to the delay of educational services. You get to continue your learning and complete your degree at the stipulated time period you have set for yourself."
      },
    ],
  },
  // {
  //   id: 4,
  //   name: "Mena",
  //   accronym: "Mena",
  //   logo: menaLogo,
  //   image: menaPartner,
  //   about: [
  //     "MENA College of Management (also known as MCM) is an institution of higher education located in Dubai, United Arab Emirates.",
  //     "MENA College of Management is a young, inclusive Higher Education Institution, that aims to produce highly qualified graduates by offering professional courses and degree programmes in specialized areas of management that are important to the needs of corporate organizations.",
  //   ],
  // },
  {
    id: 5,
    name: "IXperience",
    accronym: "IXperience",
    logo: iXperienceLogo,
    semiparterned: true,
    accronym: "iXperience",
    link: "https://ixperience.vigilearnapply.com/register/individual",
    noJotForm: true,
    otherProgram: true,
    learnMore: {
      image: iXperiencePartner,
      programme: [
        {
          title: "Data Analytics",
          fee: {
            // cost: "$195",
            durationPeriod: "8 weeks",
            mode: "Self-paced",
          },

          aboutProgramme: [
            "This course is the ideal introduction for students of all backgrounds to the dynamic field of Data Analytics. Students will learn about the key foundational Data Analytics skill sets and tools, which is simpler than it sounds - chances are, you’ve worked with data before! All it takes is a critical eye and an ability to see patterns in simple sets of information presented in a spreadsheet. Once the basics are clear, a bright future opens up where data can change the world in new and profound ways.",
          ],
          semititle: "Course Curriculum",
          totalDuration: "4 sections • 357 lectures",
          programCollapsible: [
            {
              title: "Intro to Data Analysis and Visualisation with Python",
              duration: "12 Lectures • 2 hrs 23 min",
              programmes: [
                {
                  title: "Introduction",
                  time: "1:17",
                  videoId: "674986227"
                },
                {
                  title: "What we are going to learn, and why learn Data Analytics?",
                  time: "15:59"
                },
                {
                  title: "Why use Python?",
                  time: "5:05"
                },
                {
                  title: "Setting up Python, Anaconda and Jupyter notebooks",
                  time: "15:15"
                },
                {
                  title: "Intro to Jupyter notebooks",
                  time: "27:55"
                },
                {
                  title: "Intro to Python notebooks + Variables",
                  time: "12:00"
                },
                {
                  title: "Strings",
                  time: "14:24"
                },
                {
                  title: "Numbers",
                  time: "10:51"
                },
                {
                  title: "Boolean",
                  time: "8:09"
                },
                {
                  title: "Lists",
                  time: "27:41"
                },
                {
                  title: "Wrapping up and finding help",
                  time: "5:03"
                },
                {
                  title: "Homework",
                  time: "10:06"
                },
              ],
            },
            {
              title: "Problem definition, Getting data, and Pandas",
              duration: "11 Lectures • 1 hrs 23 min",
              programmes: [
                {
                  title: "Introduction",
                  time: "1:06"
                },
                {
                  title: "Framing the problem",
                  time: "8:37"
                },
                {
                  title: "Data sources",
                  time: "6:02"
                },
                {
                  title: "Data quality and ethics",
                  time: "8:52"
                },
                {
                  title: "Reading data in",
                  time: "4:19"
                },
                {
                  title: "Intro to Pandas and dataframes",
                  time: "10:48"
                },
                {
                  title: "Reading and writing dataframes",
                  time: "7:57"
                },
                {
                  title: "Inspecting dataframes",
                  time: "3:30"
                },
                {
                  title: "Indexing",
                  time: "4:07"
                },
                {
                  title: "Golden thread project",
                  time: "6:03"
                },
                {
                  title: "Homework",
                  time: "14:20"
                },
              ]
            },
            {
              title: "Data Wrangling",
              duration: "11 Lectures • 1 hrs 23 min",
              programmes: [
                {
                  title: "Introduction",
                  time: "1:34"
                },
                {
                  title: "Lightning quick theory",
                  time: "9:32"
                },
                {
                  title: "Selecting columns",
                  time: "7:36"
                },
                {
                  title: "Renaming columns",
                  time: "4:09"
                },
                {
                  title: "Filtering rows",
                  time: "10:46"
                },
                {
                  title: "Dropping rows and columns",
                  time: "5:43"
                },
                {
                  title: "Mutating columns",
                  time: "9:26"
                },
                {
                  title: "Summarising/aggregating",
                  time: "29:03"
                },
                {
                  title: "Homework",
                  time: "4:33"
                },
              ],
            },
            {
              title: "Visualisations",
              duration: "11 Lectures • 1 hrs 23 min",
              programmes: [
                {
                  title: "Introduction",
                  time: "1:24"
                },
                {
                  title: "Intro to data visualisation",
                  time: "6:33"
                },
                {
                  title: "How do our minds interpret visualisations?",
                  time: "11:33"
                },
                {
                  title: "Do's and Don'ts",
                  time: "9:56"
                },
                {
                  title: "Data visualisation in Python",
                  time: "12:03"
                },
                {
                  title: "Intro to packages",
                  time: "8:54"
                },
                {
                  title: "Intro to Seaborn",
                  time: "10:42"
                },
                {
                  title: "Relationship plots",
                  time: "19:50"
                },
                {
                  title: "Categorical plots",
                  time: "9:22"
                },
                {
                  title: "Advanced plots",
                  time: "7:31"
                },
                {
                  title: "Homework",
                  time: "2:44"
                },
              ]
            },
          ],
        }
      ],
    },
    about: [
      "IXperience is an education innovator based in South Africa pioneering a holistic, experiential approach to education that inspires radical personal growth and empowers students for the future of a rapidly changing business world.",
      "iX's vision is to build a lifelong learning brand with a range of future-focused programs and services. Their current focus is on creating career development programs for high schoolers, university students, professionals, and executives.",
    ],
    whyUs: [
      {
        title: "In-demand skills:",
        details: "Learn an in-demand skill from an industry expert, with years of experience working in that field."
      },
      {
        title: "Flexible time:",
        details: "Learn by watching high quality videos."
      },
      {
        title: "Tangible deliverable:",
        details: "Each course includes a real-world project that you will complete and can add to your portfolio and/or resume."
      },
      {
        title: "High-touch support:",
        details: "Weekly live support sessions led by a Course Mentor, working in that industry, to help answer any questions you have and provide additional guidance."
      },
      {
        title: "Certificate of completion:",
        details: "If you watch the videos and complete the project, you’ll receive a Certificate of Completion. If you attend the Mentor sessions and do outstanding work on the project, you’ll receive a Certificate of Merit, and a LinkedIn recommendation from your Mentor."
      },
      {
        title: "Career Paths:",
        details: "Being a certified data analyst, you are well on your way to a long future of steady job growth. Here are some job titles that you can apply for when you have completed our Data Analytics course: Data Analyst, Business Analyst, Financial Analyst, Operations Analyst, Risk Analyst, Research Analyst."
      },
    ],
  },

  // {
  //   id: 6,
  //   name: "Manipal Global Education Group",
  //   accronym: "manipal",
  //   logo: manipalLogo,
  //   image: manipalPartner,
  //   about: [
  //     "Manipal Global Education Services is a leading international global provider of quality higher education business services. Founded in 2001, Manipal’s foundation of over six and a half decades in the Indian education system enables Manipal Global Education Services’ successful expansion in Indian and overseas markets.",
  //     "The company currently operates and supports universities and medical institutions in India, Dubai, Malaysia, Nepal, and Antigua in the Caribbean.",
  //   ],
  //   // institutions: [7, 9, 8],
  // },
  {
    id: 7,
    name: "Manipal International University, Malaysia",
    accronym: "MIU",
    logo: manipalIULogo,
    image: manipalIU,
    noJotForm: true,
    link: "https://miu.vigilearnapply.com/register/individual",
    otherProgram: true,
    // parentInstitution: 6,
    about: [
      "Manipal International University (MIU) is a full-fledged Malaysian university offering multidisciplinary programs with a focus in the fields of Science, Engineering, Business and Mass Communication. With over nine years of academic excellence, MIU has welcomed 1500+ students from 34 countries to date. The university aims to help students develop holistically, enabling them to be successful leaders of tomorrow.",
      "iX's vision is to build a lifelong learning brand with a range of future-focused programs and services. Their current focus is on creating career development programs for high schoolers, university students, professionals, and executives.",
    ],
    learnMore: {
      image: manipalIUsq,
      aboutUs:
        "Manipal International University (MIU), a member of the Manipal Global Education Group, is a full-fledged Malaysian university offering multidisciplinary programs with a focus in the fields of Science, Engineering, Business and Mass Communication. MIU has welcomed 1500+ students from 34 countries to date. The university aims to help students develop holistically, enabling them to be successful leaders of tomorrow.",
      whyUs: [
        "Unique virtual classroom model utilizes best-in-class learning technology to enable students to learn anytime, anywhere. ",
        "Partnerships with International universities that create pathway to Australia, New Zealand, UK, UAE, US and more! ",
        "Continuous interaction with a dedicated team of experienced, PhD-qualified professors from across the globe.  ",
        "Professors delivering live lectures using the latest webinar technology – there’s no need to spend your time getting stuck in traffic trying to get to campus!",
        "Access our extensive e-library of 10,000 journals and over 200,000 e-books from the convenience of your browser. ",
        "Academic programs are designed to assist individuals at every stage of their career, from career-starting through to career-defining, and include undergraduate, postgraduate and research degrees. ",
        "Partnership with industry bodies to offer our students industry certifications that add additional value to their degree. ",
        "Highly Subsidized fee with installment payment options",
      ],
      programme: [
        {
          title: "Bachelor of Business Administration (BBA)",
          fee: {
            amount: "$6000",
            installments: 9,
            installmentPeriod: "3 years",
            intakes: "October, March & July",
          },
          timing: "Completely online",
          specialization:
            "Marketing, International Business, Finance, Logistics, Human Resource Management",
          aboutProgramme: [
            "The Bachelor of Business Administration (Hons.) provides students with the necessary skills to be effective professionals in corporate administration. Through this 3-year intensive degree program, students are trained to understand the various disciplines and best practices for organizational management.",
          ],

        },
      ],
      courses: [
        {
          title: "FOUNDATION COURSES",
          aboutProgramme: "",
          programGroups: [
            {
              title: "Foundation in Business Studies",
              programmes: [
                "A stepping stone for O-Levels (or equivalent) school leavers who are interested in a career in Business and Management. Upon completion of this level, students can pursue the Bachelor’s and later on the Master’s in Business Administration offered by MIU to gain complete understanding of the business world. ",
              ],

            },
            {
              title: "Foundation in Engineering ",
              programmes: [
                "The right platform for O-Levels (or equivalent) school leavers to pursue a degree in Engineering. This course provides the preliminary knowhow to complete a Bachelor’s degree in any stream of engineering. ",
              ],

            },
            {
              title: "Foundation in Science ",
              programmes: [
                "A learning structure fully accredited by Malaysian Qualifications Agency with a general overview of scientific disciplines to best prepare students to follow their dream of a fulfilling career in the world of science. ",
              ],

            },
            {
              title: "English Language Enhancement Program ",
              programmes: [
                "This programme has been designed specifically to ensure that the students become competent in communicating in English. Students emerge as confident communicator at the end of this 1-year programme. This course incorporates essential aspects meeting and communicating with people in formal and informal situations, ability to read and comprehend the language and teaches the nuances of written communication in the language. Completion of this course enables a student to clear the IELTS examination and enter a diploma or foundation course at MIU. ",
              ],

            },
          ],
        },
        {
          title: "SCHOOL OF SCIENCE AND ENGINEERING ",
          aboutProgramme: "",
          programGroups: [
            {
              title: "Bachelor of Commerce Professional (B.Com) ",
              programmes: [
                "A programme designed to create qualified technical assistants in the field of mechanical engineering skilled with the nuances of the field to create a rewarding career for themselves. ",
              ],
            },
            {
              title: "Diploma in Civil Engineering ",
              programmes: [
                "A curriculum aimed at producing qualified and trained Civil Engineering technical assistants well-equipped to conduct themselves with integrity and professionalism. ",
              ],
            },
            {
              title: "Diploma in Computer and Electronics Engineering ",
              programmes: [
                "A curriculum structure aimed to impart all the necessary skills and knowledge to pursue a career in the fields of computing or electronics. ",
              ],
            },
            {
              title: "Bachelor of Chemical Engineering (HONS) ",
              programmes: [
                "A 4-year program recognized worldwide and developed in conformance with the Washington Accord to impart relevant and updated knowledge and skills in students. ",
              ],
            },
            {
              title: "Bachelor of Civil Engineering (HONS) ",
              programmes: [
                "An intensive programme that provides a comprehensive understanding of the field to create civil engineers backed by strong theoretical and practical knowledge. ",
              ],
            },
            {
              title:
                "Bachelor of Electrical and Electronics Engineering (HONS) ",
              programmes: [
                "A course that prepares students to pursue a rewarding career in the electrical and electronics industry through a strong knowledge of business backed by technical know-how. ",
              ],
            },
            {
              title: "Bachelor of Mechanical Engineering (HONS) ",
              programmes: [
                "A program based on a problem-solving approach to train students to be more capable and creative in finding solutions to everyday problems. ",
              ],
            },
            {
              title: "Bachelor of Computer Engineering (HONS) ",
              programmes: [
                "Creating high-calibre professional software engineers through a course structure backed by an exhaustive understanding of the IT industry. ",
              ],
            },
          ],
        },
        {
          title: "SCHOOL OF MANAGEMENT AND BUSINESS ",
          aboutProgramme: "",
          programGroups: [
            {
              title: "Diploma in Business Administration ",
              programmes: [
                "A course intended to equip students with core business principles and entrepreneurial skills to pursue a rewarding career in the field of business and management. ",
              ],
            },
            {
              title: "Diploma in Mass Communication ",
              programmes: [
                "An educational programme aligned to industry requirements and equipped with international faculty to create a firm grounding in the field of communication and media. ",
              ],
            },
            {
              title: "Bachelor of Business Administration (HONS) ",
              programmes: [
                "A 3-year intensive degree program that equips students with the skills and knowledge to become successful professionals in a variety of sectors. ",
              ],
            },
            {
              title:
                "Bachelor of Business Administration- International Business (HONS) ",
              programmes: [
                "A course in business administration especially designed to enable students to develop a keen understanding of the global business scenario and build a career beyond the boundaries of geography. ",
              ],
            },
            {
              title: "Bachelor of Accounting (HONS) ",
              programmes: [
                "A comprehensive building block to a career in Accounting based on credibility and sound knowledge in an area that is so very vital in every aspect of economy creation and growth. ",
              ],
            },
            {
              title: "Bachelor of Mass Communications (HONS) ",
              programmes: [
                "Honing the skills of creative students by training them on various aspect of the communication industry and enabling for life in the world of media, communications, public relations, corporate relations and other such fields. ",
              ],
            },
          ],
        },
      ],
      modeOfPayment: [
        {
          image: paymentIcon,
          name: "Card payment",
          description: "Make payment to school directly",
          action: "Make payment",
          link: "https://payment.miu.edu.my/"
        },
        {
          image: paymentIcon,
          name: "Bank Transfer",
          description: "Make payment to school directly",
          action: "View Details",
          info: [
            {}, // put in an empty object to make the payment modal work properly
            {
              title: "Bank Transfer (MIU)",
              beneficiary: "Manipal International University",
              bankName: "Malayan Banking Berhad",
              bankAddress: "PT. 7443, Jalan BBN 1/2F, Bandar Baru Nilai, 71800 Nilai, Negeri Sembilan Darul Khusus, Malaysia",
              acctNo: "5551 0830 2982",
              branchName: "Bandar Baru Nilai",
              branchCode: "5510",
              swiftCode: "MBBEMYKL"
            },
          ]
        },
      ],
      // modeOfPayment: ["Bank Transfer", "Card Payment via School Website"],
      accreditations: manipalIUImages,
      alumni: {
        network: "1,000,000+",
        activeStudents: "400,000+",
        countries: "81+",
        campuses: "10",
        individuals: [],
      },
    },
  },
  {
    id: 8,
    name: "Manipal GlobalNXT University ",
    accronym: "GlobalNXT",
    logo: manipalGNLogo,
    image: manipalGlobalNxt,
    noJotForm: true,
    otherProgram: true,
    // parentInstitution: 6,
    link: "https://gnu.vigilearnapply.com/register/individual",
    about: [
      "A leading online learning platform, Manipal GlobalNxt University (MGNU), is an excellent choice to pursue higher education and progress on individual career pathways. Committed to providing students with a lifetime of learning, the University aims to provide education that delivers meaningful and breakthrough educational outcomes, anytime and anywhere. Leveraging a unique online global classroom pedagogy, MGNU focuses on education surpassing geographic limitations with 24/7 online support. ",
    ],
    learnMore: {
      image: manipalGlobalNxtSq,
      aboutUs:
        "A leading online learning platform, Manipal GlobalNxt University (MGNU), is an excellent choice to pursue higher education and progress on individual career pathways. Committed to providing students with a lifetime of learning, the University aims to provide education that delivers meaningful and breakthrough educational outcomes, anytime and anywhere. Leveraging a unique online global classroom pedagogy, MGNU focuses on education surpassing geographic limitations with 24/7 online support. ",
      whyUs: [
        "Unique virtual classroom model utilizes best-in-class learning technology to enable students to learn anytime, anywhere. ",
        "Partnerships with International universities that create pathway to Australia, New Zealand, UK, UAE, US and more! ",
        "Continuous interaction with a dedicated team of experienced, PhD-qualified professors from across the globe.  ",
        "Professors delivering live lectures using the latest webinar technology – there’s no need to spend your time getting stuck in traffic trying to get to campus!",
        "Access our extensive e-library of 10,000 journals and over 200,000 e-books from the convenience of your browser. ",
        "Academic programs are designed to assist individuals at every stage of their career, from career-starting through to career-defining, and include undergraduate, postgraduate and research degrees. ",
        "Partnership with industry bodies to offer our students industry certifications that add additional value to their degree. ",
        "Highly Subsidized fee with installment payment options",
      ],
      programme: [
        {
          title: "Masters of Business Administration (MBA)",
          fee: {
            amount: "$2,000",
            oldAmount: "$6,000",
            installments: 6,
            installmentPeriod: "2 years",
            intakes: "January, May & September",
          },
          specialization:
            "FINANCE, MARKETING, INFORMATION TECHNOLOGY, HEALTHCARE MANAGEMENT, PROJECT MANAGEMENT, INTERNATIONAL BUSINESS, SUPPLY CHAIN MANAGEMENT, STRATEGIC MANAGEMENT",
          aboutProgramme: [
            "The online course is tailor-made for experienced professionals who want to maximize their knowledge base and accelerate their professional development. Offering a learning curve and an experience that goes beyond the physical boundaries of a classroom, Manipal GlobalNxt University’s MBA degree offered online focuses on broadening practical knowledge in the subject. Aimed at developing the leadership skills of the learners it acquaints them with the complexities as well as the nature of the ever-changing, ever-evolving business challenges. ",
          ],
        },
        {
          title:
            "Master of Science in Information Technology Management (Msc. ITM) ",
          fee: {
            amount: "$2,000",
            oldAmount: "$6,000",
            installments: 6,
            installmentPeriod: "2 years",
            intakes: "January, May & September",
          },
          aboutProgramme: [
            "Manipal GlobalNxt University’s Masters of Science in IT Management is ideal for graduates who aim to develop in-depth analytical expertise for a long-running and successful career in today’s fast-growing Information Technology sector. The masters in information technology program focuses on real-world skills for the strategic application of IT to deliver value to workplaces through the optimum utilisation of technological innovations.",
            "The course aims at producing hybrid IT managers well-versed with not just technical components of Information Technology, but also with the different elements of business and management with a global perspective on emerging technologies, practices and methodologies. The focus of the program is to reduce the talent gap when it comes to skilled professionals proficient with both IT and management. ",
          ],

        },
        {
          title: "Doctor of Business Administration (DBA)",
          fee: {
            amount: "$6,000",
            installments: 9,
            installmentPeriod: "3 years",
            intakes: "January, May & September",
          },
          aboutProgramme: [
            "The dynamic online Manipal DBA degree provides both practical and theoretical knowledge about real-world business challenges, covering business research as well as application of theories thereby equipping learners with in-depth expertise in the desired area of study. The program offers extensive insights into the field of business administration and helps the learners become eligible for senior-level leadership opportunities. It is aimed at improving the executive as well as the professional practice of business administration by encouraging learners to break complex business and management issues with the application of thorough research and practical knowledge. ",
          ],
        },
        {
          title: "Doctor of Philosophy in Education (PhD Education)",
          fee: {
            amount: "$6,000",
            installments: 5,
            installmentPeriod: "3 years",
            intakes: "January, May & September",
          },
          aboutProgramme: [
            "The online PhD program at Manipal GlobalNxt University focuses on extending practical knowledge in the essential discipline of education. The program aims to develop talent for the education sector, which is in high demand in today's knowledge-based economy. Rather than going through numerous modules covering theoretical ideas, learners in this program must complete only three research modules in order to acquire a doctorate. The curriculum is flexible enough to allow students to choose from a variety of study areas.",
          ],
        },
        {
          title: "Master of Education (M.Ed.)",
          fee: {
            amount: "$2,000",
            installments: 2,
            installmentPeriod: "2 years",
            intakes: "January, May & September",
          },
          aboutProgramme: [
            "The Master of Education (M.Ed.) is a two-year research-based program. The program is designed for individuals who want to either prepare for a career as an educator or advance their existing professional prospects. The program is flexible enough to allow learners to select their area of research across diverse areas such as outcome-based education (OBE), curriculum development, pedagogy and assessment design, adult learning, learning styles, technology in learning, early childhood education, remote learning, open and distance learning (ODL), learner life cycle management and more.",
          ],
        },
        {
          title: "Master of Science in Business (MSc Business)",
          fee: {
            amount: "$2,000",
            installments: 2,
            installmentPeriod: "2 years",
            intakes: "January, May & September",
          },
          aboutProgramme: [
            "The Master of Science in Business program allows business executives to concentrate on the issues that are most important to them and their company. To achieve a master's degree, students must complete only three research modules in total. The program is flexible enough to allow learners select their area of research across diverse functional areas like business analytics, strategy, marketing, accounting, finance, information technology management, leadership, human resource management, and operations.",
          ],
        },
      ],
      modeOfPayment: [
        {
          image: paymentIcon,
          name: "Card payment",
          description: "Make payment to school directly",
          action: "Make payment",
          link: "https://mycampus.globalnxt.edu.my/gnupayments"
        },
        {
          image: paymentIcon,
          name: "Bank Transfer",
          description: "Make payment to school directly",
          action: "View Details",
          info: [
            {}, // put in an empty object to make the payment modal work properly
            {
              title: "Bank Transfer (Global NXT)",
              beneficiary: "Edunxt Global Sdn. Bhd.",
              bankName: "Malayan Banking Berhad",
              bankAddress: "Lot AG 1-2 & AG 9-10, Block A, Plaza Pekeliling, 2, Jalan Tun Razzak 50500 Kuala Lumpur, Malaysia",
              acctNo: "714075001077",
              branchName: "Jalan Ipoh Branch",
              branchCode: "14075",
              swiftCode: "MBBEMYKL",
              payDetails: "Please state your full name and Student Identification Number",
            },
          ]
        },
      ],
      accreditations: manipalGlobalNxtImages,
      alumni: {
        network: "1,000,000+",
        activeStudents: "400,000+",
        countries: "81+",
        campuses: "10",
        individuals: [],
      },
    },
  },
  {
    id: 9,
    name: "Manipal Academy Of Higher Education, Dubai (MAHE) ",
    accronym: "MAHE",
    logo: manipalAHELogo,
    image: manipalAHE,
    // secondJotForm: true,
    noJotForm: true,
    link: "https://mahe.vigilearnapply.com/register/individual",
    otherProgram: true,
    // parentInstitution: 6,
    about: [
      "Manipal Academy of Higher Education (MAHE Dubai) is one of the leading universities in the Middle East region. Home to 2000+ students from over 40 nations worldwide, MAHE Dubai has made possible 4000+ successful student graduations students across disciplines in the last two decades. Located in Dubai International Academic City, a melting pot of culture, the students also benefit from a state-of-the-art on-campus hostel facility, making them feel at home. ",
    ],
    learnMore: {
      image: manipalGlobalNxtSq,
      awards: [
        manipalAward1,
        manipalAward2,
        manipalAward3,
        manipalAward4,
        manipalAward5,
      ],
      aboutUs:
        "Manipal Academy of Higher Education (MAHE Dubai) is one of the leading universities in the Middle East region. Home to 2000+ students from over 40 nations worldwide, MAHE Dubai has made possible 4000+ successful student graduations students across disciplines in the last two decades. Located in Dubai International Academic City, a melting pot of culture, the students also benefit from a state-of-the-art on-campus hostel facility, making them feel at home. ",
      whyUs: [
        "Unique virtual classroom model utilizes best-in-class learning technology to enable students to learn anytime, anywhere. ",
        "Partnerships with International universities that create pathway to Australia, New Zealand, UK, UAE, US and more! ",
        "Continuous interaction with a dedicated team of experienced, PhD-qualified professors from across the globe.  ",
        "Professors delivering live lectures using the latest webinar technology – there’s no need to spend your time getting stuck in traffic trying to get to campus!",
        "Access our extensive e-library of 10,000 journals and over 200,000 e-books from the convenience of your browser. ",
        "Academic programs are designed to assist individuals at every stage of their career, from career-starting through to career-defining, and include undergraduate, postgraduate and research degrees. ",
        "Partnership with industry bodies to offer our students industry certifications that add additional value to their degree. ",
        "Highly Subsidized fee with installment payment options",
      ],
      programme: [
        {
          title: "SCHOOL OF ENGINEERING & IT​",
          aboutProgramme: "",
          programGroups: [
            {
              title: "B.Tech (Day)​",
              programmes: [
                "(Chemical, Civil, Computer Science, Data Science & Engineering, Electrical & Electronics, Mechanical, Mechatronics) ",
              ],

            },
            {
              title: "BTech (Evening)​ ",
              programmes: ["(Civil, Electrical & Electronics, Mechanical) "],

            },
            {
              title: "BSc - Information Systems & Management ​",
              programmes: [],

            },
            {
              title: "MTech​​ (Day)",
              programmes: [
                "(Chemical, Civil, Computer Science, Data Science & Engineering, Electrical & Electronics, Mechanical, Mechatronics) ",
                "MSc - Information Science ",
                "PG Diploma - Cyber Security ",
                "PG Diploma - Data Science",
                "PhD – Engineering",
              ],

            },
          ],
        },

        {
          title: "SCHOOL OF BUSINESS ",
          aboutProgramme: "",
          programGroups: [
            {
              title: "Bachelor of Commerce Professional (B.Com)​",
            },
            {
              title: "Bachelor of Commerce Professional (B.Com) (with ACCA)​",
            },
            {
              title: "Bachelor in Business Admin (BBA) (Day)",
            },
            {
              title: "Bachelor in Business Admin (BBA) (Evening)​",
            },
            {
              title: "MBA Global Business (Day)​",
            },
            {
              title: "Work Mode MBA (Evening/Weekend)",
            },
            {
              title: "PG Diploma - Business Administration​",
            },
            {
              title: "PG Diploma - Logistics & Supply Chain Management​",
            },
            {
              title: "PhD – Management​",
            },
          ],
        },

        {
          title: "SCHOOL OF DESIGN & ARCHITECTURE​",
          aboutProgramme: "",
          programGroups: [
            { title: "Bachelor of Architecture (B.Arch) " },
            { title: "B.Des - Fashion Design" },
            { title: "B.Des - Interior Design" },
            { title: "BSc - Design Management" },
            { title: "MA - Fashion Management " },
            { title: "M.Des - Sustainable Design" },
            { title: "Certificate Program - Interior Design" },
            { title: "BA - Media & Communication" },
            { title: "BA - Film Studies & TV Production" },
            { title: "BSc – Animation" },
            { title: "MA - Media & Communication" },
            { title: "PG Diploma - Event Management" },
            { title: "Certificate Program - Graphics & Multimedia" },
          ],
        },

        {
          title: "SCHOOL OF LIFE SCIENCES​ ​",
          aboutProgramme: "",
          programGroups: [
            { title: "BSc – Biotechnology" },
            { title: "BSc - Food & Nutrition Science" },
            { title: "MSc - Medical Biotechnology" },
            { title: "MSc - Molecular Biology & Human Genetics" },
            { title: "MSc - Nutrition & Dietetics" },
            { title: "PhD – Biotechnology" },
          ],
        },

        {
          title: "DEPARTMENT OF ARTS & HUMANITIES​​",
          aboutProgramme: "",
          programGroups: [
            { title: "BA - Humanities (Literature, Psychology, Sociology) " },
          ],
        },

        {
          title: "FOUNDATION PROGRAMMES​",
          aboutProgramme: "",
          programGroups: [
            { title: "MAHE Dubai Foundation Program" },
            { title: "English Enhancement Program " },
          ],
        },
      ],
      modeOfPayment: [
        {
          image: paymentIcon,
          name: "Card payment",
          description: "Make payment to school directly",
          action: "Make payment",
          link: "https://payment.manipaldubai.com/"
        },
        {
          image: paymentIcon,
          name: "Bank Transfer",
          description: "Make payment to school directly",
          action: "View Details",
          info: [
            {}, // put in an empty object to make the payment modal work properly
            {
              title: "Bank Transfer (MAHE)",
              beneficiary: "MANIPAL EDUCATION (MENA) FZ LLC",
              bankName: "STANDARD CHARTERED BANK",
              bankAddress: "BUR DUBAI BR., DUBAI, UAE",
              acctNo: "01-3892867-01",
              IBanNumber: "AE930440000001389286701",
              swiftCode: "SCBLAEADXXX"
            },
          ]
        },
      ],
      accreditations: manipalAHEImages,
      internships: manipalAHEIntImages,
      rating: {
        stars: 5,
        comment: "Rated 5 star with Highest Overall Rating Score - 836",
        issuer: "KHDA Higher education Ratings 2020",
      },
      scholarships: [
        "For all UG (Full time day) programs: 30% scholarship on tuition fees",
        "For all UG Evening programs: 25% scholarship on tuition fees",
        "For all Master’s program – Full time day: 25% scholarship on tuition fees",
        "For all Master’s Program evening mode: 20% scholarship on tuition fees",
        "For all certificate and foundation program: 20% scholarship on tuition fees",
      ],
      alumni: {
        network: "1,000,000+",
        activeStudents: "400,000+",
        countries: "81+",
        campuses: "10",
        individuals: [
          {
            name: "Rajeev Suri",
            image: rajeevSuri,
            post: "CEO, Inmarsat",
          },
          {
            name: "Satya Nadella",
            image: satyaNadella,
            post: "CEO, Microsoft",
          },
          {
            name: "Datuk sheikh muszaphar",
            image: datukSheikhMuszaphar,
            post: "Malaysian Astronaut",
          },
        ],
      },
    },
  },

  {
    id: 10,
    parterned: true,
    name: "Hult International Business School",
    accronym: "HULT",
    logo: hultLogo,
    image: hultPartner,
    noJotForm: true,
    otherProgram: true,
    partnerBrochure: hultBrochure,
    link: "https://hult.vigilearnapply.com/register/individual",
    // parentInstitution: 6,
    learnMore: {
      image: hultPartner,
      aboutUs:
        "Hult International Business School is a private institution that was founded in 1964. Being global and multicultural is not a strategy, but the institute’s identity. Hult has designed a business school that looks to the future by focusing on developing your leadership skills, challenging you with a future-oriented curriculum, and building the global perspective that only first-hand experience can bring. ",
      whyUs: [
        {
          title: "Change Through Challenge",
          details:
            "Whether you’re figuring out how best to apply new technology to an existing business or developing a startup idea, you’ll collaborate to solve challenges businesses are facing right now.",
        },
        {
          title: "Find Your Entrepreneurial Spirit",
          details:
            "Learning from professors who are experienced entrepreneurs, consulting for local business owners, and launching your own ventures are staples of the Hult experience. ",
        },
        {
          title: "Expose Yourself to a Collaborative Environment",
          details:
            "Creating spaces that inspire our students and encourage collaboration is important to us at Hult. We select our campuses based on their central location & their potential for conversion into open, contemporary learning environments.",
        },
        {
          title: "Utilize the Power of Working Relationships",
          details:
            "The strength of your working relationships has a big influence on your success and your wellbeing. At Hult, you’ll find a welcoming group of people that you can learn from and rely on—be it supportive professors, talented teammates, or likeminded club members.",
        },
        {
          title: "Build a Better Future Together Change Through Challenge",
          details:
            "Arguably the greatest challenge facing us today is creating a sustainable society.At Hult, our students are part of an ecosystem that aims to create a culture of sustainable business thinking, from the ground up.",
        },
        {
          title: "Work Within Teams",
          details:
            "Business success begins and ends with teamwork. Working in diverse teams to solve real-world business challenges is at the center of every Hult program & it is what will make you stand out in every field.",
        },
        {
          title: "Gain Hands-on Experience",
          details:
            "As part of your everyday education at Hult, you’ll put theory into practice and gain hands-on experience via team projects and live client challenges.",
        },
      ],
      applyImage: hultApply,
      apply: [
        {
          index: number1,
          step: <span>Complete your online application <a href='https://hult.vigilearnapply.com/register/individual' target="_blank" rel="noreferrer" style={{ textDecoration: 'underline', color: 'white', fontWeight: 'bold' }}>here</a></span>,
        },
        {
          index: number2,
          step: "Pay 75 USD application fee",
        },
        {
          index: number3,
          step: "Submit application and supporting documents like CV, Personal statement and University transcripts",
        },
        {
          index: number4,
          step: "Personal assessment interview",
        },
        {
          index: number5,
          step: "Admissions decision",
        },
        {
          index: number6,
          step: "Submit deposit to confirm your seat",
        },
      ],
      statistics: [
        {
          type: {
            image: stats,
            label: "MASTERS"
          },
          info: [
            {
              percentage: "91%",
              description: "of Hult Masters graduates are employed within six months of graduation",
            },
            {
              percentage: "96%",
              description: "of Hult Masters graduates changed industry, country or function",
            },
          ],
          salary: {
            title: "Average Salary",
            description: "increase of 47% shortly after graduation",
          },
          employers: {
            title: "Leading Employers",
            group: hultImages
          },
        },
        {
          type: {
            image: stats2,
            label: "UNDERGRADUATE"
          },
          info: [
            {
              percentage: "55%",
              description: "of BBA graduates are now working in a new country",
            },
            {
              percentage: "98%",
              description: "of Bachelor of Business Administration (BBA) graduates are employed or have attended graduate school within six months of graduation.",
            },
          ],
          salary: {
            title: "Top 5 Countries",
            description: "BA graduates are working in: US, UK, Germany, UAE, Spain",
          },
          employers: {
            title: "Leading Employers",
            group: hultImages2
          },
        },
        {
          type: {
            image: stats3,
            label: "MBA"
          },
          info: [
            {
              percentage: "89%",
              description: "of Hult MBA graduates are employed within three months of graduation",
            },
            {
              percentage: "95%",
              description: "of Hult Masters graduates changed industry, country or function",
            },
          ],
          salary: {
            title: "Average Salary",
            description: "increase of 122% shortly after graduation",
          },
          // employers: {
          //   title: "Leading Employers",
          //   group: hultImages
          // },
        },
      ],
      requirements: [
        {
          title: "MIB, MIM, MFIN, MBAN, MEI, MSE",
          list: [
            "Bachelor’s degree or equivalent",
            "Proficiency in English (TOEFL/IELTS/PTE)",
            "Successful application process"
          ],
        },
        {
          title: "MIM, MFIN, MBAN",
          list: [
            "Bachelor’s degree in a business-related field",
            "OR",
            "Bachelor’s degree in any subject + one year’s relevant work experience"
          ],
        },
        {
          title: "MIB",
          list: [
            "Less than three years’ work experience"
          ],
        },
      ],
      location: {
        info: "At Hult, your ambition isn’t limited by boundaries—here, your degree will take you beyond business to blaze your own trail and become a leader in today’s business world. With campus locations in six cities, and students of unparalleled diversity, you’ll live a global education, not just learn about it. ",
        places: [
          {
            image: Boston,
            name: "Boston",
          },
          {
            image: Dubai,
            name: "Dubai",
          },
          {
            image: SanFransisco,
            name: "San Fransisco",
          },
          {
            image: London,
            name: "London",
          },
          {
            image: NewYork,
            name: "New York",
          },
          {
            image: Shanghai,
            name: "Shanghai",
          },
        ],
      },
      programme: [
        {
          title: "Bachelor of Business Administration",
          fee: {
            amount: "$49,950, £32,950 respectively",
            mode: "Hybrid",
            duration: "2 - 4 years"
          },
          majors: "Analytics, Entrepreneurship, Finance, Management, Marketing",
          minors: "Computer Science, Design, Psychology",
          study: [
            "Boston",
            "London",
          ],
          aboutProgramme: [
            "Hult’s transformative Bachelor of Business Administration (BBA) is designed around the universal truth that to master a skill—any skill, from riding a bike to leading a team—it must be practiced. As every business person will say: knowledge of theory alone is not enough to be successful.",
            "You can transfer your credits after 2 years of study in another university to Hult, meaning you can then complete the final two years of study from one of the Hult campuses. You can also begin your degree program from home then complete the final two years of study from one of the Hult campuses."
          ],

        },
        {
          title: "Global One-Year MBA ",
          fee: {
            amount: "$79,800, £56,900, 289,000 AED respectively",
            mode: "100% on campus",
            duration: "1 year"
          },
          study: [
            "Boston",
            "San Francisco",
            "Dubai",
            "London",
          ],
          aboutProgramme: [
            "Secure an MBA that will give you a competitive edge in the rapidly changing global economy and future-proof your career. Hult’s MBA program has been designed to develop the skills most in demand with employers worldwide so you can make an impact from your first day on the job.",
          ],

        },
        {
          title: "Executive MBA",
          fee: {
            amount: "$85,000, £57,800, 314,000 AED respectively",
            mode: "50% on campus, 50% online",
            duration: "2 years"
          },
          study: [
            "Boston",
            "Dubai",
            "London",
          ],
          aboutProgramme: [
            "You want an MBA that will give you a competitive edge in the rapidly changing global economy and future-proof your career. Hult’s MBA program has been designed to develop the skills most in demand in businesses worldwide so you can make an impact—in both corporate and entrepreneurial environments.",
          ],

        },
        {
          title: "Hybrid MBA",
          fee: {
            amount: "$57,750, £37,200, 196,000 AED respectively ",
            mode: "50% on campus, 50% online",
            duration: "2 years"
          },
          study: [
            "Boston",
            "Dubai",
            "London",
          ],
          aboutProgramme: [
            "The Hult hybrid MBA that will give you a competitive edge in the rapidly changing global economy and future-proof your career. Hult’s MBA program has been designed to develop the skills most in demand in businesses worldwide so you can make an impact—in both corporate and entrepreneurial environments.",
          ],

        },
        {
          title: "Master’s in International Business ",
          fee: {
            amount: "$49,800, $52,800, £37,000, 176,000 AED respectively",
            mode: "100% on campus",
            duration: "1 year"
          },
          study: [
            "Boston",
            "San Francisco",
            "Dubai",
            "London",
          ],
          aboutProgramme: [
            "Gain a broad, practical understanding of global business, get on the- ground international experience, and tailor your degree to your own interests and goals.",
          ],

        },
        {
          title: "Master’s in International Marketing",
          fee: {
            amount: "$49,800, £37,000 respectively",
            mode: "100% on campus",
            duration: "1 year"
          },
          study: [
            "Boston",
            "London",
          ],
          aboutProgramme: [
            "Gain the international strategic skills, creative executional ability, and cutting-edge knowledge to launch a global marketing career in a constantly shifting digital environment.",
          ],

        },
        {
          title: "Master’s in Business Analytics",
          fee: {
            amount: "$49,800, £37,000 respectively",
            mode: "100% on campus",
            duration: "1 year"
          },
          study: [
            "Boston",
            "San Francisco",
          ],
          aboutProgramme: [
            "Gain the analytical capability and business acumen to translate data into meaningful actions.",
          ],

        },
        {
          title: "Master’s in Entrepreneurship & Innovation",
          fee: {
            amount: "$52,800",
            mode: "100% on campus",
            duration: "1 year"
          },
          study: [
            "San Francisco",
          ],
          aboutProgramme: [
            "Gain skills that are at the center of value creation, from startups to global enterprises, and learn to devise and deliver new products and services.",
          ],

        },
        {
          title: "Master’s in Social Entrepreneurship",
          fee: {
            amount: "$52,800",
            mode: "100% on campus",
            duration: "1 year"
          },
          study: [
            "San Francisco",
          ],
          aboutProgramme: [
            "Learn to create social impact while building profitable businesses through a commitment to Profit, People, & Planet.",
          ],

        },
      ],
      modeOfPayment: [
        {
          image: paymentIcon,
          name: "Card payment",
          description: "Make payment to school directly",
          action: "Make payment",
        },
        {
          image: paymentIcon,
          name: "Bank transfer or cash",
          description: "*Convenience fee of $35 applies",
          action: "View Details",
          info: [
            {
              title: "Bank Transfer or cash deposit via Edutech",
              beneficiary: "Edutech Advanced Business Solution",
              acctName: "Edutech Advanced Business Solution",
              swift: "Zeibngla",
              benAcctNo: "5071007042",
              zenSwift: "Zenith Bank Plc",
              intermediaryTitle: "Intermediary Bank Details",
              bank: "Citibank N.A. Canada Square Canary Wharf London E14 5LB",
              sortCode: "18-50-08",
              swiftAddr: "CITIGB2L",
              IBanNumber: "GB80CITI18500810407941",
              acctNo: "10407941 (USD)"
            },
          ]
        },
        {
          image: paymentIcon,
          name: "Card payment via EduTech",
          description: "*Convenience fee of $35 applies",
          action: "Make payment",
        },
      ],
      // modeOfPayment: ["Bank Transfer", "Card Payment via School Website"],
      accreditations: hultAccreditationImages,
      alumni: {
        network: "10,000+",
        activeStudents: "3,000+",
        countries: "140+",
        campuses: "5+",
        individuals: [],
      },
    },
  },
  {
    id: 11,
    parterned: true,
    name: "Rochester Institute of Technology of Dubai",
    accronym: "RIT",
    logo: ritLogo,
    isRIT: true,
    image: ritPartner,
    noJotForm: true,
    partnerBrochure: ritBrochure,
    otherProgram: true,
    link: "https://rit.vigilearnapply.com/register/individual",
    learnMore: {
      image: ritPartner,
      aboutUs:
        "Established in 2008, RIT Dubai is a not-for-profit global campus of the esteemed Rochester Institute of Technology in New York, one of the world’s leading technological-focused universities with a storied 185-year history. RIT Dubai offers highly valued Bachelor’s and Master’s degrees in business and leadership, engineering, and computing. The curriculum provides students with relevant work experience through an innovative cooperative education program that helps students stand out in today’s highly completive job market.",
      whyUs: [
        {
          title: "Almost 200 years of History",
          details:
            "Founded in 1829, as the Rochester Athenaeum, which was an association to cultivate and promote literature, science, and arts. The university now has campuses in China, Croatia, Dubai, and Kosovo.",
        },
        {
          title: "Study Abroad Programs",
          details:
            "Transfer to New York or one of RIT partner campuses in Europe and Asia. Begin your program in Dubai and complete the final semester abroad, or spend a Summer traveling while learning.",
        },
        {
          title: "Special Co-op Internships",
          details:
            "Cooperataive Education, or co-op, is different from a normal internship. RIT arranges full-time paid work related to your field of study. You will have immersive real-world work experience by the time you graduate.",
        },
        {
          title: "Fully Accredited",
          details:
            "IT Dubai is licensed by the Knowledge and Human Development Authority (KHDA), the regulatory authority in the Government of Dubai, and accredited by the Ministry of Education- Higher Education Commission.",
        },
        {
          title: "Flexible Class Timing",
          details:
            "Masters students are allowed continuity of work while studying because RIT graduate programs are held in the evenings and on weekends.",
        },
      ],
      applyImage: ritApply,
      apply: [
        {
          index: number1,
          step: <span>Complete your online application  <a href='https://rit.vigilearnapply.com/register/individual' target="_blank" rel="noreferrer" style={{ textDecoration: 'underline', color: 'white', fontWeight: 'bold' }}>here</a></span>,
        },
        {
          index: number2,
          step: "Fill the application form displayed on the screen appropriately",
        },
        {
          index: number3,
          step: "Await further instruction via email to complete application & registration",
        },
        {
          index: number4,
          step: "Visa Processing",
        },
        {
          index: number5,
          step: "Get Onboarded on School System",
        },
        {
          index: number6,
          step: "Begin studying",
        },
      ],
      requirements: [
        {
          title: "UNDERGRADUATES",
          list: [
            "A scanned copy of your passport.",
            "High school transcripts - predicted marks of your final year are acceptable for a conditional decision until you submit your final marks.",
            "Emirates ID (UAE Residents).",
            "English language test (IELTS, Toefl, or EmSAT) – if available.",
            "Personal statement."
          ],
        },
        {
          title: "POSTGRADUATES",
          list: [
            "Applicants should hold or currently be completing, a bachelor degree, or the U.S. equivalent granted by an accredited college or university. Original transcripts must be submitted to RIT for evaluation with a certified English translation of transcripts where required.",
            "Bachelor’s degree in any subject + one year’s relevant work experience",
            "Applicants should satisfy the standard RIT requirements for the academic performance of approximately a 'B' grade average (3.0 on a 4.0 scale) in undergraduate studies. Applicants with GPAs below 3.0 may get offered conditional admission to the graduate programs.",
            "Applicants should meet all appropriate undergraduate pre-requisites:",
            "Candidates for the Engineering master’s programs should have an undergraduate bachelor’s degree in the same discipline of engineering, or an area closely related to the discipline they wish to study.",
            "Candidates for the Networking and Systems Administration MS program should have a solid educational or employment record in networking, security and systems administration.",
            "Candidates for the MS Service Leadership and Innovation program should have a solid educational or employment record in any field upon admission evaluation.",
            "Applicants must submit a resume (curriculum vitae) to demonstrate professional work experience in the desired area of study.",
            "Students whose native language is English, or who have studied at the college or university level in the US or an English-speaking country are still required to take the TOEFL or IELTS language exam due to a requirement by the Ministry of Higher Education in UAE as RIT is accredited by the Ministry of Higher Education in UAE and is Internationally recognized."
          ],
        },
      ],
      openings: {
        info: "Students of RIT Dubai also have the unique opportunity to choose to study abroad at the main campus in New York or at one of its other global campuses.",
        offerings: [
          {
            image: USA,
            title: "American degrees"
          },
          {
            image: UAE,
            title: "UAE Ministry accredited"
          }
        ]
      },
      programme: [
        {
          title: "B.Sc Business Administration (Finance)",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "4 years"
          },
          aboutProgramme: [
            "As a Finance major at RIT Dubai, you will develop the ability to manage corporate finances and enhance your leadership potential in the field. Students of this program learn how to solve problems and more importantly, how to prevent problems through in-depth analysis, contingency planning, and effective decision making. You will go beyond simply developing and managing budgets to learn how to analyze a budget, determine opportunities, save money, or pursue the best investment options. You will become familiar with the financial systems involved in public, private, and government spaces and study the financial instruments related to numerous assets and liabilities. Specifically, you will study cash flow, asset and risk management, capital markets, portfolio theory, international finance, and forecasting and budgeting. Finance majors at RIT Dubai become experts at financial decision-making - one of the main differences between Finance and Accounting."
          ],
        },
        {
          title: "B.Sc Business Administration (Management)",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "4 years"
          },
          aboutProgramme: [
            "Management is the process of ensuring that an organization or company is able to operate in both the immediate and near future. Managers are charged with making decisions that will impact an organization on every level. These decisions range from hiring a new employee to taking a company public. Management is not an easy field, and good managers are highly sought after. The management program at RIT Dubai educates students on the many facets of management, while also improving their ability to motivate their peers, communicate with others and lead a team, all of which are essential skills for good managers. The program prepares students for management and specialist careers in a variety of enterprises and organizations. Students develop the skills and concepts needed to become effective leaders, ethical decision-makers, and creative innovators."
          ],
        },
        {
          title: "B.Sc Business Administration (Marketing)",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "4 years"
          },
          aboutProgramme: [
            "RIT’s Marketing program will guide your progress toward becoming an Internet Marketing professional. After Marketing Principles where you will learn the basics, it continues with a course in Integrated Marketing Communication where you will learn to woven Internet Marketing into the company’s traditional promotion mix. It then continues with an overview course, Internet Marketing, before continuing with Social Media Marketing and Search Engine Marketing. You will then develop your strategic vision with Marketing Strategy and Marketing Research. Together with RIT’s emphasis on great writing, (RIT’s writing professors are excellent) and our famous internships (we call them coops) and electives such as Webpage Design, Professional Selling, and Negotiation, these courses will turn you into a solid Internet Marketing professional that can both develop a strategic plan and execute the details. You are also required to take a minor such as Digital Business, Finance, and Management to broaden your knowledge."
          ],
        },
        {
          title: "B.Sc Business Administration (International Business)",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "4 years"
          },
          minors: "Digital business, Finance, Management, Marketing",
          aboutProgramme: [
            "Students in the international business major develop the foundation necessary to understand business as well as political and cultural diversity. Proficiency in a foreign language is an integral part of the major. A co-major is chosen in one of the following areas: accounting, finance, management, management information systems, or marketing. This secondary area of interest provides students with the functional tools needed in their careers. International business positions include substantial personal and professional benefits. Overseas assignments typically bring long hours and hard work, yet the reward of upward mobility within the corporate world continues to lure young executives to global assignments. International business students are required to select a minor from one of the business disciplines. You may select any minor from the list below."
          ],
        },
        {
          title: "B.Sc Mechanical Engineering",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "5 years"
          },
          aboutProgramme: [
            "An Engineering degree from RIT Dubai opens up a world of exciting career opportunities and Mechanical Engineering in particular, is involved with a range of cutting edge design across automotive and aerospace systems, bioengineering devices, and energy-related technologies. Our labs are equipped to provide you with extensive experimentation and feature everything from 3D printers to Universal Testing Machines, to a comprehensive machine shop to ensure you have access to state-of-the-art facilities to help you in your development as an engineer."
          ],
        },
        {
          title: "B.Sc Industrial Engineering",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "5 years"
          },
          aboutProgramme: [
            "The industrial engineering curriculum covers the principal concepts of engineering economics and project management, facilities planning, human performance, mathematical and simulation modeling, production control, applied statistics and quality, and contemporary manufacturing production processes that are applied to solve the challenges presented by the global environment and economy of today. The curriculum stresses the application of contemporary tools and techniques in solving engineering problems. Starting in their third year, the students have the choice of a general degree in industrial engineering or specialization in an option. The general degree and program options encourage a team approach. Students may work with students in other engineering disciplines to solve problems for clients outside of RIT Dubai."
          ],
        },
        {
          title: "B.Sc Microelectronics Engineering",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "5 years"
          },
          aboutProgramme: [
            "Semiconductor microelectronics technology remains important for the world economy. The semiconductor industry is a star performer in U.S. manufacturing. Fostering a vigorous semiconductor industry in our country is important for the nation’s economic growth, long-term security, and the preparation and maintenance of a capable high-tech workforce. The Kate Gleason College of Engineering developed the first Bachelor of Science degree in microelectronics engineering in the U.S., and the college continues to provide highly educated and skilled engineers for the semiconductor industry. RIT-Dubai is able to provide this program to its students in 2 years of academics in Dubai + 3 years of academics in the NY campus of RIT (including 1 year of Coop experience). In this format, students complete the basics course work in the first two years at RIT-Dubai before transferring to the RIT New York campus to complete the last three years."
          ],
        },
        {
          title: "B.Sc Electrical Engineering",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "5 years"
          },
          aboutProgramme: [
            "The BS in Electrical Engineering addresses the high-technology needs of business and industry by offering a rich academic program that includes analog and digital integrated circuits, digital signal processing, radiation and propagation, power electronics, control systems, communications, circuit theory, computer architecture, computer-aided design, embedded systems, solid-state devices, microelectromechanical systems (MEMs), robotics and energy. Our nationally and internationally recognized program combines the rigor of theory with the reality of engineering practice. As an Engineering hub, Dubai is the perfect place to study for an engineering degree and interact with industry. The major will prepare you for an exciting career within the varied electrical engineering disciplines and positions in business management. As a graduate, you will also have the foundation to pursue advanced study at the most prestigious graduate schools.  Since the ability to design is an essential part of electrical engineering, you are presented with challenging design problems in several courses, beginning with Freshman Practicum (EEEE-105) in the first year."
          ],
        },
        {
          title: "B.Sc Computing and Information Technologies",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "4 years"
          },
          aboutProgramme: [
            "Information technology is found in every aspect of our lives: the workplace, our homes, the way we communicate, and in much of the entertainment we consume. IT professionals, therefore, are in great demand and highly valued. IT professionals are solution architects, identifying complex problems and creating custom solutions that help users meet their goals. They play an integral role in any modern organization, working on all phases of IT solutions from conception to development, testing, deployment, security, and management. In this information technology degree, you’ll learn to design, implement, and manage complex IT systems. Students in the computing and information technologies major are characterized by their hands-on approach to technology. They are designers and builders, but primarily they’re enablers. Students approach complex problems and create custom solutions that help users meet their goals. They play an integral role in any modern organization, often working behind the scenes to deploy technology where it’s needed most."
          ],
        },
        {
          title: "B.Sc Computing Security",
          fee: {
            amount: "61,950 AED, 16,973 USD",
            duration: "4 years"
          },
          aboutProgramme: [
            "As technology advances rapidly, protecting individuals, companies, government entities, and global organizations from cyber threats are becoming a huge sector, with career opportunities spanning the globe. Our Bachelor of Science in Computing Security has been designed to prepare you to excel in this rapidly growing area. If you love computer science, crave a program that offers a hands-on curriculum and a range of active learning experiences and gives plenty of opportunities for independent exploration at an advanced level, the Bachelor of Science in Computing Security will be perfect. Considered a leading program in computing and network security and forensics by industry experts including the U.S. Department of Homeland Security, the Department of Defense, and the National Security Agency, the degree offers you a high level of specialization beyond what is provided by more general majors in Information Systems or Information Technology."
          ],
        },
        {
          title: "M.Eng in Engineering Management",
          fee: {
            totalAmount: "117,150 AED, 32,096 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The core curriculum for the Master of Engineering in Engineering Management program is drawn from the Kate Gleason College of Engineering, Industrial Engineering program. To develop your management expertise and ensure a well-balanced curriculum, the program is complemented with courses from the Saunders College of Business. This combination enables you to cover the dual aspects of being an engineering manager, both as a technologist and a business expert and will provide you with a thorough background in areas such as organizational behavior, finance, accounting, as well as industrial engineering expertise. The program specifically caters to working professionals and is delivered through evening classes."
          ],
        },
        {
          title: "M.Sc Professional Studies: Smart Cities",
          fee: {
            totalAmount: "130,020 AED, 35,622 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The Master of Science in Professional Studies: Smart Cities is the first program of its kind in the region and will provide you with the skills to lead the digital transformation agenda in both public and private sector contexts. Smart Cities is an emerging interdisciplinary approach that includes designers, technologists, and humanists working together to create and deploy smart systems, which can communicate live status updates through distributed networks on traffic patterns, pollution, parking, water, energy, and other vital information. The program has been created for thought-leaders who are fascinated by this fast-developing and extremely exciting area of Smart Cities; our current students include senior decision-makers from across the region who are helping to shape the future of the cities we live in. As a world leader in smart cities and urban development, Dubai is the ideal place to study this kind of program."
          ],
        },
        {
          title: "M.Sc Professional Studies: Data Analytics",
          fee: {
            totalAmount: "117,150 AED, 32,096 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The Master of Science in Professional Studies: Data Analytics is a one-of-a-kind program in the UAE that will provide you with the skills to make better organizational decisions through the usage of data analytics. The program has been designed in partnership with public departments including Smart Dubai and Dubai Data, as well as leading international organizations in the field. As a graduate of the program, you will be equipped with the skills to work effectively with complex, real-world data and create value from it in support of your organization’s strategic objectives."
          ],
        },
        {
          title: "M.Sc Professional Studies: Future Foresight and Planning",
          fee: {
            totalAmount: "117,150 AED, 32,096 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The Program aims at exploring and institutionalizing the concepts of future foresight and planning in organizations. A rich program content that includes a variety of tools and knowledge of the future will enable graduates of public sector and business leaders and other professionals to improve their strategic thinking and planning and improve the way they make future-related decisions and deal with ensuing change. Students will also be able to develop professional expertise in terms of managing and leading future foresight and strategic planning projects."
          ],
        },
        {
          title: "M.Sc Professional Studies: Service Leadership and Innovation",
          fee: {
            totalAmount: "117,150 AED, 32,096 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The Master of Science in Service Leadership & Innovation is delivered through a combination of daytime and evening classes, and online learning, culminating in a comprehensive examination or capstone project. Class schedules cater to working professionals, providing intensive seminars that will allow you to develop and practice the following competencies: Creating and innovating new service ideas, practices, and policies. Building and utilizing sophisticated human capital assets wisely. Designing, aligning, and implementing new strategic focus. Building experiences that the customer values. Application of leadership concepts to produce superior performance outcomes."
          ],
        },
        {
          title: "M.Sc Electrical Engineering",
          fee: {
            totalAmount: "106,500 AED, 29,178 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The curriculum for the Master of Science in Electrical Engineering combines theoretical fundamentals and practical lessons to help you understand and implement the practices, methodologies, and cutting-edge techniques of this dynamic field of technology. You will follow a program of study that includes courses in the areas of engineering mathematics, communications, and control, and you will have the option to complete your studies with either a thesis, graduate paper, or comprehensive exam. The program is offered in its entirety at RIT Dubai during weekday evenings, enabling working professionals to complete the degree in as little as 18 months. You can also choose to study abroad or transfer to the main campus in Rochester, New York, subject to achieving additional relevant requirements and approvals."
          ],
        },
        {
          title: "M.Sc Mechanical Engineering",
          fee: {
            totalAmount: "106,500 AED, 29,178 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The Master of Science in Mechanical Engineering is a 30-credit hour degree program. The program allocates twenty-four credit hours of course work, distributed in the core, focus area, and elective groups, and six credit hours of thesis work. Three focus areas, specializations, are offered at RIT Dubai: Sustainability and energy efficiency, Thermo/Fluids Engineering, and Mechanics & Design. A special curriculum may be designed to fulfill the needs of certain individuals with proper approvals from the main campus. The program follows the evening classes model to better serve the working engineers who strive to further their expertise."
          ],
        },
        {
          title: "M.Sc Computing Security",
          fee: {
            totalAmount: "106,500 AED, 29,178 USD",
            duration: "1 year"
          },
          aboutProgramme: [
            "The courses offered by this degree are suited to the opportunities available in both the private and public sectors. In the private sector, a considerable number of opportunities exist with banking institutions, cyber-social applications, and private investigation professional service vendors. The courses we offer around web and application security audits and forensics will be significant for these areas. In the public sector, the law enforcement and healthcare verticals will benefit significantly from the network security, forensics, and cryptography modules we have on offer. To highlight this point further, our experience from engaging with government entities shows that there is a strong demand for homegrown cryptography solutions, especially in the hardware encryption and decryption space. Similarly, with the rapid adoption of smart city technologies, offering a course related to big data and sensor security will be significant. The use of AI tools and techniques which are covered in the courses will empower individuals with the skills and approach needed to successfully contribute to the transformation of their organizations."
          ],
        },

      ],
      accreditations: ritAccreditationImages,
      alumni: {
        students: "17,000+",
        intlStudents: "1,600+",
        countriesNtl: "110+",
        campuses: "5+",
        individuals: [],
      },
    },
  },
  {
    id: 12,
    parterned: true,
    isRIT: true,
    name: "Rochester Institute of Technology, New York",
    accronym: "RIT New York ",
    logo: ritNewyorkLogo,
    image: ritPartner2,
    noJotForm: true,
    // partnerBrochure: ritBrochure,
    otherProgram: true,
    link: "https://ritnewyork.vigilearnapply.com/register/individual",
    learnMore: {
      image: ritPartner2,
      aboutUs:
        `
          Rochester Institute of Technology, New York was founded in 1829 and is the tenth largest private university in the United States in terms of full-time students.
          RIT is internationally known for its science, computer, engineering, and art programs, as well as its innovative cooperative education program that gives students professional and industrial experience. 
          Through creativity and innovation, and an intentional blending of technology, the arts, and design, RIT offers a wide range of academic opportunities including undergraduate and graduate degrees, including doctoral and professional degrees, and online masters as well. 
          RIT is also an internationally recognized leader in preparing deaf and hard-of-hearing students for successful careers in professional and technical fields.
        `
      ,
      whyUs: [
        {
          title: "Almost 200 Years of History",
          details:
            "Founded in 1829, as the Rochester Athenaeum, which was an association to cultivate and promote literature, science, and arts. The University now has campuses in China, Croatia, Dubai, and Kosovo.",
        },
        {
          title: "Special Co-Op Internships",
          details:
            "Cooperataive education, or Co-Op, is different from a normal internship. RIT arranges full-time paid work related to your field of study. You will have immersive real-world work experience by the time you graduate.",
        },
        {
          title: "Up to 3 years OPT",
          details:
            "You're eligible for up to 3 years of Optional Practical Training. While most schools can afford you 12 months of OPT per degree level, at RIT New York, you can apply for a possible 24-month extension if you qualify.",
        },
        {
          title: "Study Abroad Programs",
          details:
            "Transfer to one of RIT partner campuses in Europe and Asia. Begin your program in New York and complete the final semester abroad or spend a summer traveling while learning.",
        },
        {
          title: "Flexible Class Timing",
          details:
            "Masters' students are allowed continuity of work while studying because RIT graduate programs are held in the evenings and on weekends.",
        },
      ],
      applyImage: ritApply,
      apply: [
        {
          index: number1,
          step: <span>Complete your online application <a href='https://ritnewyork.vigilearnapply.com/login' target="_blank" rel="noreferrer" style={{ textDecoration: 'underline', color: 'white', fontWeight: 'bold' }}>here</a></span>,
        },
        {
          index: number2,
          step: "Select the appropriate institution & fill the application form displayed on the screen ",
        },
        {
          index: number3,
          step: "Await further instruction via email to complete application & registration",
        },
        {
          index: number4,
          step: "Visa Processing",
        },
        {
          index: number5,
          step: "Get Onboarded on School System",
        },
        {
          index: number6,
          step: "Begin studying",
        },
      ],
      programme: [
        {
          title: "MBA Business Administration",
          aboutProgramme: [
            "Applications of technology and data analytics are the future of modern business. And, as organizations adapt, there is an increasing demand for business leaders to acquire skills in information systems and data analytics. RIT's MBA degree is designed to provide you with a strong focus on not only technology, but information systems, data analytics, and an exceptional foundation in the STEM fields."
          ],
          fee: {
            totalAmount: "52,092 USD",
            duration: "2 years"
          },
        },
        {
          title: "B.Sc Business Administration: Management",
          aboutProgramme: [
            "Our business management BS focuses on the critical competencies and interpersonal skills needed to be a successful change-agent in any organization. You are exposed to the many facets of management and managing, from the ability to motivate peers, to communicating with others, to leading a team–all of which are the essential skills of good managers and effective leaders. RIT’s management degree builds a T-shaped professional: those who have developed a deep disciplinary knowledge with a broad understanding of the professional and personal skills that define an effective leader."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "BFA Film and Animation",
          aboutProgramme: [
            "The film and animation major is for students who recognize the moving image as an expressive force uniquely important to modern life. A film degree focused on production, screenwriting, 2D animation, 3D animation, and stop motion animation. Explore the artistic, technical, historical, and business aspects of the motion picture industry."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Digital Humanities and Social Sciences",
          aboutProgramme: [
            "RIT’s digital humanities major pairs the liberal arts with digital technologies to prepare you for careers in dynamic areas that require advanced computing and digital skills."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "BFA Fine Art Photography Option",
          aboutProgramme: [
            "The fine art photography option prepares students for careers as visual artists, educators, editorial photographers, or freelance artists. Graduates are employed in a number of professional fine-art related institutions such as museums, archives, studios, and commercial galleries. The primary goal is to nurture the artist’s personal aesthetic vision through photographic expression."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Communication",
          aboutProgramme: [
            "Developing productive relationships, managing teams, analyzing audiences, creating effective messages, and understanding media are key competencies needed for successful communication professionals. RIT’s communication degree prepares you in the theory, research, and practical application needed to develop these skills. You will graduate ready for a successful career as a communication specialist. The degree’s strong focus on undergraduate research also prepares students for graduate work in communication and related academic disciplines."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Computing and Information Technologies",
          aboutProgramme: [
            "Information technology is found in every aspect of our lives: the workplace, our homes, the way we communicate, and in much of the entertainment we consume. IT professionals, therefore, are in great demand and highly valued. IT professionals are solution architects, identifying complex problems and creating custom solutions that help users meet their goals. They play an integral role in any modern organization, working on all phases of IT solutions from conception to development, testing, deployment, security, and management. In this information technology degree, you’ll learn to design, implement, and manage complex IT systems."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Chemical Engineering",
          aboutProgramme: [
            "A chemical engineering degree prepares you to advance nano-scale composites, pharmaceuticals, plastics, fibers, metals, and ceramics and to develop alternative energy systems, biomedical materials and therapies, and strategies that minimize the environmental impact of technological advancements."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Electrical Engineering",
          aboutProgramme: [
            "Electrical engineers synthesize science, mathematics, technology, and application-oriented designs into world-class consumer products, timely microprocessors, state-of-the-art computers, advanced electronic components, and much more. In RIT’s electrical engineering degree, you’ll synthesize science, mathematics, technology, and application-oriented designs into world-class consumer products, timely microprocessors, state-of-the-art computers, advanced electronic components, and much more."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Mechanical Engineering",
          aboutProgramme: [
            "Wherever there is motion or energy, mechanical engineers have played a role in the innovations that define modern life. RIT’s mechanical engineering degree provides students with a broad academic base complemented by hands-on laboratory activities and cooperative education experience. Students may also choose to concentrate their studies with professional electives focusing on aerospace engineering, automotive engineering, energy and the environment, bioengineering, or manufacturing and design."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Industrial Engineering",
          aboutProgramme: [
            "An industrial engineering degree designed to optimize, design, and manage the operational and manufacturing processes by which goods are made and distributed. Graduates of RIT industrial engineering degree are able to address big-picture design and engineering questions, such as how engineers can simultaneously increase efficiency and quality."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Civil Engineering Technology",
          aboutProgramme: [
            "In RIT’s civil engineering technology degree, you’ll gain the practical theory, knowledge, and skills necessary to analyze and solve complex challenges posed by our nation’s growing infrastructure needs."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Electrical Engineering Technology",
          aboutProgramme: [
            "Develop an in-depth understanding of electrical and electronics theory and its application, applied design, and implementation to electrical and electronic systems in RIT's electrical engineering technology degree."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Mechatronics Engineering Technology",
          aboutProgramme: [
            "With a degree in mechatronics engineering–the integration of electrical and mechanical systems that involve electronics, mechanical systems, computers, imaging and sensing, automation, and robotics–you'll drive the design and development of smart products."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Mechanical Engineering Technology",
          aboutProgramme: [
            "In the mechanical engineering technology degree, you'll understand how products and machinery work, and how to design, manufacture, and use technology to develop mechanical systems for high-performance automobiles, aerospace systems, bioengineered devices, energy technologies, and more."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "5 years"
          },
        },
        {
          title: "B.Sc Nutritional Sciences",
          aboutProgramme: [
            "Understanding nutrition, especially nutritional content, can have a remarkable impact on our health. Educating the public about the benefits of nutrition, and the various ways healthy living can improve our well-being, is the focus of the BS degree in nutritional sciences."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Diagnostic Medical Sonography (Ultrasound)",
          aboutProgramme: [
            "In RIT’s diagnostic medical sonography degree, you’ll learn to use ultrasound to diagnose, evaluate, and treat a range of medical diseases and conditions."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Applied Mathematics",
          aboutProgramme: [
            "An applied mathematics major focusing on problems that can be mathematically analyzed and solved, including models for perfecting global positioning systems, analyzing cost-effectiveness in manufacturing processes, or improving digital encryption software."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
        {
          title: "B.Sc Imaging Science",
          aboutProgramme: [
            "Imaging science is an extraordinary major that combines physics, math, computer science, and engineering to create fully functioning imaging systems, which are used in everything from scientific research and discovery, satellite imaging, filmmaking, search and rescue, national security, land surveying, AR/VR, and so much more."
          ],
          fee: {
            totalAmount: "52,030 USD",
            duration: "4 years"
          },
        },
      ],
      accreditations: [mscheLogo],
      alumni: {
        students: "19,000+",
        alumniWorldwide: "135,000+",
        countriesNtl: "100+",
        campuses: "5+",
        pulitzerPrizes: "15",
        individuals: [],
      },
      requirements: [
        {
          title: "UNDERGRADUATES",
          list: [
            "RIT Application",
            "Application Fee ($65)",
            "High School Grades",
            "SAT or ACT - Optional",
            "TOEFL or IELTS",
            "Teacher's Recommendation",
            "Portfolio (applicable to Art, Design or Film & Animation)"
          ],
        },
        {
          title: "GRADUATES",
          list: [
            "RIT Application",
            "Application Fee ($65)",
            "Undergraduate Transcripts + Degree Certificate(s) ",
            "Personal Statement",
            "GRE or GMAT Scores (varies by program)",
            "TOEFL or IELTS",
            "Portfolio (Required for Architecture, MFA, and Game Design programs)"
          ],
        },
      ],
    },
  }
];

export const courses = [
  {
    title: "BSc. Business Administration",
    content:
      "Our NUC Accredited Bachelor of Science in Business Administration will provide students with a breadth of knowledge and skills needed for managing business operations. These skills include forecasting demand, planning inventory, purchasing services, conducting skillful negotiations, building strategies for the future and managing people, finances and risk.",
    institution: 1,
    degree: "BSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuBusAdmin,
  },
  {
    title: "Bachelor of Nursing Science",
    content:
      "Earn an advanced nursing degree and take the next steps towards your future in healthcare. Our Nursing programme prepares you for the multi-faceted demands of the changing healthcare landscape through an innovative, online curriculum. OAU is committed to meeting the increase in demand in healthcare needs by offering an online option for earning your Bachelor of Nursing Science (BNSc.).",
    degree: "BNSc.",
    category: "undergraduate",
    institution: 2,
    link: "http://apply.oaucdl.edu.ng/",
    availabilityStatus: false,
    // curriculum: oauNursing,
  },
  {
    title: "BSc. Accounting",
    content:
      "Kickstart your journey to a fulfilling career in finance by building a strong foundation in accounting. Our NUC accredited BSc. In Accounting programme will expose you to multiple concepts & practices that are guaranteed to set you apart from the field.",
    institution: 3,
    degree: "BSc.",
    category: "undergraduate",
    link: "http://applycodel.babcock.edu.ng/",
    availabilityStatus: true,
    // curriculum: babcockAccounting,
  },
  {
    title: "BSc. Economics",
    content:
      "As an Economics student, you’ll explore everything from pricing strategy and cost-benefit analysis to the impact of environmental policy and international exchange rates. in addition, you’ll learn to think analytically and quantitatively about a variety of economic and financial issues. This NUC Accredited programme will deliver the knowledge and skills you need to advance your career whenever and wherever it’s convenient for you.",
    institution: 1,
    degree: "BSc",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuEconomics,
  },
  // {
  //   title: "Data Analytics",
  //   content:
  //     "This course, you will learn the key aspects to data analysis. You will begin to explore the fundamentals of gathering data, and learning how to identify your data sources. You will then learn how to clean, analyze, and share your data to help make informed decisions on operations, risk management, finance, marketing, etc.",
  //   institution: 5,
  //   degree: "Professional",
  //   category: "professional",
  //   link: "https://ixperience.vigilearnapply.com/register/individual",
  //   availabilityStatus: true,
  //   fee: {
  //     amount: "$195",
  //     oldAmount: "$595",
  //   },
  // },
  // {
  //   title: "Soft Skills",
  //   content:
  //     "Get familiar with effective interpersonal communication skills (written, verbal and non-verbal) and their impact on management skills and business relationships in day-to-day interactions in the global workplace.  Topics will include personal communication styles, cultural diversity, perception, barriers to effective listening and many more.",

  //   degree: "Professional",
  //   category: "professional",
  //   institution: 4,
  //   link: "https://mcm.vigilearnlms.com/course/soft-skills-5",
  //   availabilityStatus: false,
  //   duration: "5 weeks",
  //   oldPrice: "$1,055",
  //   newPrice: "$983",
  //   module: "5",
  //   start: "July 2021",
  //   overview:
  //     "This course will familiarize participants with effective interpersonal communication skills (written, verbal and non-verbal) and the impact of these skills on management and business relationships in day to day interactions in the global workplace.  Topics will include personal communication styles, cultural diversity, perception, verbal and non-verbal communication, barriers to effective listening, dynamics of interpersonal communication and conflict resolution for effective management. ",
  //   outline: [
  //     [
  //       {
  //         title: "Introduction to Interpersonal Communication in Management",
  //         content: [
  //           "Defining Interpersonal Communication",

  //           "importance, Process of Interpersonal Communication",
  //         ],
  //       },
  //       {
  //         title: "Interpersonal Communication and Diversity in Management",
  //         content: [
  //           "Impact of diversity on communication – written, verbal and nonverbal",

  //           "Cultural differences in communication - written, verbal and nonverbal",

  //           "Improving intercultural communication – High and Low Context Cultures",
  //         ],
  //       },
  //       {
  //         title: "Interpersonal Communication and Self ",
  //         content: [
  //           "Self-concept and Impact on Communication – written and verbal",
  //         ],
  //       },
  //       {
  //         title: "Power and impact of Language  ",
  //         content: [
  //           "Role of Culture and Gender",

  //           "Power of Words – Effective Writing",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Perception for better Management Skills ",
  //         content: [
  //           "Understanding Perception for Better Management and Writing Skills",

  //           "Understanding Behavior of Others",

  //           "Improving Perception Skills  - written, verbal and non-verbal",

  //           "Influences on Perception",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Nonverbal Communication for Effective Management ",
  //         content: [
  //           "Nature of Nonverbal Communication Channels",

  //           "Gender and Culture",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Importance of Listening for effective Management ",
  //         content: [
  //           "Nature and Styles of effective Listening",

  //           "Barriers to Effective Listening",

  //           "Becoming Better Listeners",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title:
  //           "Understanding Dynamics of Interpersonal Relationships for effective Management Skills ",
  //         content: [
  //           "Nature and Types of Interpersonal Relationships at Work",

  //           "Managing Interpersonal Conflict",

  //           "Deceptive Communication",

  //           "Constructive Conflict ",

  //           "Conflict Management Styles",

  //           "Conflict Resolution",
  //         ],
  //       },
  //     ],
  //   ],
  //   tutor: {
  //     tutorName: "Dr. Poonam Singh",
  //     email: "poonam.singh@mcm.ac.ae",
  //     experience: "20+ years",
  //     about: [
  //       "Dr. Poonam Singh holds a Doctoral Degree in Anthropology from Syracuse University, Syracuse, NY, USA.  Formerly, she served in several senior leadership positions in higher education for nearly two decades.",
  //       "While she served as a Provost / VPAA, and earlier as the Dean, she also pursued her passion to teach behavioral and social sciences at undergraduate level.  As a part of her additional services she also planned, led, and presented several professional development workshops for faculty and staff",
  //       "Previously Dr. Poonam worked as a Community Development Specialist for United Nations Centre for Human Settlements (Habitat, Nairobi, Kenya) project in Nepal and subsequently as a Rural Sociologist in Myanmar. She also worked as a teaching assistant at Syracuse University and was awarded a Junior Fellowship by AIIS for research in India.",
  //     ],
  //   },
  // },
  {
    title: "BSc. Accounting",
    content:
      "The NUC Accredited online Bachelor of Science in Accounting provides excellent training for a successful career in Audit firms, in the fields of accounting, auditing and taxation as well as in banks, semi-government, public and other private organizations.",
    institution: 1,
    degree: "BSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuAccounting,
  },

  {
    title: "Bachelor of Nursing Science",
    content:
      "Earn an advanced nursing degree and take the next steps towards your future in healthcare. Our NUC Accredited Nursing programme prepares you for the multi-faceted demands of the changing healthcare landscape through an innovative, online curriculum. ABU DLC is committed to meeting the increase in demand in healthcare needs by offering an online option for earning your Bachelor of Nursing Science (BNSc.).",
    institution: 1,
    degree: "BNSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuNursing,
  },
  {
    title: "Post Graduate Diploma in Education",
    content:
      "Our Postgraduate Diploma in Education teaches an approach that will stay with its students throughout their careers. You’ll learn everything the qualified teacher needs to know about planning, teaching, learning, assessing, policies and processes. This programme will deliver the knowledge and skills you need to advance your career whenever and wherever it’s convenient for you",
    degree: "PGD",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuPGDE,
  },
  {
    title: "Masters Business Administration",
    content:
      "The ABU Distance Learning Center MBA is designed to provide the ability to obtain a master’s degree from an NUC Accredited business school no matter where you live. Focused and flexible, the highly ranked programme delivers the knowledge and skills you need to advance your career whenever and wherever it’s convenient for you.",
    degree: "MBA",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuMBA,
  },
  // {
  //   title: "Sales and Marketing Academy",
  //   content:
  //     "This course synchronizes sales, marketing and relevant digital practices to give business professionals of all levels a complete, in-depth and multi-dimensional insight into these disciplines. It gives a great opportunity to catch up with core practices and learn how these disciplines work together to create synergy and give your organization a clear competitive advantage in today’s marketplace.",
  //   degree: "Professional",
  //   category: "professional",
  //   institution: 4,
  //   link: "https://mcm.vigilearnlms.com/course/sales-and-marketing-academy-4",
  //   availabilityStatus: false,
  //   duration: "5 weeks",
  //   oldPrice: "$1,013",
  //   newPrice: "$994",
  //   module: "5",
  //   start: "July 2021",
  //   overview:
  //     "This course designed for advanced marketing and sales fosters true cross functional competencies in the most successful organizations. It successfully synchronizes sales, marketing and relevant digital practices to give business professionals of all levels and backgrounds a complete, in-depth and multi-dimensional insight into these disciplines. This course is a great opportunity to catch up with core practices and learn how these disciplines work together to create synergy and give your organization a clear competitive advantage in today’s marketplace. You will get it all in one place and at one time. Take the challenge! ",
  //   outline: [
  //     [
  //       {
  //         title: "Introduction to marketing & sales",
  //         content: [
  //           "Scope of marketing and selling",

  //           "Definition and functions of marketing ",

  //           "Definition and functions of selling",

  //           "Differences between marketing and selling",

  //           "Selling self-assessment readiness",

  //           "Marketing self-assessment readiness",

  //           "SMarketing - The new trend",

  //           "Running effective meetings between marketing and sales",

  //           "Sales and marketing communication tips",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Marketing mix foundations",
  //         content: [
  //           "The marketing mix: setting the scene ",

  //           "Understanding the marketing environment",

  //           {
  //             title: "Various marketing analysis techniques",
  //             content: [
  //               "Competition analysis ",

  //               "Michael Porter analysis ",

  //               "PEDSTLE analysis ",
  //             ],
  //           },

  //           {
  //             title: "A suggested marketing plan framework:",
  //             content: ["SWOT analysis", "TOWS analysis "],
  //           },

  //           "Criteria for prioritizing action plans",

  //           "Conducting a full marketing audit ",

  //           "Writing the strategic marketing plan ",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Market segmentation",
  //         content: [
  //           "Bases of Market Segmentation ",

  //           "Patterns of market segmentation",

  //           "Markrting Mix",

  //           "Product life cycle ",

  //           "BCG Matrix - Boston Consulting Box",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Pricing frameworks",
  //         content: [
  //           " Pricing Considerations and Strategies ",

  //           "Factors affecting price decision",

  //           "Pricing in Different Types of Market ",

  //           "General pricing Approaches ",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Promotion fundamentals",
  //         content: [
  //           "Marketing communication",

  //           "Advertising, Sales promotion, and Public Relation ",

  //           "Characteristics of the media",

  //           "Elements in the Communication Process",

  //           "Methods of sales promotion",

  //           "Ecommerce",
  //         ],
  //       },
  //     ],
  //   ],
  //   tutor: {
  //     tutorName: "Dr. Ruchi Agarwal",
  //     email: "ruchi.agarwal@mcm.ac.ae",
  //     experience: "15+ years",
  //     about: [
  //       "Dr. Ruchi has more than fifteen years of teaching experience at Higher Education level in India, Muscat (Oman) and the UAE, teaching several courses in the disciplines of management and marketing.",
  //       "During her tenure, she has been associated with the Scottish Qualifications Authority and the University of Stirling in Scotland and has received the title of 'Recognized Teacher' from universities in the UK.",
  //       "Dr. Ruchi has published several research articles in international research journals, presented papers at international conferences, including the World Education Congress. She is the co-author of a book on Customer Equity.",
  //     ],
  //   },
  // },

  {
    title: "BSc. Accounting",
    content:
      "Strong organizations know that their long-term health depends on a well-managed balance sheet. Our BSc. in Accounting programme is designed to provide rich educational experience and equip students with the skills and knowledge needed to lead, from a financial perspective. Career opportunities in this field are generally substantive and in high demand.",
    degree: "BSc.",
    category: "undergraduate",
    institution: 2,
    link: "http://apply.oaucdl.edu.ng/",
    availabilityStatus: false,
    // curriculum: oauAccounting,
  },
  {
    title: "BSc. International Studies",
    content:
      "The NUC Accredited online Bachelor of Science in International Studies will provide students with the critical knowledge and skills for succeeding in a world that has gone global. Understanding how global institutions and exchanges work, and bringing an international perspective to one’s professional development is a necessity for modern life.",
    institution: 1,
    degree: "BSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuIntStudies,
  },
  {
    title: "BSc. Computer Science",
    content:
      "The NUC Accredited online Bachelor of Science in Computer Science provides students with in-depth knowledge and analytic skills associated with the field of IT and Computer Science. The students will receive a comprehensive understanding of the design, development, testing, and documentation of a range of operating systems.",
    institution: 1,
    degree: "BSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abu
  },
  // {
  //   title: "Security, Health and Safety",
  //   content:
  //     "This course will familiarize participants with effective ways to ensure occupational environment, health and safety in their organizations. Topics will include Personal Protective Equipment (PPE), New Technology and Potential for Catastrophic Events, Fundamentals of Fire Prevention, Introduction to Hazardous Chemical Identification, Community Awareness and Emergency Response, First Aid and many more.",
  //   degree: "Professional",
  //   category: "professional",
  //   institution: 4,
  //   link: "https://mcm.vigilearnlms.com/course/security-health-and-safety-3",
  //   availabilityStatus: false,
  //   duration: "5 weeks",
  //   oldPrice: "$1,273",
  //   newPrice: "$1,185",
  //   module: "5",
  //   start: "July 2021",
  //   overview:
  //     "Companies strive to protect their investments in people, equipment, and potential profits from fires, explosion, and expensive litigation. Hence, the need for good training. This module will familiarize participants with effective ways to ensure occupational environment, health and safety in their organizations. Topics will include Personal Protective Equipment (PPE), New Technology and Potential for Catastrophic Events, Fundamentals of Fire Prevention, Protection, and Control,Environmental Effects, Health Hazards Associated with Gases, Vapors, Particulates, and Toxic Metals, Handling, Storing, and Transporting Hazardous Chemicals Safely, Introduction to Hazardous Chemical Identification, Community Awareness and Emergency Response, First Aid.",
  //   outline: [
  //     [
  //       {
  //         title: "Introduction to health and safety",
  //         content: [
  //           "Personal Protective Equipment (PPE)",

  //           "Attitudes and Behaviors",

  //           "New Technology and Potential for Catastrophic Events",

  //           "General Plant Safety Rules",

  //           "Job Hazard Analysis ",

  //           "Types of Fire Extinguishers",

  //           "Weapons of Mass Destruction",
  //         ],
  //       },

  //       {
  //         title: "Hazard Classification",
  //         content: [
  //           "Common Industrial Hazards ",

  //           "Physical, Chemical, Ergonomic, and Biological Hazards ",

  //           "Electrical Hazards ",

  //           "Industrial Noise Hazards ",

  //           "Radiation Hazards ",

  //           "Hazard Recognition ",

  //           "Accident Prevention ",

  //           "Accident Investigation ",
  //         ],
  //       },
  //     ],

  //     [
  //       {
  //         title: "Routes of entry and environmental effects",
  //         content: [
  //           "Routes of Entry",

  //           "Dose-Response Relationship",

  //           "Environmental Effects ",

  //           "Air Pollution ",

  //           "Air Pollution Control ",

  //           "Water Pollution Control",

  //           "Hazards: Scenarios ",
  //         ],
  //       },
  //       {
  //         title: "Gases, vapors, particulates and metals",
  //         content: [
  //           "Physical Hazards Associated with Gases, Vapors, Particulates, and Toxic Metals",

  //           "Health Hazards Associated with Gases, Vapors, Particulates, and Toxic Metals ",

  //           "Asbestos ",

  //           "Particulates ",

  //           "Dust and Gases ",

  //           "Dust Explosions ",

  //           "Flammable Gases ",

  //           "Compressed Gas Cylinder ",

  //           "Metallic Substances ",

  //           "Metallic Compounds ",

  //           "Metals That are Fire Hazards ",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Hazards of Liquids",
  //         content: [
  //           "Introduction to the Hazards of Liquids",

  //           "Handling, Storing, and Transporting Hazardous Chemicals Safely",

  //           "Physical Hazards Associated with Liquids",

  //           "Health Hazards Associated with Liquids",

  //           "Pressure and Pressurized Equipment ",

  //           "Process Systems ",

  //           "Flammable Liquid Storage ",

  //           "Spontaneous Combustion ",

  //           "Oxidizers ",

  //           "Hazards of Steam ",

  //           "Hazards of Water ",

  //           "Hazards of Light-Ends ",

  //           "Water Hazards ",

  //           "Acids and Caustics ",

  //           "Solvents ",

  //           "Paints and Adhesives ",

  //           "Hematopoietic System Toxins, Hepatotoxic Agents and Other Harmful",

  //           "Agents ",
  //         ],
  //       },
  //       {
  //         title:
  //           "Hazardous chemical identification: Hazacom, toxicology and dot",
  //         content: [
  //           "Introduction to Hazardous Chemical Identification ",

  //           "The Hazard Communication Program—The Workers’ Right-to-Know Act ",

  //           "Material Safety Data Sheet (MSDS) ",

  //           "Toxicology ",

  //           "Safety Signs, Tags, and Warning Labels ",

  //           "Hazardous Materials Identification System (HMIS) ",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Fire and explosion",
  //         content: [
  //           " Fire, Explosion, and Detonation ",

  //           "Chemical Explosions ",

  //           "Polymers and Fire ",

  //           "Flammable, Explosive, and Radioactive Hazards ",

  //           "Flammable and Explosive Materials ",

  //           "Fundamentals of Fire Prevention, Protection, and Control ",

  //           "The Chemistry of Fire ",

  //           "The Hazard of Air ",

  //           "Fire Stages ",

  //           "Flashpoint, Flammable Limits, and Ignition Temperature ",

  //           "Fire Classification System ",

  //           "Types of Fire Extinguishers ",

  //           "Fire Extinguisher Use ",

  //           "Fighting Fires ",
  //         ],
  //       },
  //       {
  //         title:
  //           "Electrical, noise, heat, radiation, ergonomic and biological hazards",
  //         content: [
  //           "Plant-Specific Hazards ",

  //           "Electricity ",

  //           "Bonding and Grounding ",

  //           "Heat and Radiation ",

  //           "Hearing Conservation and Industrial Noise ",

  //           "Ergonomic Hazards ",

  //           "Hazards of Confined Spaces ",

  //           "Hazards of Lifting ",

  //           "Biological Hazards ",

  //           "Blood-Borne Pathogens ",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Persona, protective equipment",
  //         content: [
  //           "Personal Protective Equipment ",

  //           "Hazards in the Workplace ",

  //           "Emergency Response—Four Levels of PPE ",
  //         ],
  //       },
  //       {
  //         title: "Administrative Controls",
  //         content: [
  //           "Introduction to Administrative Controls ",

  //           "Community Awareness and Emergency Response ",

  //           "Job Safety Analysis ",

  //           "Hazards and Operability Study",

  //           "Training and Mandated Training ",

  //           "Housekeeping ",

  //           "Safety Inspections and Audits ",

  //           "Monitoring Equipment ",

  //           "First Aid ",
  //         ],
  //       },
  //     ],
  //   ],
  //   tutor: {
  //     tutorName: "Dr. Erhauyi Meshach",
  //     email: "meshach@mcm.ac.ae",
  //     experience: "20+ years",
  //     about: [
  //       "Dr. Erhauyi Meshach Aiwerioghene is an Assistant Professor of Healthcare Management. He has an academic degrees in Human Physiology and Hospital Administration.  Dr. Meshach has a vast experience in teaching and research at universities in India and UAE in the fields of Healthcare Management, Health Information Management, Physiology and Anatomy. Currently he is the program coordinator for healthcare management at MCM.",
  //       "Dr. Meshach has authored several peer-reviewed academic papers published in well-regarded journals. In recent times, Dr. Meshach has reviewed a couple of manuscripts on behalf of leading journals such as the International Journal of Healthcare Management (Taylor and Francis group).",
  //       "In addition to all that, Dr. Meshach attended and presented at several international and national conferences in Asia and Middle East. He is also a faculty member of American College of Healthcare Executives (ACHE).",
  //     ],
  //   },
  // },

  {
    title: "BSc. Political Science",

    content:
      "The NUC Accredited online Bachelor of Science in Political Science provides students with valuable, comprehensive knowledge of government and global politics, preparing them to analyze complex political problems and recognize potential solutions in both the public and private sector.",
    institution: 1,
    degree: "BSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuPoliticalScience,
  },

  {
    title: "BSc. Sociology",

    content:
      "The NUC Accredited online Bachelor of Science in Sociology explores specific examples of social action, social processes and institutions; compare different types of social life and societies; examines theories about the nature of social existence and change; studies different methods of social research.",
    institution: 1,
    degree: "BSc.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuSociology,
  },
  {
    title: "Master in International Affairs and Diplomacy",

    content:
      "The ABU Distance Learning Center MIAD is designed to suit the needs of students and professionals interested in both developing essential knowledge for understanding international affairs, as well as strengthening their capacities in the practice of diplomacy. It is aimed at diplomats as well as staff of governmental and non-governmental organizations.",
    degree: "MIAD",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuMIAD,
  },
  {
    title: "Master in Law Enforcement and Criminal Justice",
    content:
      "The ABU Distance Learning Center MLCJ designed to provide relevant and appropriate sociological and criminological training for the operators of law enforcement and administrators of justice. It is to aid and encourage every law/security personnel to see his/her particular specialized role as only a part of the larger machinery responsible for the administration of Criminal Justice.",
    degree: "MLCJ",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuMLCJ,
  },
  // {
  //   title: "Middle/Senior management Training",
  //   content:
  //     "This course is essential for those who manage or are responsible for a team.  Here, you will learn and apply the core team competencies needed to engage your team members, align people to achieve outstanding results, and raise them to the next level.",
  //   degree: "Professional",
  //   category: "professional",
  //   institution: 4,
  //   link: "https://mcm.vigilearnlms.com/course/middlesenior-management-trainiing-2",
  //   availabilityStatus: false,
  //   duration: "5 weeks",
  //   oldPrice: "$1,181",
  //   newPrice: "$1,100",
  //   module: "5",
  //   start: "July 2021",
  //   overview:
  //     "This training course is an essential development resource for those who manage or are responsible for a team.  A team that is unified, strong, and working in harmony is able to achieve greater effectiveness and efficiency.  However, it takes strong core team competencies, leadership, and management to achieve this.  In this training course, you will learn and apply the core team competencies needed to engage your team members, align people to achieve outstanding results, and raise them to the next level.  This will be accomplished by effectively building trust, igniting innovation, and increasing empowerment to achieve increased performance. ",
  //   outline: [
  //     [
  //       {
  //         title: "Understanding team dynamics and structure",
  //         content: [
  //           "Team Leadership ",

  //           "The sociology of the team ",

  //           "Non-traditional team structures ",

  //           "Working the Team Performance Curve",

  //           "Team Profile Assessment ",

  //           "Identifying optimum team roles",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Achieving engagement and alignment",
  //         content: [
  //           "Team communication structures ",

  //           "Effective interpersonal communication",

  //           "Active Listening",

  //           "Influencing for results ",

  //           "Creating and communicating the vision ",

  //           "Managing change within the team",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Increasing performance and collaboration",
  //         content: [
  //           "Team performance management ",

  //           "Objective setting for increased performance ",

  //           "Motivation and reward within teams",

  //           "Active conflict management in teams ",

  //           "Negotiation strategies to achieve team agreement",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Igniting team innovation",
  //         content: [
  //           "Igniting Team Innovation ",

  //           "Team factors: ACL model ",

  //           "Problem-solving: defining the problem ",

  //           "Team problem-solving techniques ",

  //           "Innovation and creativity ",

  //           "Decision making and reaching a consensus",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title:
  //           "Empowerment and team development for effective management skills",
  //         content: [
  //           "Empowering for success ",

  //           "Effective Team delegation",

  //           "Development planning ",

  //           "Team Mentoring and Coaching ",

  //           "Feedback for greater improvement ",
  //         ],
  //       },
  //     ],
  //   ],
  //   tutor: {
  //     tutorName: "Dr. Fathy Mahgoob ",
  //     email: "fathy.mahgoob@mcm.ac.ae",
  //     experience: "20+ years",
  //     about: [
  //       "Dr. Fathy Mahgoob, Chair Board of Hospitality Management Program at MENA COLLEGE of MANAGEMENT DUBAI, UAE. Dr. Fathy is a PhD holder in the field of E-Marketing and Service Marketing, he has extensive experience in various sectors: more than 17 years of teaching in universities; more than 20 years in the field of tourism and hospitality holding leadership positions in the marketing division application to the academic and scientific field; accredited from AHLA Certified Hospitality Managers CHM; more than 14 years of training and development in most of the Gulf countries for many international and local companies.",
  //       "He published many scientific research in scientific journals and several specialized books and attended and participated in international conferences. He currently holds the position of Assistant Professor in the College of Business Administration Department of Tourism and Hospitality Hotel management.",
  //       "Dr, Fathy has a wide and diverse training experience at an international level (United Arab Emirates - Saudi Arabia - Sultanate of Oman - Qatar - Bahrain - Libya - Yemen - Turkey - Malaysia). More than 10,000 trainees were trained by him over a course of 14 years: from governmental and private sectors to multinational companies. He held the position of Director of Training and Development at the British International Institute, Cairo - Egypt, were 90% of the students came from Africa.",
  //       "Designed and developed many plans and specialized training programs for different sectors such as (Petroleum (Shell - Total), Banks (Commercial International Bank - Yemen Commercial Bank - Arab African Bank), Hotel sector, Security and Police, Real-estate, construction and Education sector and academia).",
  //     ],
  //   },
  // },

  {
    title: "Master In Information Management",
    content:
      "The ABU Distance Learning Center Master in Information Management aims to convey the knowledge required in order to manage computerized information systems in businesses and inter-company networks, both in terms of business administration and in terms of information technology. Students will learn how to develop information technology solutions from the business administration perspective.",
    degree: "MSc.",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuMIM,
  },
  {
    title: "Master In Public Administration",
    content:
      "The ABU Distance Learning Center MPA will provide students with a comprehensive understanding of the functioning of administrations. The program will give students the skills necessary to network in the public sector, learn about performance evaluation and analyze the relationship between civil servants and politicians.",
    degree: "MPA",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuMPA,
  },
  {
    title: "Master in Public Health",
    content:
      "The ABU Distance Learning Center MPH aims to equip students with the knowledge and skills to enable them to address public health challenges through research and practice. It covers the whole breadth of public health, encompassing low-, middle- and high-income countries.",
    degree: "MPH",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuMPH,
  },
  {
    title: "Post Graduate Diploma in Management",
    content:
      "Our Postgraduate Diploma in Management is designed to provide individuals from all fields and professions the opportunity to acquire management skills and knowledge for career advancement. The programme is suitable for graduates who have obtained university degrees and/or certificates from both management and non-management fields.",
    degree: "PGDM",
    category: "postgraduate",
    institution: 1,
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuPGDM,
  },
  // {
  //   title: "Leadership In Dynamic Workplaces",
  //   content:
  //     "This course is designed for current business leaders who are keen on improving their leadership skills by using a mix of interactive approaches from discussions concerning different business cases  around the role of leadership in the workplace, when it comes to motivation, empowerment, culture, conflict management and a lot more. ",
  //   degree: "Professional",
  //   category: "professional",
  //   institution: 4,
  //   link: "https://mcm.vigilearnlms.com/course/leadership-in-dynamic-workplaces-1",
  //   availabilityStatus: false,
  //   duration: "5",
  //   oldPrice: "$1,341",
  //   newPrice: "$1,248",
  //   module: "5",
  //   start: "July 2021",
  //   overview:
  //     "This course is designed for current business leaders who are keen on improving their leadership skills through a mix of interactive approaches from discussions concerning different business cases around the role of leadership in the workplace, when it comes to motivation, empowerment, culture, conflict management and a lot more. As well as group participations to evaluate personal leaderships and competencies in dynamic workplaces and to achieve the following competencies:",
  //   overviewPoints: [
  //     "Leadership skills in Dynamic workplaces",

  //     "Designing an engaged working environment",

  //     "Organizational Motivation ",

  //     "Leadership concepts in the workplaces ",

  //     "Organizational communication",

  //     "Cross-Cultural Leadership / Equality and diversity ",

  //     "Risk handle",

  //     "Building engaged organizational cultures ",
  //   ],
  //   outline: [
  //     [
  //       {
  //         title: "Leadership and work behavior",
  //         content: [
  //           "Defining leadership and management ",

  //           "12 Unique Insights On Leadership According To Bob Danzig",

  //           "What is a dynamic workplace ",

  //           "How leaders can create dynamic workplace ",

  //           "Recognize the downward spiral ",

  //           "Use The High Performance Development Model ( HPDM )",

  //           "Leader and Management function ",

  //           "Triple forces for leader ",

  //           "Leadership levels – managerial skills ",

  //           "Maslow’s Hierarchy of Needs",

  //           "Leadership skills in the workplace (Planning – Organizing -Pickle jar theory – Controlling ) ",

  //           "The Leadership Challenge",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Leadership concepts in the workplace",
  //         content: [
  //           "The Changing Meaning of Leadership ",

  //           "Seven Traits Associated with Leadership",

  //           "Organization & Effective Leader ",

  //           "Leadership and business environment",

  //           {
  //             header: "Behavioural Theories",
  //             content: [
  //               "University of Iowa Studies",

  //               "The Ohio State Studies",

  //               "University of Michigan Studies",
  //             ],
  //           },

  //           "Cross-Cultural leadership",
  //           "Equality and diversity",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Leadership and dynamics workplace (Organization functions)",
  //         content: [
  //           "Hersey & Blanchard’s Situational Leadership",

  //           "Understanding organizational behavior",

  //           {
  //             header: "Organizational functions",
  //             content: ["Production", "Marketing", "HRM", "Finance"],
  //           },
  //           "Effective Leader and business life cycle ",

  //           "Leadership and authority delegation / Centralization",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Leadership and organizational communication",
  //         content: [
  //           "Defining organizational communication ",

  //           "The strategy behind organizational communication",

  //           "Internal strategy",

  //           "External strategy ",

  //           "Barriers to organizational communication",

  //           "Overcoming those barriers ",

  //           "Communication success measures ",
  //         ],
  //       },
  //     ],
  //     [
  //       {
  //         title: "Case Study",
  //       },
  //     ],
  //   ],
  //   tutor: {
  //     tutorName: "Dr. Mohamed Ali",
  //     email: "mohamed.ali@mcm.ac.ae",
  //     experience: "20+ years",
  //     about: [
  //       "Dr. Mohamed Ali obtained his BSc from the College of Management Information Systems (MIS), in Cairo 1998 with honors. His Master’s is in (Business administration) from Sadat academy for administrative sciences 2008, and his PhD is from Management and Sciences university (MSU) in Malaysia 2015. He also holds a higher diploma in Business Administration.",
  //       "The experience of Dr. Fathy Is very diverse, he has more than 22 years of academic experience in more than 5 universities in and out of (UAE), his teaching experience ranges from market research to customer service, to HRM, to Project management. He also has experience in training and consulting, Co-restructuring, market segmentation.",
  //       "Dr. Fathy believes that the investment today should be in education, as it is one of the most important factors of development in any country. His objective as a professor is to develop and encourage creativity and higher order thinking in a way that increases students’ performance, as well as understand the significance of individual differences and the motivational methods appropriate for every single student.",
  //     ],
  //   },
  // },
  {
    title: "BSc. Economics",
    content:
      "This programme was designed to disseminate and expand knowledge in the principles and applications of Economics and thereby contribute rationally to the development of Economics and manpower requirements for national development.",
    degree: "BSc.",
    category: "undergraduate",
    institution: 2,
    link: "http://apply.oaucdl.edu.ng/",
    availabilityStatus: false,
  },
  {
    title: "BSc. Public Administration",
    content:
      "The NUC Accredited Online Bachelor of Science in Public Administration prepares students for careers that transform government, nonprofit organizations, and policy at all levels. You will be empowered to create change in the public service sector through careers in all levels of government and nonprofit organizations",
    institution: 1,
    degree: "BSC.",
    category: "undergraduate",
    link: "https://apply.abudlc.edu.ng/",
    availabilityStatus: true,
    // curriculum: abuPublicAdmin,
  },
];
