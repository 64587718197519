import React, { useState } from "react";
import PopUpVideo from "../../components/popUpVideo";
import playImage from "../../assets/images/play-image.png";
import "../../assets/styles/pageStyles/products.scss";

import { displayData } from "../../helpers";

const LearningManagementSystem = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);

  const data = [
    {
      title: "User Management",
      content:
        "Our LMS provides simple interfaces for all user instances. Whether you're an Admin, Student, or a Tutor, you'll find our interfaces user-friendly and easy to navigate. For any type of learning institution, managing users is as simple as a click or two.",
    },

    {
      title: "Social Networking",
      content:
        "Forums are great in promoting self-awareness and encouraging social interaction between students or with tutors.",
    },

    {
      title: "Big Data Analytics",
      content:
        "Using Big data as a core tooling resource for revealing insights from diverse, complex datasets can revolutionize interactions and content delivery across all electronic platforms.",
    },

    {
      title: "Virtual Classroom",
      content:
        "Classrooms don’t need walls and physical boundaries, they can be twice as efficient with these limitations absent. Now imagine a classroom without walls, but the best virtual classroom experience.",
    },
  ];

  const showData = (start, number) => displayData(data, start, number);

  return (
    <div className="products-container product-wrapper">
      <div className="bg-blue-primary">
      <div className="product-banner container text-center ">
        <div className="product-banner-text banner-text-light">
          <h1 data-aos="fade-down">
            Learning Management System
          </h1>
          <p id="lms-subtext" data-aos="fade-up">
          Deliver content, track performance, facilitate collaboration and engage real-time, all with VigiLearn LMS. 
          </p>
        </div>
        <div className="product-image-box d-flex align-items-center justify-content-center">
          <div
            className="product-play-container"
            onClick={() => setPopUpOpen(!popUpOpen)}
          >
            <img src={playImage} alt="" width="35px" height="40px" />
          </div>
        </div>
      </div>
      </div>
   
      <div className="product-key-features product-key-features--light-bg d-flex justify-content-center">
        <div className="features-content--light">
          <h2 className="text-center" style={{color: "#3A434B"}}>Key Features</h2>
            <div className="feature-cards row">
              <div className="col-md-6">{showData(0, 2)}</div>
              <div className="col-md-6">{showData(2, 4)}</div>
          </div>
        </div>
      </div>
      <PopUpVideo
        open={popUpOpen}
        channel="vimeo"
        videoId="549197830"
        onClose={() => setPopUpOpen(false)}
      />
    </div>
  );
};

export default LearningManagementSystem;
