import React, { useState } from "react";
import PopUpVideo from "../../components/popUpVideo";
import playImage from "../../assets/images/play-image.png";
import "../../assets/styles/pageStyles/products.scss";

import { displayData } from "../../helpers";

const ExaminationPortal = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);

  const data = [
    {
      title: "Question Bank Mangement",
      content:
        "The exam software allows its users to manage and create different question paper templates and set-up question banks that are reusable anytime. The inclusion of text editors and images could make the question paper presentation more appealing.",
    },
    {
      title: "Import and Export Facilitites",
      content:
        "You can import & export users, exams, responses, reports with ease.",
    },
    {
      title: "Random Ordering of Questions",
      content:
        "You can automatically randomize the order of the pages, questions and choices for each respondent that accesses the test.",
    },
    {
      title: "Single Sign-On",
      content:
        "The examination portal can be integrated to any LMS and it allows students access the portal with the same credential.",
    },
    {
      title: "White Label and Branding",
      content:
        "The portal allows white label, customize to suite your organization and business model.",
    },

    {
      title: "Organization and User Management",
      content:
        "You can create & assign users with login access. You can group users within the same organization or class for ease of examining.",
    },
    {
      title: "Configuration of question type",
      content:
        "This option allows easy creation of several question types including multiple-choice, quizzes, and mathematical formulas. It also allows the upload of images alongside with questions. ",
    },

    {
      title: "Exam Instructions",
      content:
        "An administrator can set exam instructions prior to the exam date, instruction can also be edited. The instruction will be displayed before the examination starts.",
    },

    {
      title: "Automatic Reporting",
      content:
        "At the end of every exam session, the result is displayed for the student to see and also updated on the administrative end.",
    },
  ];

  const showData = (start, number) => displayData(data, start, number);

  return (
    <div className="products-container product-wrapper">
      <div className="product-banner container text-center">
        <div className="product-banner-text">
          <h1 data-aos="fade-down">
            <span className="green-text">Examination</span>{" "}
            <span className="orange-text">Portal</span>
          </h1>
          <p data-aos="fade-up">
            Simplify your examination delivery and assessment process with a
            simple solution to a complex problem. The exam portal allows you to
            administer examinations of different types and collates all data
            with ease.
          </p>
        </div>
        <div className="product-image-box d-flex align-items-center justify-content-center">
          <div
            className="product-play-container"
            onClick={() => setPopUpOpen(!popUpOpen)}
          >
            <img src={playImage} alt="" width="35px" height="40px" />
          </div>
        </div>
      </div>
      <div className="product-key-features bg-blue-primary d-flex justify-content-center">
        <div className="features-content ">
          <h2 className="text-center">Key Features</h2>
          <div className="d-none d-lg-block">
            <div className="feature-cards row">
              <div className="col-4">{showData(0, 3)}</div>
              <div className="col-4">{showData(3, 6)}</div>
              <div className="col-4">{showData(6, 9)}</div>
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div className="feature-cards row">
              <div className="col-md-6">{showData(0, 4)}</div>
              <div className="col-md-6">{showData(4, 8)}</div>
            </div>
          </div>
        </div>
      </div>
      <PopUpVideo
        open={popUpOpen}
        channel="vimeo"
        videoId="549195132"
        onClose={() => setPopUpOpen(false)}
      />
    </div>
  );
};

export default ExaminationPortal;
