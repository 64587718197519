import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { RiCalendarEventLine, RiPriceTag3Line } from 'react-icons/ri'
import Button from "./Button";

const Divider = () => <span style={{ fontWeight: 600, fontSize: '14px', margin: '0px 1rem' }}>|</span>
const ProgramGroupHultCard = ({ program, link, noJotForm }) => {

    return (
        <div className="program-individual-card">
            <div className="flex">
                <h5>{program.title}</h5>
                <a href={link} target="_blank" rel="noreferrer">
                    <Button
                        label="Apply"
                        variant="primary"
                        size="sm"
                        style={{ padding: '5px 40px' }}
                    />
                </a>
            </div>
            <div className="program-fees-box">
                <div>
                    <span>{program.fee.totalAmount ? 'Total Tuiton Fee:' : 'Tuition:'}</span>{" "}
                    <span style={{ color: '#F26527', fontWeight: 700 }}>{program.fee.totalAmount ? program.fee.totalAmount : program.fee.amount}</span>{" "}
                    {program.fee.oldAmount && <span style={{ textDecoration: 'line-through' }}>{program.fee.oldAmount}</span>}
                </div>
                <Divider />
                {program.fee.mode && <><div>
                    <span>Mode: </span> {" "}
                    <span style={{ color: '#51B74B', fontWeight: 700 }}>{program.fee.mode}</span>
                </div>
                    <Divider />
                </>}

                <div>
                    <span>Duration: </span> {" "}
                    <span style={{ fontWeight: 600 }}>{program.fee.duration}</span>
                </div>
            </div>
            {program.aboutProgramme.map((info, i) => <p key={i} style={{ marginTop: '20px' }}>
                {info}
            </p>)}

            {program.majors && <p style={{ fontWeight: 700, marginTop: '20px' }}><i>
                Majors: {program.majors}</i>
            </p>}
            {program.minors && <p style={{ fontWeight: 700 }}><i>
                Minors: {program.minors}</i>
            </p>}

            {
                program.study && <p style={{ fontWeight: 600, marginTop: '20px' }}>
                    Study from
                    <span style={{ marginLeft: "10px" }}> {program.study.map((location, i) => <Button key={i} label={location} size="sm" variant="bordered" style={{ margin: "2px 5px" }} />)}</span>
                </p>
            }
        </div>
    );
};

export default ProgramGroupHultCard;
