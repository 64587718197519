import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import { institutions } from "../data/instututionsAndCourses";
import LinesEllipsis from "react-lines-ellipsis";
import "../assets/styles/componentStyles/program-card.scss";

const ProgramCard = ({
  program,
  programsState,
  setShowForm,
  isInstitution,
}) => {
  const { title, link, content, degree, availabilityStatus, institution } =
    program;

  const childInstitution = institutions.find(
    (institution) => institution.id === program
  );

  return (
    <div className="program-card">
      <div className={`text-right ${isInstitution ? "d-none" : ""}`}>
        <span
          className={`program-status ${availabilityStatus
              ? "program-status--active"
              : "program-status--closed"
            }`}
        >
          {availabilityStatus ? "Active" : "Closed"}
        </span>
      </div>
      <h5 className="mt-2">{isInstitution ? childInstitution.name : title}</h5>
      {program.fee &&
        <div style={{ margin: '.2rem 0' }}>
          <span s>Tuition:</span>{" "}
          <span style={{ color: '#F26527', fontWeight: 700 }}>{program.fee.amount}</span>{" "}
          {program.fee.oldAmount && <span style={{ textDecoration: 'line-through' }}>{program.fee.oldAmount}</span>}
        </div>
      }
      <div>
        <LinesEllipsis
          text={isInstitution ? childInstitution.about[0] : content}
          maxLine="5"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
      <div className="mt-2">
        {degree && (
          <label className="degree-type-tag" htmlFor="degree">
            {degree}
          </label>
        )}
      </div>
      {(availabilityStatus || isInstitution) && (
        <div className="course-card-cta">
          <div className="row mt-3 ">
            {institution === 6 || childInstitution?.parentInstitution === 6 ? (
              <>
                <div className="col-6">
                  <Button
                    label="Apply"
                    size="sm"
                    fullWidth
                    variant="secondary"
                    onClick={() => {
                      console.log('form form', childInstitution)
                      if (childInstitution.firstJotForm) {
                        setShowForm("jotform-1");
                      } else if (childInstitution.secondJotForm) {
                        setShowForm("jotform-2");
                      } else {
                        setShowForm("jotform-3");
                      }
                    }}
                  />
                </div>

                {/* THIS SHOULD ROUTE TO A MANIPAL CUSTOM PAGE */}
                <div className="col-6">
                  <Link
                    to={{
                      pathname: "/manipal-program",
                      state: {
                        learnMore: childInstitution.learnMore,
                        name: childInstitution.name,
                        firstJotForm: childInstitution.firstJotForm,
                        secondJotForm: childInstitution.secondJotForm,
                      },
                    }}
                  >
                    <Button
                      label="Learn More"
                      variant="primary"
                      size="sm"
                      fullWidth
                    />
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="col-6">
                  <a href={link} target="_blank" rel="noreferrer">
                    <Button
                      label="Apply"
                      size="sm"
                      fullWidth
                      variant="secondary"
                    />
                  </a>
                </div>

                {institution === 4 && (
                  <div className="col-6">
                    <Link
                      to={{
                        pathname: "/program",
                        state: { program, programsState },
                      }}
                    >
                      <Button
                        label="Learn More"
                        variant="primary"
                        size="sm"
                        fullWidth
                      />
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramCard;
