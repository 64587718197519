import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { RiSubtractFill, RiAddFill, RiVideoFill } from 'react-icons/ri'
import PopUpVideo from "../components/popUpVideo";

const Collapsed = ({ courseGroup, showAllPrograms }) => {
    const [showPrograms, setShowPrograms] = React.useState(false)
    const [popUpOpen, setPopUpOpen] = React.useState(false);

    return (
        <div style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.16)', width: '100%', height: 'auto', backgroundColor: 'white', padding: '2rem', marginBottom: '0', marginTop: '0' }}>
            <div style={{ marginBottom: showPrograms || showAllPrograms ? '2rem' : '0px', display: 'flex', justifyContent: 'space-between' }}>
                <div>{showPrograms || showAllPrograms ? <RiSubtractFill onClick={() => setShowPrograms(!showPrograms)} size="20px" style={{ marginRight: '20px', cursor: 'pointer' }} /> : <RiAddFill onClick={() => setShowPrograms(!showPrograms)} size="20px" style={{ marginRight: '20px', cursor: 'pointer' }} />}{courseGroup.title}</div>
                <div>{courseGroup.duration}</div>
            </div>
            {(showPrograms || showAllPrograms) && <>
                {courseGroup.programmes.map(j => {
                    return (
                        <div className="course-individual">
                            <div className="flex">
                                <div> <RiVideoFill size="20px" style={{ marginRight: '15px' }} />{j.title}</div>
                                <div> {j.videoId && <span style={{ marginRight: '100px', cursor: 'pointer' }} onClick={() => setPopUpOpen(true)}>Preview</span>}{j.time}</div>
                            </div>
                            <PopUpVideo
                                open={popUpOpen}
                                channel="vimeo"
                                videoId="674986227"
                                onClose={() => setPopUpOpen(false)}
                            />
                        </div>
                    )
                })}
            </>}

        </div>);
};

export default Collapsed;