import React, { useState } from "react";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { institutions } from "../data/instututionsAndCourses";
import tutorImage from "../assets/images/tutor-image.png";
import deadlineImage from "../assets/images/deadline-image.svg";
import durationImage from "../assets/images/duration-image.svg";
import feeImage from "../assets/images/fee-image.svg";
import startImage from "../assets/images/start-image.svg";
import { HiOutlinePlus, HiOutlineMinus } from "react-icons/hi";
import "../assets/styles/pageStyles/program.scss";

const Program = (props) => {
  const { program, programsState } = props.location?.state;
  const {
    title,
    duration,
    institution,
    oldPrice,
    newPrice,
    module,
    start,
    overview,
    outline,
    link,
    tutor,
    curriculum,
  } = program;

  const [openAccordions, setOpenAccordions] = useState([]);
  // const [showOtherDetails, setShowOtherDetails] = useState(false);
  const checkExpanded = (index) =>
    openAccordions.includes(index)
      ? setOpenAccordions(
          openAccordions.filter((accordionIndex) => accordionIndex !== index)
        )
      : setOpenAccordions([...openAccordions, index]);

  const { email, tutorName, experience } = tutor || {};

  return (
    <div>
      <div className="bg-blue-primary program-banner">
        <div className="container">
          <Link to={{ pathname: programsState.redirect, state: programsState }}>
            <p
              className="back-cta"
              style={{ cursor: "pointer", color: "#FFFFFF" }}
            >
              <MdArrowBack fontSize="1.6rem" />
              &nbsp; Back
            </p>
          </Link>

          <div className=" course-intro">
            <p>
              {
                institutions.find((institute) => institute.id === institution)
                  ?.name
              }
            </p>
            <div className="course-intro-header">
              <h2>{title}</h2>
              <div className="d-flex justify-content-between align-items-center course-intro-ctas">
                {curriculum && (
                  <a href={curriculum} download>
                    <button className="course-download-cta download-cta mt-0">
                      Download curriculum
                    </button>
                  </a>
                )}

                <a href={link} target="_blank" rel="noreferrer">
                  <Button label="Apply" size="long" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="course-intro-details-wrapper">
        <div className="container">
          <div className="course-intro-details">
            <div className="row" style={{ width: "100%" }}>
              <div className="col-6 col-lg-3 d-flex align-items-center justify-content-lg-center justify-content-md-start">
                <img src={durationImage} alt="" />
                <div className="course-intro-item">
                  <p>Duration</p>
                  <h3>{duration}</h3>
                </div>
              </div>
              <div className="col-6 col-lg-3 d-flex align-items-center justify-content-lg-center justify-content-md-start">
                <img src={feeImage} alt="" />
                <div className="course-intro-item">
                  <p>Tuition Fee</p>
                  <div className="">
                    <h3>{newPrice}</h3>
                    <h5>{oldPrice}</h5>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3 d-flex align-items-center justify-content-lg-center justify-content-md-start  mobile-top">
                <img src={deadlineImage} alt="" />
                <div className="course-intro-item">
                  <p>Modules</p>
                  <h3>{module}</h3>
                </div>
              </div>
              <div className="col-6 col-lg-3 d-flex align-items-center justify-content-lg-center justify-content-md-start mobile-top">
                <img src={startImage} alt="" />
                <div className="course-intro-item">
                  <p>Starts</p>
                  <h3 style={{ color: "#0B2F7A" }}>{start}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="course-details container">
        <div className="course-overview">
          <h3>Course Overview</h3>
          <p>{overview} </p>
        </div>
        <div className="course-outline">
          <div className="course-outline-header">
            <h3>Course Outline</h3>
          </div>
          {outline?.map((weeks, index) => (
            <div className="course-content " key={index}>
              <div
                className="d-flex justify-content-between"
                onClick={() => checkExpanded(index)}
              >
                <h3>Week {index + 1}</h3>
                <span
                  className="expand-cta"
                  onClick={() => checkExpanded(index)}
                >
                  {!openAccordions.includes(index) ? (
                    <HiOutlinePlus />
                  ) : (
                    <HiOutlineMinus />
                  )}
                </span>
              </div>

              <div
                className={`course-content-inner ${
                  openAccordions.includes(index)
                    ? "course-content-inner--open"
                    : ""
                }`}
              >
                <ul className="space-top-1">
                  {weeks.map(({ title, content }, topicIndex) => (
                    <li key={topicIndex} className="course-topic">
                      {title}
                      <ul className="topic-content">
                        {content?.map((contentItem, contentIndex) =>
                          typeof contentItem === "string" ? (
                            <li key={contentIndex}>{contentItem}</li>
                          ) : (
                            <li key={contentIndex}>
                              {contentItem.title}
                              <ul className="inner-child-content">
                                {contentItem.content?.map(
                                  (
                                    contentItemContent,
                                    contentItemContentIndex
                                  ) => (
                                    <li
                                      key={contentItemContentIndex}
                                      className="child-topic-item"
                                    >
                                      {contentItemContent}
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          )
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="tutor-section">
          <h4>Meet your Trainer</h4>
          <div className="tutor-profile">
            <div className="tutor-profile-intro d-flex">
              <div className="tutor-image-container">
                <img src={tutorImage} alt="tutor-name" />
              </div>
              <div
                className="d-flex justify-content-between intro-details"
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-column ">
                  <span id="tutor-name">{tutorName}</span>
                  <span id="tutor-course">{title}</span>
                  <span id="tutor-experience">{experience} experience</span>
                </div>
                <div className="d-flex flex-column align-items-md-end">
                  <span>Email:</span>
                  <span>
                    <a href={`mailto:${email}`}>{email}</a>
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="tutor-about">
              <p>{about[0]}</p>
              {about[1] && (
                <div>
                  {!showOtherDetails && (
                    <p
                      style={{
                        color: "#0F42A4",
                        cursor: "pointer",
                        fontSize: "15px",
                      }}
                      onClick={() => setShowOtherDetails(true)}
                    >
                      Read More
                    </p>
                  )}

                  {showOtherDetails &&
                    about
                      .slice(1)
                      .map((aboutParagraph, index) => (
                        <p key={index}>{aboutParagraph}</p>
                      ))}
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
