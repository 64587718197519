import React from "react";
import InstitutionsComponent from "../../components/InstitutionsComponent";

const Institutions = () => {
  return (
    <div className="bg-blue-secondary institutions-container">
      <div className="container ">
        <div className=" mb-4 institutions-containter-text dark-text">
          <h3>Institutions</h3>
          {/* <p className="my-4">
            We have partnered with a careful selection of world renowed
            institutions that offer courses that will be your path to a
            fulfilling career.
          </p> */}
        </div>
        <div className="institution-partner-logos">
          <InstitutionsComponent />
        </div>
      </div>
    </div>
  );
};

export default Institutions;
