import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { BiCheck } from "react-icons/bi";
import sisImage from "../../assets/images/sis-image.png";
import examPortalImange from "../../assets/images/exam-portal-image.png";
import appPortalImage from "../../assets/images/application-portal-image.png";
import lmsImage from "../../assets/images/lms-image.png";
import brochure from "../../assets/documents/brochure.pdf";

const AllProducts = () => {
  const history = useHistory();
  const products = [
    {
      title: "Student Information System (SIS)",
      content:
        "Enjoy seamless management of all academic operations with this single solution.",
      values: [
        "Data Management",
        "Gradebook Management",
        "Reporting",
        "Fee Payment",
      ],
      path: "/student-information-system",
      image: sisImage,
    },
    {
      title: "Learning Mangement System (LMS)",
      content:
        "Deliver content, track performance, facilitate collaboration and engage real-time, all with VigiLearn LMS.",
      values: [
        "User Management",
        "Social Networking",
        "Big Data Analytics",
        "Virtual Classroom",
      ],
      path: "/learning-management-system",
      image: lmsImage,
    },
    {
      title: "Application Portal",
      content:
        "Gatekeeping made easy. Manage all applications into your institution with an intuitive interface that allows you automate prequalification based on various parameters.",
      values: [
        "User Management",
        "Big Data Analytics",
        "Payment Integration",
        "User Screening",
      ],
      path: "/application-portal",
      image: appPortalImage,
    },
    {
      title: "Examination Portal",
      content:
        "Enjoy seamless management of all academic operations with this single solution. With its host of features, your institution is guaranteed to experience an exponential increase in administrative efficiency. ",
      values: [
        "Signle Sign-On",
        "Exam Instructions",
        "Automatic Reporting",
        "Question bank",
      ],
      path: "/examination-portal",
      image: examPortalImange,
    },
  ];

  return (
    <div className="products-container">
      {products.map(({ title, content, values, path, image }, index) => (
        <div
          className={`${index % 2 !== 0 ? "bg-blue-secondary" : ""}`}
          key={index}
        >
          <div className="container" data-aos="fade-up">
            <div
              className={`row product-container ${
                index % 2 !== 0 ? "flex-row-reverse" : ""
              } `}
            >
              <div className="col-lg-6 text-center">
                <img src={image} className="product-image" alt={title} />
              </div>

              <div className="col-lg-6">
                <h1 className="mt-sm-4">{title}</h1>
                <p>{content} </p>
                <div className="mt-4 mb-4 product-values row">
                  {values.map((value, key) => (
                    <div className="col-6 mb-4" key={key}>
                      <span className="check-icon mr-2">
                        {" "}
                        <BiCheck style={{ marginTop: "-.3rem" }} />
                      </span>
                      <span>{value}</span>
                    </div>
                  ))}
                </div>

                <Button
                  label="Learn more"
                  size="sm"
                  variant="primary"
                  onClick={() => history.push(path)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="products-brochure bg-blue-primary">
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <h1>
                We're eager to show you what our products can really do. Get
                started here.
              </h1>
            </div>
            <div className="col-sm-3">
              <a href={brochure} download="Vilgilearn-brochure">
                <button className="download-cta">Download Brochure</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
