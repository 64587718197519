import React from "react";
import "../assets/styles/componentStyles/alumninetwork.scss";
import Statistics from "../pages/Home/Statistics";

const AlumniNetwork = ({ alumni }) => {
  const data = [
    {
      value: alumni.network ? alumni.network : alumni.students,
      title: alumni.network ? "Alumni Network" : "Students",
      class: "green",
    },
    {
      value: alumni.activeStudents ? alumni.activeStudents : (alumni.intlStudents ? alumni.intlStudents : alumni.alumniWorldwide),
      title: alumni.activeStudents ? "Active Students" : (alumni.alumniWorldwide ? "Alumni Worldwide" : "Active Students"),
      class: "yellow",
    },
    {
      value: alumni.countries ? alumni.countries : alumni.countriesNtl,
      title: alumni.countries ? "Countries with Students" : "Student Nationalities",
      class: "orange",
    },
    {
      value: alumni.campuses,
      title: "International campuses",
      class: "blue",
    },
  ];

  const userData = !alumni.pulitzerPrizes ? data : [...data, {
    value: alumni.pulitzerPrizes,
    title: "Pulitzer Prizes",
    class: "grey",
  }];
  return (
    <Statistics data={userData} transparent wrap />
  );
};

export default AlumniNetwork;
