import ManipalAhe1 from '../assets/images//manipalAheIntern/1.png';
import ManipalAhe2 from '../assets/images//manipalAheIntern/2.png';
import ManipalAhe3 from '../assets/images//manipalAheIntern/3.png';
import ManipalAhe4 from '../assets/images//manipalAheIntern/4.png';
import ManipalAhe5 from '../assets/images//manipalAheIntern/5.png';
import ManipalAhe6 from '../assets/images//manipalAheIntern/6.png';
import ManipalAhe7 from '../assets/images//manipalAheIntern/7.png';
import ManipalAhe8 from '../assets/images//manipalAheIntern/8.png';


export const manipalAHEIntImages = [
    ManipalAhe1,
    ManipalAhe2,
    ManipalAhe3,
    ManipalAhe4,
    ManipalAhe5,
    ManipalAhe6,
    ManipalAhe7,
    ManipalAhe8,
];
