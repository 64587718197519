import React, { useState } from "react";
import PopUpVideo from "../../components/popUpVideo";
import playImage from "../../assets/images/play-image.png";
import "../../assets/styles/pageStyles/products.scss";

import { displayData } from "../../helpers";

const StudentInformationSystem = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);

  const data = [
    {
      title: "Data Management",
      content:
        "This solution provides a centralized place to institute data, which also includes a data backup facility. Admins can easily access student records, add new entries, print the records in a single click, export data, easily customize fields and update information.",
    },
    {
      title: "Gradebook Management",
      content:
        "VigiLearn SIS allows admins to import marks in bulk, and also easily edit the mark of the students for active and inactive batches. The Gradebook provides all student grades in one place, and users can view and download GPA and CGPA reports.",
    },

    {
      title: "Admission/Registration Management",
      content:
        "VigiLearn SIS stores the academic records of all users and allows admins to generate custom reports which can include any information tied to user profiles, and even limit accessibility to specific users.  This solution also gives an automatic allotment of batches & subjects to students for every academic year.",
    },
    {
      title: "Fee payment",
      content:
        "With VigiLearn SIS, fees can be collected online by integrating the SIS with options of Payment Gateways available. Admins can also schedule fee collection dates & set up payments based on chosen parameters. Payment receipts can also be generated for all users at the click of a button.",
    },

    {
      title: "Reporting",
      content:
        "You can check reports related to finance, users, courses and much more. Reports are dynamic and get automatically updated as the data changes, and can be exported in various formats including XML, CSV, Word & PDF.",
    },

    {
      title: "Timetable/Calendar Management",
      content:
        "With VigiLearn SIS, admin can set up timetable for courses and also schedule dates for tests, assignment deadlines, and exams. This timetable can be viewed by the students on their SIS platform.",
    },
  ];

  const showData = (start, number) => displayData(data, start, number);

  return (
    <div className="products-container product-wrapper">
      <div className="product-banner container text-center">
        <div className="product-banner-text">
          <h1 data-aos="fade-down">
            <span className="green-text">Student</span>{" "}
            <span className="orange-text">Information System</span>
          </h1>
          <p data-aos="fade-up">
            Enjoy seamless management of all academic operations with this
            single solution. With its host of features, your institution is
            guaranteed to experience an exponential increase in administrative
            efficiency.{" "}
          </p>
        </div>
        <div className="product-image-box d-flex align-items-center justify-content-center">
          <div
            className="product-play-container"
            onClick={() => setPopUpOpen(!popUpOpen)}
          >
            <img src={playImage} alt="" width="35px" height="40px" />
          </div>
        </div>
      </div>
      <div className="product-key-features bg-blue-primary d-flex justify-content-center">
        <div className="features-content ">
          <h2 className="text-center">Key Features</h2>
          <div className="d-none d-lg-block">
            <div className="feature-cards row">
              <div className="col-4">{showData(0, 2)}</div>
              <div className="col-4">{showData(2, 4)}</div>
              <div className="col-4">{showData(4, 6)}</div>
            </div>
          </div>
          <div className="d-block d-lg-none">
            <div className="feature-cards row">
              <div className="col-md-6">{showData(0, 3)}</div>
              <div className="col-md-6">{showData(3, 6)}</div>
            </div>
          </div>

        </div>
      </div>
      <PopUpVideo
        open={popUpOpen}
        channel="vimeo"
        videoId="564136209"
        onClose={() => setPopUpOpen(false)}
      />
    </div>
  );
};

export default StudentInformationSystem;
