import React from "react";
import "../assets/styles/componentStyles/statistics-card.scss";

const StatisticsCard = ({ data }) => {
  const { value, title, class: className } = data;
  return (
    <>
      <div className={`stat-card-top--${className}`}></div>
      <div
        className={`statistics-card-container d-flex flex-column align-items-center statistics-card-container--${className}`}
      >
        <h2>{value}</h2>
        <p>{title}</p>
      </div>
    </>
  );
};

export default StatisticsCard;
