import React from "react";
import { Link } from "react-router-dom";

const Accounting = () => {
  const reasons = [
    {
      content:
        "Right now is the best time to register. Classes are held online and can be taken from the safety and comfort of a student’s home. So if you think you can accomplish it, just do it. ",
    },
    {
      content:
        "The application period for the next class of accounting students is currently open. Apply now to kickstart your career in the field of accounting. Available schools are: Babcock University and the Ahmadu Bello University, Zaria.",
    },
    {
      title: "Prestigious Universities Options",
      content:
        "We have partnered with two prestigious universities for students to choose from, Ahmadu Bello University, Zaria and the Babcock University. These schools assure quality and integrity of tertiary education in Africa. At the end of the programme, students will be capable of competing globally in your field.",
    },
    {
      title: "Payment flexibility on school fees",
      content:
        "When you register on VigiLearn, learners can pay in installments",
    },
  ];
  return (
    <>
      <p>
        Today’s world is big on numbers. From school arithmetic and market runs
        to financial statements from huge corporations, numbers are a huge part
        of everyday life. Yet, there are people who have a special love for
        numbers and money. Are you one of those? Do you perceive numbers
        differently? Are you detailed and meticulous? If you answered yes to all
        these questions, we think you should consider taking steps to build a
        career in Accounting via VigiLearn.
      </p>
      <p className="mt-4">
        At VigilLearn, we strive to pursue increased learning and productivity
        for a diverse community of people and professionals. As a result, we
        have developed a learning management platform that enables education to
        as many people as possible, no matter where they are, and at their own
        convenience.
      </p>
      <p className="mt-4">
        A career in accounting offers many opportunities, the field provides
        many career paths that can lead to many jobs. Accountants are also well
        paid, with some organizations providing additional benefits to
        remuneration packages. All these positive employment prospects and the
        stability of an accounting career lead many individuals to choose
        finance as a career path. This profession has some growth potential, and
        individuals can easily move up the ranks. However, this might mean
        obtaining additional credentials in other and related fields.
      </p>
      <p className="mt-4">
        With VigiLearn, you can start your journey to a successful career in
        finance by building a strong foundation in accounting. We offer
        accredited BSc programs in Accounting, where learners can gain an
        understanding of a variety of accounting concepts that are sure to set
        them on the right path, thereby providing students with the skills
        necessary to survive on the job market as an accountant.
      </p>
      <p className="mt-4">
        In partnership with schools such as Babcock University and Ahmadu Bello
        University, we offer NUC-accredited e-learning Bachelor of Science in
        Accounting degrees. This course can help prepare individuals for careers
        with audit firms, banking, investment firms, semi-government, public,
        and private organizations.
      </p>
      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">
        Why Should You Register for your B.Sc in Accounting with VigiLearn
        today?
      </h4>
      <ul className="mt-4">
        {reasons.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            {title && <span className="font-weight-bold">{title}: </span>}
            <span>{content}</span>
          </li>
        ))}
      </ul>

      <p className="mt-5">
        Not thinking about an accounting course? Consider taking other
        undergraduate, postgraduate, and professional courses which are also
        available on VigiLearn. Click <Link to="/programs">here</Link> to make a
        choice.
      </p>
      <p className="mt-4">
        We offer master’s degree programs ranging from the Sciences to Business
        and the Arts from prestigious Nigerian universities like Ahmadu Bello
        University, Obafemi Awolowo University and Babcock University. Learn
        more about our programs <Link to="/programs">here</Link>.
      </p>
    </>
  );
};

export default Accounting;
