import React from "react";
import "../assets/styles/componentStyles/rating.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Rating = ({ awards }) => {

    const responsive = {
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 2,
            partialVisibilityGutter: 45,
            slidesToSlide: 2
        },
    };

    return (
        <>
            <div className="row rating-root hide-sm">
                {awards.map((award, index) => {
                    return (
                        <div style={{ margin: '1rem' }}>
                            <img src={award} alt="award" />
                        </div>
                    )
                })}
            </div>
            <div className="row rating-root d-block d-sm-none">
                <Carousel responsive={responsive} arrows={false} partialVisible={true}>
                    {awards.map((award, index) => {
                        return (
                            <div style={{ marginRight: '1rem' }}>
                                <img src={award} alt="award" />
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </>
    );
};

export default Rating;
