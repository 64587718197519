import employer1 from '../assets/images/hultEmployers/employer1.png';
import employer2 from '../assets/images/hultEmployers/employer2.png';
import employer3 from '../assets/images/hultEmployers/employer3.png';
import employer4 from '../assets/images/hultEmployers/employer4.png';
import employer5 from '../assets/images/hultEmployers/employer5.png';
import employer10 from '../assets/images/hultEmployers/employer10.png';
import employer11 from '../assets/images/hultEmployers/employer11.png';
import employer12 from '../assets/images/hultEmployers/employer12.png';
import employer13 from '../assets/images/hultEmployers/employer13.png';
import employer14 from '../assets/images/hultEmployers/employer14.png';

export const hultImages = [
    employer1,
    employer2,
    employer3,
    employer4,
    employer5,
];

export const hultImages2 = [
    employer10,
    employer11,
    employer12,
    employer13,
    employer14,
];