import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { RiCalendarEventLine, RiPriceTag3Line } from 'react-icons/ri'
import Button from "../components/Button";

const Divider = () => <span style={{ fontWeight: 600, fontSize: '14px', margin: '0px 1rem' }}>|</span>
const ProgramGroupIndividualCard = ({ program, firstJotForm, secondJotForm, noJotForm, link, showForm, setShowForm }) => {

    return (
        <div className="program-individual-card">
            <div className="flex">
                <h5>{program.title}</h5>
                {noJotForm ? <a href={link} target="_blank" rel="noreferrer">
                    <Button
                        label="Apply"
                        variant="primary"
                        size="sm"
                        style={{ padding: '5px 40px' }}
                    />
                </a> :
                    <Button
                        label="Apply"
                        variant="primary"
                        size="sm"
                        style={{ padding: '5px 40px' }}
                        onClick={() => {
                            if (firstJotForm) {
                                setShowForm("jotform-1");
                            } else if (secondJotForm) {
                                setShowForm("jotform-2");
                            } else {
                                setShowForm("jotform-3");
                            }
                        }}
                    />}
            </div>
            <div className="program-fees-box">
                <div>
                    <span>Tuition:</span>{" "}
                    <span style={{ color: '#F26527', fontWeight: 700 }}>{program.fee.amount}</span>{" "}
                    {program.fee.oldAmount && <span style={{ textDecoration: 'line-through' }}>{program.fee.oldAmount}</span>}
                </div>
                <Divider />
                <div>
                    <span>Installments: </span> {" "}
                    <span style={{ color: '#51B74B', fontWeight: 700 }}>{program.fee.installments} installments</span>{" "}
                    <span style={{ fontWeight: 700 }}>over {program.fee.installmentPeriod}</span>
                </div>
                <Divider />
                <div>
                    <span>Intakes: </span> {" "}
                    <span style={{ fontWeight: 600 }}>{program.fee.intakes}</span>
                </div>
            </div>
            {program.aboutProgramme.map(info => <p style={{ marginTop: '20px' }}>
                {info}
            </p>)}

            {program.specialization && <p style={{ fontWeight: 700, marginTop: '20px' }}>
                Specialization: {program.specialization.toUpperCase()}
                {program.timing && <p style={{ fontWeight: 700 }}>
                    Class timing: {program.timing}
                </p>}
            </p>}
        </div>
    );
};

export default ProgramGroupIndividualCard;
