export const faq = [
  {
    question: "What is VigiLearn?",
    answer:
      "VigiLearn is an e-learning platform that connects learners to quality online education through a variety of top institutions around the world",
  },
  {
    question: "How can I apply for a Programme?",
    answer:
      "To apply, visit the programmes or institutions page, select a course of interest, view its requirements, then proceed to fill out an application (if applicable)",
  },
  {
    question: "What kinds of programmes are available?",
    answer:
      "There are several programmes you can study for with VigiLearn, and they cut across Undergraduate, Postgraduate and Professional levels.",
  },
  {
    question: "How much does it cost?",
    answer:
      "The cost of the programme depends on the institution hosting it. However, VigiLearn guarantees you get the best possible price for its available programmes. To further ensure that finance is not a barrier to learning, our sister company - EduCollect, is available to provide quick access to education loans. Learn more on educollectfinance.com",
  },
  {
    question: "What is the mode of payment",
    answer:
      "Payment is completely online via the institution’s portal. Some operate a pay-per-course model, while others allow instalmental payment, and some, payment upfront.",
  },
  {
    question: "Where is VigiLearn located?",
    answer:
      "VigiLearn’s global headquarters is in the United Arab Emirates, while our first local office is in Lagos, Nigeria.",
  },
  {
    question:
      "Do I have to write JAMB to apply for an undergraduate programme?",
    answer:
      "Certain programmes require a JAMB result without emphasis on the score attained, while others do not require a JAMB result at all.",
  },
  {
    question: "Are the examinations done online?",
    answer:
      "Examinations are typically administered physically at designated locations, but some courses make use of online assessments in place of examinations. ",
  },
  {
    question: "Does the LMS require internet service?",
    answer: "Yes, you will need to have internet access to use the LMS",
  },
  {
    question: "Are these programmes NUC accredited?",
    answer:
      "Yes, all programmes on our platform from Nigerian Universities are NUC accredited and give you the same degree as on-campus students.",
  },
];
