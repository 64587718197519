import ManipalIU1 from '../assets/images/manipalIUAcc/1.png';
import ManipalIU2 from '../assets/images/manipalIUAcc/2.png';
import ManipalIU3 from '../assets/images/manipalIUAcc/3.png';
import ManipalAhe1 from '../assets/images/manipalAheAcc/1.png';
import ManipalAhe2 from '../assets/images/manipalAheAcc/2.png';
import ManipalAhe3 from '../assets/images/manipalAheAcc/3.png';
import ManipalAhe4 from '../assets/images/manipalAheAcc/4.png';
import ManipalGlobalNxt1 from '../assets/images/manipalGlobalNxtAcc/1.png';
import ManipalGlobalNxt2 from '../assets/images/manipalGlobalNxtAcc/2.png';
import ManipalGlobalNxt3 from '../assets/images/manipalGlobalNxtAcc/3.png';
import ManipalGlobalNxt4 from '../assets/images/manipalGlobalNxtAcc/4.png';
import ManipalGlobalNxt5 from '../assets/images/manipalGlobalNxtAcc/5.png';
import ManipalGlobalNxt6 from '../assets/images/manipalGlobalNxtAcc/6.png';
import ManipalGlobalNxt7 from '../assets/images/manipalGlobalNxtAcc/7.png';
import hultImage1 from '../assets/images/hultImages/1.png';
import hultImage2 from '../assets/images/hultImages/2.png';
import hultImage3 from '../assets/images/hultImages/3.png';
import ritImage1 from '../assets/images/ritImages/1.png';
import ritImage2 from '../assets/images/ritImages/2.png';
import ritImage3 from '../assets/images/ritImages/3.png';
import ritImage4 from '../assets/images/ritImages/4.png';


export const manipalIUImages = [
    ManipalIU1,
    ManipalIU2,
    ManipalIU3,
];

export const manipalAHEImages = [
    ManipalAhe1,
    ManipalAhe2,
    ManipalAhe3,
    ManipalAhe4,
];

export const manipalGlobalNxtImages = [
    ManipalGlobalNxt1,
    ManipalGlobalNxt2,
    ManipalGlobalNxt3,
    ManipalGlobalNxt4,
    ManipalGlobalNxt5,
    ManipalGlobalNxt6,
    ManipalGlobalNxt7,
];

export const hultAccreditationImages = [
    hultImage1,
    hultImage2,
    hultImage3,
];

export const ritAccreditationImages = [
    ritImage1,
    ritImage2,
    ritImage3,
    ritImage4,
];