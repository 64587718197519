import React from "react";
import InstitutionsComponent from "../components/InstitutionsComponent";
import "../assets/styles/pageStyles/partner.scss";
import CoursesComponent from "../components/CoursesComponent";
import { institutions } from "../data/instututionsAndCourses";
import { useParams, Redirect } from "react-router-dom";

const Partner = () => {
  const { id } = useParams();

  const institution = institutions.find(
    (institution) => institution.id === Number(id)
  );

  const { name, image, about } = institution || {};

  if (!id || !institution) {
    return <Redirect to={"/"} />;
  }

  return (
    <>
      <div className="institution-partner-banner">
        <div className="container">
          <h2>{name} </h2>
          <p>
            We Connect our Partners to Thousands of Students Around the World{" "}
          </p>
        </div>
      </div>
      <div className="container about-university">
        <div className="row">
          <div className="col-lg-6">
            <div className="univeristy-image">
              <img src={image} alt={name} width="100%" />
            </div>
          </div>
          <div className="col-lg-6 dark-text py-2">
            <h3>About University</h3>
            {about?.map((info, index) => (
              <p key={index} className="mt-4">
                {info}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-blue-secondary institution-programs">
        <CoursesComponent institutionId={id} />
      </div>
      <div className="container other-schools">
        <h2>Other Institutions</h2>
        <InstitutionsComponent />
      </div>
    </>
  );
};

export default Partner;
