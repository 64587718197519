import React from "react";
import Button from "../../components/Button";


const PartnerBanner = () => {
  return (
    <div className="bg-blue-primary partner-intro" data-aos="fade-zoom-in">
      <div className="row m-0 partner-intro-container">
        <div className="col-xs-12 col-xl-6 partner-hero-text">
          <h1>Institution Partners</h1>

          <p>
            Through our partnerships with progressive organizations, VigiLearn
            is able to provide access to globally acclaimed degree programs and
            certification courses through our user-friendly learning management
            systems.{" "}
          </p>

          <div>
            <a href="#partner-form">
              <Button label="Become a Partner" />
            </a>
          </div>
        </div>
        <div
          className="d-none d-xl-block col-xl-6 partner-hero-image"
        ></div>
      </div>
    </div>
  );
};

export default PartnerBanner;
