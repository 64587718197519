import React, { useState, useEffect } from "react";
import applyPortal from "../../assets/images/application-portal-image.png";
import examPortal from "../../assets/images/exam-portal-image.png";
import sis from "../../assets/images/sis-image.png";
import lms from "../../assets/images/lms-image.png";

const WhatWeDo = () => {
  const initialData = [
    {
      title: "Apply Portal",
      content:
        "Manage academic and recruitment application processes with ease, with VigiLearn Apply.",
      image: applyPortal,
    },
    {
      title: "Learning Management System (LMS)",
      content:
        "Deliver content, track performance, grade courses & foster real-time collaboration in a single solution.",
      image: lms,
    },
    {
      title: "Student Information System (SIS)",
      content:
        "With a variety of features, VigiLearn SIS effectively manages all your academic and administrative operations.",
      image: sis,
    },
    {
      title: "Exam Portal",
      content:
        "Examinations done the right way. Simpler. Faster. Safer.",
      image: examPortal,
    },
  ];

  const [data, setData] = useState(initialData);

  const [, setTime] = useState(0);

  const [, setImageInterval] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setData((prev) => {
        prev.push(prev.shift());
        return prev;
      });
      setTime((prevTime) => prevTime + 7000);
    }, 7000);
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  //had to be a different useEffect
  useEffect(() => {
    const timer = window.setInterval(() => {
      setImageInterval((prevIndex) =>
        prevIndex < initialData.length - 1 ? prevIndex + 1 : 0
      );
    }, 7000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="what-we-do-wrapper" data-aos="fade-up">
      <div className="what-we-do">
        <div className="container">
          <div className="what-we-do-intro mx-auto">
            <h1>What We Do</h1>
            <p>
              We provide a platform through which progressive academic &amp;
              corporate organizations deliver learning to as many individuals as
              possible{" "}
            </p>
          </div>
          <div className="wwd-image-container">
            {/* <img src={initialData[imageInterval].image} alt="" /> */}
            <img src={lms} alt="" />
          </div>
        </div>
      </div>

      <div className="bg-blue-tetiary wwd-details home-other ">
        <div className="container">
          <div className="row">
            {data.slice(0, 3).map(({ title, content }, index) => (
              <div
                className={`col-xs-12 col-md-4  ${
                  index !== 1 ? "d-none d-md-block" : ""
                }`}
                key={index}
              >
                <div
                  className={`wwd-card wwd-card--${
                    index === 1 ? "active" : "inactive"
                  }`}
                >
                  <h2>{title}</h2>
                  <p>{content}</p>{" "}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
