import React from "react";
import "../assets/styles/componentStyles/location.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Location = ({ location }) => {

    const responsive = {
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 2,
            partialVisibilityGutter: 45,
            slidesToSlide: 2
        },
    };

    return (
        <>
            <div className="row location-root">
                <div className="col-lg-6">
                    <p className="col-lg-8">
                        At Hult, your ambition isn’t limited by boundaries—here, your degree will take you beyond business to blaze your own trail and become a leader in today’s business world. <b>With campus locations in six cities</b>, and students of unparalleled diversity, you’ll live a global education, not just learn about it.
                    </p>
                </div>
                <div className="col-lg-6 row px-auto mx-auto">
                    {location.places.map((location, index) => {
                        return (
                            <div className="col-lg-4">
                                <div className="card center mx-auto">
                                    <img src={location.image} alt="location" />
                                    <h6>{location.name}</h6>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default Location;
