import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { useParams, useLocation, Redirect } from "react-router-dom";
import ProgramGroupCard from "../components/programGroupCard";
import {
  RiBankCard2Line,
  RiBankLine,
  RiCalendarEventLine,
  RiPriceTag3Line,
  RiMacLine,
  RiRouteLine,
  RiAdminLine,
  RiTerminalWindowFill,
  RiBookMarkLine,
  RiStarLine,
  RiHeart2Line,
  RiPercentFill,
  RiStarFill,
  RiSuitcaseFill,
} from "react-icons/ri";
import InstitutionsComponent from "../components/InstitutionsComponent";
import PaymentMode from "../components/PaymentMode";
import Button from "../components/Button";
import AlumniNetwork from "../components/AlumniNetwork";
import star from "../assets/images/star.png";
import Rating from "../components/Rating";
import Location from "../components/Location";
import Openings from "../components/Openings";
import { institutions } from "../data/instututionsAndCourses";
import ProgramGroupIndividualCard from "../components/programGroupIndividualCard";
import ProgramGroupCollapsibleCard from "../components/programGroupCollapsibleCard";
import ProgramGroupHultCard from "../components/programGroupHultCard";
import { Modal } from "react-bootstrap";
import brochure from "../assets/documents/ManipalBrochurefinal.pdf";
import { Hidden } from "@material-ui/core";
import CoursesComponent from "../components/CoursesComponent";
import EmployabilityStatistics from "../components/EmployabilityStatistics";
import study1 from '../assets/images/study1.svg';
import study2 from '../assets/images/study2.svg';
import rankImage from '../assets/images/ranking-image.jpeg';

const PartnerInstitutionProgram = () => {
  // const location = useLocation()
  const { id } = useParams();
  const program = institutions.find((i) => i.id === Number(id));
  const [showForm, setShowForm] = React.useState(null);
  const [showCourses, setShowCourses] = React.useState(true);
  const { name, accronym, image, link, about, learnMore, firstJotForm, secondJotForm, noJotForm, whyUs, parterned, isRIT, semiparterned, partnerBrochure } =
    program;

  const icons = [
    <RiMacLine size="25px" color="#F26527" />,
    <RiRouteLine size="25px" color="#F26527" />,
    <RiAdminLine size="25px" color="#F26527" />,
    <RiTerminalWindowFill size="25px" color="#F26527" />,
    <RiBookMarkLine size="25px" color="#F26527" />,
    <RiStarLine size="25px" color="#F26527" />,
    <RiHeart2Line size="25px" color="#F26527" />,
    <RiPercentFill size="25px" color="#F26527" />,
  ];

  const toggleShow = event => {
    const courseTab = document.getElementById("course");
    event.target.classList.add('isActive');
    console.log(":::::::::::::", courseTab);
    courseTab.classList.remove('isActive');
    setShowCourses(false)
  };
  const toggleHide = event => {
    const reqTab = document.getElementById("requirement");
    event.target.classList.add('isActive');
    console.log(":::::::::::::", reqTab);
    reqTab.classList.remove('isActive')
    setShowCourses(true)
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setCookieShow(true);
  //   }, 3000);
  // }, []);

  // const toggleShow = event => {
  //   event.target.classList.add('isActive');
  //   courseTab.classList.remove('isActive');
  //   setShowCourses(false)
  // };
  // const toggleHide = event => {
  //   event.target.classList.add('isActive');
  //   reqTab.classList.remove('isActive')
  //   setShowCourses(true)
  // };

  const paymentIcons = [
    <RiBankLine size="25px" />,
    <RiBankCard2Line size="25px" />,
  ];

  const rankingsData = [
    'Ranked 52nd among "best value schools" (2022)',
    'Ranked 12th among top schools\' co-op or internship programs (2022)',
    'Ranked 57th among "most innovative schools" (2022)',
    'Tied for 9th in the nation for "Best Online MBA programs" (2022) | U.S News & World Report',
    'Listed in 2022\'s Best 387 Colleges | Princeton Review',
    'Listed in Forbes Magazine as one of America\'s best colleges'
  ];
  const RankingsCard = ({data}) => {
    return (
      <div className="col-md-6" style={{paddingBottom : "0.5rem"}}>
        <div
          style={{
            height : "40px",
            width : "40px",
            borderRadius : "50%",
            backgroundColor : "white",
            color : "#3E63F6",
            display : "flex",
            alignItems : "center",
            justifyContent : "center"
          }}
        ><RiSuitcaseFill size="25px" /></div>
        <p>{data}</p>
      </div>
    )
  }

  if (!program) {
    return <Redirect to={"/"} />;
  }
  console.log("id", id);

  return (
    <>
      <div className="multiple-institution-partner-banner">
        <div className="container">
          <h2>{name} </h2>
        </div>
      </div>
      <div className="container about-university">
        {/* {partnerInstitutions.map(institution => <PartnerInstitutionsCard logo={logo} institution={institution} />)} */}

        <div className="row" style={{ flexDirection: parterned || semiparterned ? 'row-reverse' : '' }}>
          <div className="col-lg-6">
            <div className="univeristy-image">
              <img
                src={learnMore ? learnMore.image : image}
                alt={name}
                width="100%"
              />
              {learnMore && !parterned && !semiparterned && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    overflow: "hidden",
                    top: "2rem",
                    left: "3rem",
                    width: "230px",
                    height: "70px",
                  }}
                >
                  <img
                    src={program.logo}
                    alt={name}
                    style={{ width: "100%", height: "100%", display: "block" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 dark-text py-2">
            <h3>About {parterned || semiparterned ? accronym : 'University'}</h3>
            {learnMore && !semiparterned ? (
              <p className="mt-4">{learnMore.aboutUs}</p>
            ) : (
              about.map((i, index) => (
                <p className="mt-4" key={index}>
                  {i}
                </p>
              ))
            )}
          </div>
        </div>
        {learnMore &&
          learnMore.alumni &&
          !learnMore.alumni.individuals.length && !parterned && (
            <AlumniNetwork alumni={learnMore.alumni} />
          )}
        {learnMore && learnMore.rating && <Rating awards={learnMore.awards} />}
        {learnMore && learnMore.location && <Location location={learnMore.location} />}
        {learnMore && learnMore.openings && <Openings openings={learnMore.openings} />}
      </div>
      {parterned && id === "10" ?
        <>
          <div className="">
            <div className="hult-bg  d-none d-md-block">
              <div className="row m-0 ">
                <div className="col-lg-12 pr-0">
                  <div className="hult-bg-text" data-aos="zoom-in-right">
                    <p>
                      Your education is undoubtedly one of the<br />
                      most important investments you will ever make.<br />
                      <b>Take advantage of a Hult Scholarship today!</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-xs-block d-md-none">
              <div className="hult-bg-image"></div>
              <div className="bg-white mobile-impact-details" style={{ color: "#212529" }}>
                <p>
                  Your education is undoubtedly one of the<br />
                  most important investments you will ever make.<br />
                  <b>Take advantage of a Hult Scholarship today!</b>
                </p>
              </div>
            </div>
          </div>
        </>
        : parterned && isRIT ?
          <>
            <div className="rit-wrapper bg-blue-secondary">
              {
                name.includes("New York") &&
                <div className="container"
                  style={{
                    display : "flex",
                    flexWrap : "wrap",
                    paddingTop : "2rem"
                  }}
                >
                  <div className="col-lg-6">
                    <div className="univeristy-image" style={{paddingBottom : "1rem"}}>
                      <img
                        src={rankImage}
                        alt={name}
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 dark-text" style={{
                    paddingLeft : "2rem",
                    display : "flex",
                    flexWrap : "wrap",
                  }}>
                    {rankingsData.map((data) => {
                      return <RankingsCard data={data} />
                    })}
                  </div>
                </div>
              }

              <div className="rit-banner container text-center">
                <div className="rit-banner-text">
                  { name.includes("Dubai") ? 
                    <p data-aos="fade-up">
                      RIT Dubai offers students a range of scholarships for<br />
                      <span className="font-weight-bold"> undergraduate & postgraduate studies.</span> Our scholarships are competitive<br /> and require students to maintain excellent academic performance.
                    </p> 
                    : 
                    <p data-aos="fade-up">
                      RIT New York offers students a range of scholarships for<br />
                      <span className="font-weight-bold"> undergraduate & postgraduate studies.</span> Our scholarships are awarded <br /> based on your academic and/or extracurricular merit.
                      <br /> You are automatically considered for most RIT scholarships just by applying for admission.
                    </p>
                  }
                </div>
                <div className={`${name.includes("Dubai") ? "rit-image-box" : "rit-image-box2"} d-flex align-items-center justify-content-center`}>
                </div>
              </div>
            </div>
          </>
          : <></>
      }
      <div className={`${parterned && isRIT ? "spacing bg-blue-primary" : parterned && id !== "11" ? "bg-blue-primary" : "bg-blue-secondary"} multiple-other-schools why-study-with-us-container`}>
        <h3 className={`${!parterned ? "" : "white-text"}`}>Why study with us</h3>
        <div className="why-study-with-us">
          {learnMore && !parterned && !semiparterned
            ? learnMore.whyUs.map((item, i) => (
              <div key={i} className="col-lg-4 study-card">
                <div className="study-card-content">
                  <p
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "rgba(242, 101, 39, 0.1)",
                    }}
                  >
                    {icons.find((item, index) => index === i)}
                  </p>
                  <p>{item}</p>
                </div>
              </div>
            ))
            : learnMore && parterned && !semiparterned ?
              learnMore.whyUs.map((item, i) => (
                <div key={i} className="col-lg-6 study-card">
                  <div className="study-card-content" style={{ height: "auto" }}>
                    <p
                      style={{
                        // width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src={i % 2 === 0 ? study2 : study1}
                        className=""
                        alt="Vigilearn"
                      />
                      <span className="study-card-title"> {item.title} </span>
                    </p>
                    <p style={{
                      textTransform: "capitalize"
                    }}>{item.details}</p>
                  </div>
                </div>))
              : whyUs.map((item, i) => (
                <div key={i} className="col-lg-4 study-card">
                  <div className="study-card-content">
                    <p
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        backgroundColor: "rgba(242, 101, 39, 0.1)",
                      }}
                    >
                      {icons.find((item, index) => index === i)}
                    </p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>{item.title}</p>
                    <p>{item.details}</p>
                  </div>
                </div>
              ))}
        </div>
      </div>

      {/* PROGRAMMES​ */}
      {!parterned ? (
        <div className="container multiple-other-schools">
          {learnMore && !semiparterned ? <h3>Programmes</h3> : semiparterned ? <h3>Courses</h3> : ""}
          {learnMore ? (
            learnMore.programme.map((item, i) => {
              return (
                <>
                  {item.programGroups ? (
                    <ProgramGroupCard
                      key={i}
                      programGroup={item}
                      firstJotForm={firstJotForm}
                      secondJotForm={secondJotForm}
                      noJotForm={noJotForm}
                      link={link}
                      showForm={showForm}
                      setShowForm={setShowForm}
                    />)
                    : item.programCollapsible ?
                      (
                        <ProgramGroupCollapsibleCard
                          key={i}
                          program={item}
                          firstJotForm={firstJotForm}
                          secondJotForm={secondJotForm}
                          noJotForm={noJotForm}
                          link={link}
                          showForm={showForm}
                          setShowForm={setShowForm}
                        />
                      ) : (
                        <ProgramGroupIndividualCard
                          key={i}
                          program={item}
                          firstJotForm={firstJotForm}
                          secondJotForm={secondJotForm}
                          noJotForm={noJotForm}
                          link={link}
                          showForm={showForm}
                          setShowForm={setShowForm}
                        />
                      )}
                </>
              );
            })
          ) : (
            <CoursesComponent institutionId={id} />
          )}
        </div>
      ) : (
        <div className="container multiple-other-schools">
          <div className="row justify-content-center" style={{ marginBottom: "5rem" }}>
            <div className="tab-button isActive" id="course" onClick={toggleHide}>
              Courses
            </div>
            <div className="tab-button" id="requirement" onClick={toggleShow}>
              Entry Requirement
            </div>
          </div>
          {showCourses ? learnMore.programme.map((item, i) => {
            return (
              <>
                <ProgramGroupHultCard
                  key={i}
                  program={item}
                  link={link}
                  noJotForm={noJotForm}
                  showForm={showForm}
                  setShowForm={setShowForm}
                />
              </>
            );
          }) : (
            <div className="col-lg-12 entry-card mx-auto">
              <div className="entry-card-content">
                <h3> Entry Requirements </h3>
                {learnMore.requirements.map((item, i) => (
                  <div key={i} className="">
                    <div className="entry-card-title">
                      {item.title}
                    </div>
                    <ol className="dashed">
                      {item.list.map((data, i) => (
                        <li key={i}>
                          {/* {data !== "OR" ? "-" : ""} {data} */}
                          {data}
                        </li>
                      ))} </ol>
                  </div>
                ))}
              </div>
            </div>)}
        </div>
      )}

      <div className="container mt-3">
        {learnMore &&
          learnMore.courses &&
          learnMore.courses.length &&
          learnMore.courses.map((item, i) => {
            return (
              <>
                {
                  <ProgramGroupCard
                    key={i}
                    programGroup={item}
                    firstJotForm={firstJotForm}
                    secondJotForm={secondJotForm}
                    noJotForm={noJotForm}
                    link={link}
                    showForm={showForm}
                    setShowForm={setShowForm}
                  />
                }
              </>
            );
          })}
      </div>
      {
        learnMore && learnMore.scholarships && learnMore.scholarships.length && (
          <div className="container multiple-other-schools">
            <div
              style={{
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.16)",
                width: "100%",
                height: "auto",
                backgroundColor: "white",
                padding: "2rem",
                marginBottom: "2rem",
                textAlign: "center",
              }}
            >
              <h3 style={{ marginBottom: 0 }}>
                <span style={{ color: "#F26527" }}>20-30%</span>{" "}
                <span>scholarship available for</span>{" "}
                <span style={{ color: "#51B74B" }}>Nigerian Students</span>{" "}
              </h3>
            </div>
            {/* <h3>Scholarship details for Nigerian students</h3>
                    {
                        learnMore.scholarships.map(item => {
                            return (
                                <div style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.16)', width: '100%', height: 'auto', backgroundColor: 'white', padding: '2rem', marginBottom: '2rem' }}>
                                    <h5>{item}</h5>
                                </div>
                            )
                        })
                    } */}
          </div>
        )
      }
      {
        learnMore && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "3rem",
            }}
          >
            {/* <a href={brochure} download={`${name}-brochure"`}>
              <Button label="Download Brochure" size="sm" variant="secondary" />
            </a> */}
            { !(isRIT && name.includes("New York")) && // RIT New york has no brochure - remove button
              <a href={parterned ? partnerBrochure : brochure} download={`${name}-brochure"`}>
                <Button label="Download Brochure" size="sm" variant="secondary" />
              </a>
            }
          </div>
        )
      }
      {learnMore &&
        learnMore.apply && 
        learnMore.applyImage &&
        parterned && (
          <div className="row" style={{ margin: '0', padding: '0' }}>
            <div className="col-md-6 px-0 align-items-center">
              <img src={learnMore.applyImage} alt="hult apply" className="hult-apply" />
            </div>
            <div className="col-md-6 align-items-center hult-apply-steps">
              <h3> How to apply? </h3>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {learnMore.apply.map((item, i) => (
                  <div
                    className="col-lg-12 row align-items-center"
                    style={{ margin: "5px 0" }}
                    key={i}
                  >
                    <div className="col-1 pl-0">
                      <img src={item.index} alt="number" style={{ width: "30px" }} />
                    </div>
                    <div className="col-10 ml-1">
                      {item.step}
                    </div>
                  </div>))}
              </div>
            </div>
          </div>
        )}
      <div className="bg-blue-secondary">
        {learnMore &&
          learnMore.alumni &&
          parterned && (
            <AlumniNetwork alumni={learnMore.alumni} />
          )}
      </div>
      {
        learnMore && learnMore.statistics && (
          <div className="container multiple-other-schools">
            <h3>Employability Statistics</h3>
            <EmployabilityStatistics statsSlides={learnMore.statistics} />
          </div>
        )
      }

      {
        learnMore && !parterned && !semiparterned && (
          <div
            className="multiple-institution-payment-banner"
            style={{ textAlign: "left" }}
          >
            <div className="container">
              <h3 style={{ marginBottom: "3rem" }}>Mode of Payment</h3>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {learnMore.modeOfPayment.map((item, i) => (
                  <div
                    className="col-lg-4"
                    style={{ marginBottom: "2rem" }}
                    key={i}
                  >
                    <PaymentMode payment={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
      {
        learnMore && learnMore.alumni && learnMore.alumni.individuals.length ? (
          <div className="bg-blue-secondary multiple-other-schools  why-study-with-us-container">
            <h3>Acclaimed Manipal Alumni</h3>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {learnMore.alumni.individuals.map((item, i) => (
                <>
                  <div key={i} className="col-lg-4" style={{ marginBottom: "2rem" }}>
                    <div className="multiple-alumni-images">
                      <img src={item.image} alt={item.name} />
                      <div className="info">
                        <h5>{item.name}</h5>
                        <p>{item.post}</p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <AlumniNetwork alumni={learnMore.alumni} />
          </div>
        ) : (
          <></>
        )
      }
      {
        learnMore && learnMore.internships && learnMore.internships.length && (
          <div className="container multiple-other-schools">
            <h3>Placement & Internships</h3>
            <div className="image-box">
              {learnMore.internships.map((img, i) => (
                <img key={i} src={img} alt="accreditation" />
              ))}
            </div>
          </div>
        )
      }
      {
        learnMore && !semiparterned && learnMore.accreditations && (
          <div className="container multiple-other-schools">
            <h3>Accreditation</h3>
            <div className="image-box">
              {learnMore.accreditations.map((img, i) => (
                <img key={i} src={img} alt="accreditation" />
              ))}
            </div>
          </div>
        )
      }
      <div
        className={
          learnMore
            ? "container multiple-other-schools"
            : "bg-blue-secondary multiple-other-schools why-study-with-us-container"
        }
      >
        <h3>Other Schools</h3>
        <InstitutionsComponent schoolId={id} />
      </div>
      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-1"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836780080557"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836780080557"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-2"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836377964570"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836377964570"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>

      <Modal
        style={{ zIndex: 2000 }}
        show={showForm === "jotform-3"}
        size={"lg"}
        onHide={() => setShowForm(null)}
        className="modal-open"
        centered
      >
        <>
          <div>
            <button
              type="button"
              className="close text-align-right jot-form-modal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowForm(null)}>
                &times;
              </span>
            </button>
          </div>

          <div className="embed-responsive embed-responsive-1by1">
            <iframe
              id="JotFormIFrame-212836592062558"
              title="Course Registration Form"
              onLoad={window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/212836592062558"
              frameBorder="0"
              style={{
                minWidth: "100%",
                height: "100%",
                border: "none",
              }}
              scrolling="yes"
            >
              Loading...
            </iframe>
          </div>
        </>
      </Modal>
    </>
  );
};

export default PartnerInstitutionProgram;
