import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { RiSubtractFill, RiAddFill, RiVideoFill } from 'react-icons/ri'
import Button from "../components/Button";
import Collapsed from "../components/Collapsed";

const Divider = () => <span style={{ fontWeight: 600, fontSize: '14px', margin: '0px 1rem' }}>|</span>
const ProgramGroupCollapsibleCard = ({ program, firstJotForm, secondJotForm, noJotForm, link, setShowForm }) => {
    const [showAllPrograms, setShowAllPrograms] = React.useState(false)
    return (
        <div className="program-individual-card">
            <div className="flex">
                <h5 style={{ fontWeight: '600' }}>{program.title}</h5>
                {noJotForm ? <a href={link} target="_blank" rel="noreferrer">
                    <Button
                        label="Apply"
                        variant="primary"
                        size="sm"
                        style={{ padding: '5px 40px' }}
                    />
                </a> :
                    <Button
                        label="Apply"
                        variant="primary"
                        size="sm"
                        style={{ padding: '5px 40px' }}
                        onClick={() => {
                            if (firstJotForm) {
                                setShowForm("jotform-1");
                            } else if (secondJotForm) {
                                setShowForm("jotform-2");
                            } else {
                                setShowForm("jotform-3");
                            }
                        }}
                    />}
            </div>
            <div className="program-fees-box">
                {/* <div>
                    <span>Cost:</span>{" "}
                    <span style={{ color: '#F26527', fontWeight: 700 }}>{program.fee.cost}</span>{" "}
                    {program.fee.oldAmount && <span style={{ textDecoration: 'line-through' }}>{program.fee.oldAmount}</span>}
                </div>
                <Divider /> */}
                <div>
                    <span>Duration: </span> {" "}
                    {/* <span style={{ color: '#51B74B', fontWeight: 700 }}>{program.fee.installments} installments</span>{" "} */}
                    <span style={{ fontWeight: 700 }}>over {program.fee.durationPeriod}</span>
                </div>
                <Divider />
                <div>
                    <span>Mode of learning: </span> {" "}
                    <span style={{ fontWeight: 600 }}>{program.fee.mode}</span>
                </div>
            </div>
            {program.aboutProgramme.map(info => <p style={{ marginTop: '20px' }}>
                {info}
            </p>)}
            <h5 className="sub-title">{program.semititle}</h5>
            <div className="flex" style={{ marginBottom: '1rem' }}>
                {program.totalDuration}
                <div className="color-primary" style={{ cursor: 'pointer' }} onClick={() => setShowAllPrograms(!showAllPrograms)}>
                    {showAllPrograms ? 'Collapse' : 'Expand'} all sections
                </div>
            </div>

            {program.programCollapsible.map((course, i) => {
                return (
                    <>
                        {
                            <Collapsed
                                key={i}
                                courseGroup={course}
                                showAllPrograms={showAllPrograms}
                            />
                        }
                    </>
                );
            })}
        </div>
    );
};

export default ProgramGroupCollapsibleCard;
