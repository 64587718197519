import blogOne from "../assets/images/blog-one-small.jpg";
import blogOneFull from "../assets/images/blog-one.png";
import blogTwo from "../assets/images/blog-two-small.jpg";
import blogTwoFull from "../assets/images/blog-two-full.png";
import blogThree from "../assets/images/blog-three-small.png";
import blogThreeFull from "../assets/images/blog-three-full.png";
import blogFour from "../assets/images/blog-four-small.jpg";
import blogFourFull from "../assets/images/blog-four-full.png";
import blogFive from "../assets/images/blog-five-small.jpeg";
import blogFiveFull from "../assets/images/blog-five-full.png";
import blogSix from "../assets/images/blog-six-small.jpg";
import blogSixFull from "../assets/images/blog-six-full.png";
import blogSeven from "../assets/images/blog-seven-small.png";
import blogSevenFull from "../assets/images/blog-seven-full.png";
import blogEight from "../assets/images/blog-eight-small.png";
import blogEightFull from "../assets/images/blog-eight-full.png";
export const blogs = [
  {
    id: 1,
    image: blogOne,
    fullImage: blogOneFull,
    title: "Advance Your Career with Professional Certificates on VigiLearn",
    date: "Sept 03, 2021",
    content:
      "When it comes to learning online, there are loads of choices. However, as a professional and with hectic work and life schedules finding one that speaks your language and which you can take at your pace can be overwhelming. Vigilearn is here to help.",
  },
  {
    id: 2,
    image: blogTwo,
    fullImage: blogTwoFull,
    title: "Get a Nursing Degree without jamb on Vigilearn",
    date: "Sept 03, 2021",
    content:
      "Nurses are in high demand. Not just locally but also across the globe. However, for many students, scaling through Joint Admissions and Matriculation Board (JAMB) exams and getting the much-needed admission is a whole different hassle. How then can students pursue their dreams of getting a bachelor’s degree in Nursing without the accompanying admissions stress?",
  },
  {
    id: 3,
    image: blogThree,
    fullImage: blogThreeFull,
    title:
      "Why Soft Skills Are Important in Continuing Professional Development.",
    date: "Sept 03, 2021",
    content:
      "Let’s talk Continuing Professional Development. Today’s world of work is ever changing, demanding more fluid, and in control professionals. For many professionals, while school may have stopped, class is always in session. This means consistent learning even on the job. Now more than ever, where hard skills may get many professionals started, there is a need to sharpen soft skills as these are what takes many through the glass ceiling to the C-suite.",
  },
  {
    id: 4,
    image: blogFour,
    fullImage: blogFourFull,
    title:
      "Power your Future with a Master's Degree in Public Health - Entirely Online",
    date: "Sept 03, 2021",
    content:
      "In a post covid world, and with the rising global health crisis, there is a more global call for medical doctors and health professionals who understand and can manage public health. As such, a Master of Public Health (MPH) degree is one of the most sought-after programs in many countries. And for many looking to activate their relocation plans, this is one key must-have.",
  },
  {
    id: 5,
    image: blogFive,
    fullImage: blogFiveFull,
    title: "Why You Should Get a Master Degree on VigiLearn",
    date: "Sept 03, 2021",
    content:
      "Many are often faced with the million-dollar question after completing a Bachelors program; Should I get a Master degree or should I dive into the workplace immediately? If you are in this situation, you should know that while getting a job after school has its perks, obtaining a Master degree exposes you to opportunities that can generally improve your quality of life. Again, while acquiring a master degree may feel very rewarding, it can be very strenuous for working professionals. This is why VigiLearn is offering a way for professionals to pursue a master’s degree while working",
  },
  {
    id: 6,
    image: blogSix,
    fullImage: blogSixFull,
    title: "A great time to advance your career with a degree in Accounting",
    date: "Sept 03, 2021",
    content:
      "Today’s world is big on numbers. From school arithmetic and market runs to financial statements from huge corporations, numbers are a huge part of everyday life. Yet, there are people who have a special love for numbers and money. Are you one of those? Do you perceive numbers differently? Are you detailed and meticulous? If you answered yes to all these questions, we think you should consider taking steps to build a career in Accounting via VigiLearn. ",
  },
  {
    id: 7,
    image: blogSeven,
    fullImage: blogSevenFull,
    title:
      "Apply for a Masters in International Affairs and Diplomacy on VigiLearn. ",
    date: "Oct 13, 2021",
    content:
      "Politics is global. From social events in East London to political happenings in Nigeria, the world is looking for ambitious individuals to make a positive impact. A masters in International Affairs and Diplomacy provides a great opportunity to navigate global issues and impact.",
  },
  {
    id: 8,
    image: blogEight,
    fullImage: blogEightFull,
    title:
      "Learn the skills you need to excel in the growing career of Data Science  ",
    date: "Oct 13, 2021",
    content:
      "In 2022, will data science still be a rising career? The answer is a resounding Yes. Globally, the need for data scientists is increasing, but supply is significantly low.",
  },
];
