import React from "react";

const Impact = () => {
  return (
    <div className="">
      <div className="home-impact  d-none d-md-block">
        <div className="row m-0 ">
          <div className="col-xs-12 col-md-8 col-lg-6">
            <div className="home-impact-text" data-aos="zoom-in-right">
              <h1 className="dark-text">
                <span className="green-text">Impact</span> Made In{" "}
                <span className="orange-text">Africa</span> So Far
              </h1>
              <p>
                Through the deployment of our custom platforms in partnership
                with progressive global Institutions, we have been able to power
                more than 20 programs &amp; courses, which has led to more than
                20,000 enrolments. In addition, more than 6,000 students have
                graduated from these programs and in excess of $10 million has
                been raised with our partners.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-xs-block d-md-none">
        <div className="home-impact-image"></div>
        <div className="bg-blue-primary mobile-impact-details">
          <h1>
            <span className="green-text">Impact</span> Made In{" "}
            <span className="orange-text">Africa</span> So Far
          </h1>
          <p>
            Through the deployment of our custom platforms in partnership with
            progressive global Institutions, we have been able to power more
            than 20 programs &amp; courses, which has led to more than 20,000
            enrolments. In addition, more than 6,000 students have graduated
            from these programs and in excess of $10 million has been raised
            with our partners.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Impact;
