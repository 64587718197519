import React from "react";
import "react-multi-carousel/lib/styles.css";
import "../assets/styles/pageStyles/referral.scss";
import { Button } from "react-bootstrap";
import {BsArrowUpRight} from "react-icons/bs";
import ritNewyork from "../assets/images/ritNewyork.png";
import ritDubai from "../assets/images/rit--logo.png";
import babcockLogo from "../assets/images/babcock-logo.png";
import miu from "../assets/images/ManipalIULogo.png";
import manipalGNU from "../assets/images/ManipalGlobalNxtLogo.png";
import hultLogo from "../assets/images/hult-logo.png";
import manipalMAHE from "../assets/images/ManipalAHELogo.png";
import abuLogo from "../assets/images/abu-logo.png";
import ixLogo from "../assets/images/iXperience-logo.png";

const Referral = () => {
  const Card = ({logo, title, price, link, isNaira}) => {
    return (
      <div className="card_center col-sm-6 col-md-4 col-lg-3">
        <div className="card_container">
          <div style={{padding : "10px 0px"}}>
            <img style={{height : "50px", width : "auto", maxWidth : "80%", minWidth : "40%"}} src={logo} alt="logo" />
          </div>
          <span className="card_title">
            {title}
          </span>
          <span className="card_content">
            Refer a friend and earn <span className="content_price">{!isNaira && '$'}<span style={{display : isNaira ? "inline-block" : "none"}}>&#x20A6;</span>{price}</span> when they become a student of {title}
          </span>
          <a href={link} target="_blank" rel="noopener noreferrer" >
          <Button style={{width : "fit-content", bottom : '40px', position : "absolute"}}>
            Register Here
            <span style={{paddingLeft : "10px"}}><BsArrowUpRight/></span>
          </Button>
          </a>
        </div>
      </div>
    )
  }

  const cardDetails = [
    {
      title : "Manipal International University",
      price : "400",
      link : "https://miu.vigilearnapply.com/referral/register",
      logo : miu,
    },
    {
      title : "Manipal GlobalNXT University",
      price : "100",
      link : "https://gnu.vigilearnapply.com/referral/register",
      logo : manipalGNU
    },
    {
      title : "Hult International Business School",
      price : "400",
      link : "https://hult.vigilearnapply.com/referral/register",
      logo : hultLogo
    },
    {
      title : "Manipal Academy of Higher Education",
      price : "200",
      link : "https://mahe.vigilearnapply.com/referral/register",
      logo : manipalMAHE
    },
    {
      title : "Ahmadu Bello University",
      price : "5000",
      isNaira : true,
      link : "https://apply.abudlc.edu.ng/referrals/new",
      logo : abuLogo
    },
    {
      title : "iXperience",
      price : "20",
      link : "https://ixperience.vigilearnapply.com/referral/register",
      logo : ixLogo
    },
    {
      title : "Babcock University",
      price : "5000",
      isNaira : true,
      link : "https://applycodel.babcock.edu.ng",
      logo : babcockLogo,
    },
    {
      title : "Rochester Institute of Technology, Dubai",
      price : "200",
      link : "https://rit.vigilearnapply.com/referral/register",
      logo : ritDubai,
    },
    {
      title : "Rochester Institute of Technology, New York",
      price : "200",
      link : "https://ritnewyork.vigilearnapply.com/referral/register",
      logo : ritNewyork,
    }
  ]

  return (
    <div>
        <div className="introduction bg-blue-primary">
            <div className="container about-intro-text">
              <div className="text-center">
                <h2>Refer and Earn</h2>
                <p className="detail-text">
                  Refer your family and friends who are looking to study at any of our 
                  listed institutions and earn some cash when they pay their tuition. 
                  Generate your unique referral code below.
                </p>
              </div>
            </div>

            <div className="container about_points">
              <div className="col-md-4 about_details">
                <h4>Generate Unique Referral Link</h4>
                <p>A unique referral link will be generated on each institution's page, that will be linked to your account.</p>
              </div>
              <div className="col-md-4 about_details">
                <h4>Share Unique Referral Link To Friends and Family</h4>
                <p>Share to all your loved ones and anyone else who might need this amazing opportunity.</p>
              </div>
              <div className="col-md-4 about_details">
                <h4>Get Rewarded After They Make Their Tuition Payment</h4>
                <p>As soon as any of your referrals make a tuition payment, you will be instantly rewarded.</p>
              </div>
            </div>
        </div>

        <div className="card_group_container col-md-12">
          {
            cardDetails.map((data, index) => {
              return <Card {...data} key={index} />
            })
          }
        </div>      
    </div>
  );
};

export default Referral;
