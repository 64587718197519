import React from "react";
import { Link } from "react-router-dom";

const Power = () => {
  const skills = [
    {
      title: "Boycott the traditional way of Learning and learn Online",
      content:
        "Embrace the flexibility of our easy-to-use platform from anywhere and wherever you are.",
    },
    {
      title: "You'll never have to worry about high fees",
      content:
        "With flexibility in payment, you can pay for courses per need as well as access various education loans and repayment options through our partner, Educollect.",
    },
    {
      title: "Responsibility",
      content:
        "Together with our prestigious partners, we are dedicated to ensuring the integrity and quality of tertiary education in Africa. In addition, we strive to develop top-notch students who can compete globally.",
    },
  ];
  return (
    <>
      <p>
        In a post covid world, and with the rising global health crisis, there
        is a more global call for medical doctors and health professionals who
        understand and can manage public health. As such, a Master of Public
        Health (MPH) degree is one of the most sought-after programs in many
        countries. And for many looking to activate their relocation plans, this
        is one key must-have.
      </p>
      <p className="mt-4">
        This Masters of Public Health (MPH) degree course is entirely online,
        (except for periodic examinations which students take offline) and
        focuses on the entire breadth of public health, which means health
        professionals interested in this course can keep working and studying
        without losing momentum. This Master in Public Health (MPH) comes with 3
        Specializations: MPH - Epidemiology, MPH - Environmental and
        Occupational Health, MPH - Health Promotion and Prevention
      </p>
      <p className="mt-4">
        On Vigilearn and in partnership with Ahmadu Bello University (ABU),
        public health professionals like pharmacists and microbiologists can now
        earn a Masters degree in Public Health which can be a step closer to
        their future in global healthcare. This program covers topics on public
        health skills, knowledge, and principles necessary in the public health
        sector today, leadership skills required for health specializations, and
        enhancing populations' health and wellbeing.
      </p>
      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">
        Why Should You Earn a Public Health Degree on Vigilearn
      </h4>

      <ul className="mt-4">
        {skills.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>
      {/* <p className="mt-5">
        Ready to take that leap towards your future in Public Health?
        Applications are currently ongoing, Click <Link to="/">here</Link>
      </p> */}
      <p className="mt-5">
        Not interested in Public Health? Other undergraduate, postgraduate, and
        professional courses are also available on Vigilearn. Click{" "}
        <Link to="/programs">here</Link> to learn more.
      </p>
    </>
  );
};

export default Power;
