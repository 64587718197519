import React from "react";

const SoftSkills = () => {
  const skills = [
    {
      title: "Communication",
      content:
        "The ability to express thoughts and ideas clearly and effectively through verbal and nonverbal means such as words, body language, gestures, facial expressions, and eye contact.",
    },
    {
      title: "Time Management",
      content:
        "This involves being able to prioritise work and manage self and teams so that nothing interferes with other activities or gets in the way of accomplishing goals.",
    },
    {
      title: "Responsibility",
      content:
        "This involves showing self-discipline and taking personal responsibility for your own actions and decisions.",
    },
    {
      title: "Self-Awareness",
      content:
        "This involves showing self-discipline and taking personal responsibility for your own actions and decisions.",
    },
    {
      title: "Critical Thinking",
      content:
        "his includes the ability to reason effectively and analyse information with the aim of problem-solving.",
    },
    {
      title: "Creativity",
      content:
        "This involves learning to think creatively and finding innovative ways to solve tasks.",
    },
  ];
  return (
    <>
      <p>
        Let’s talk Continuing Professional Development. Today’s world of work is
        ever changing, demanding more fluid, and in control professionals. For
        many professionals, while school may have stopped, class is always in
        session. This means consistent learning even on the job. Now more than
        ever, where hard skills may get many professionals started, there is a
        need to sharpen soft skills as these are what takes many through the
        glass ceiling to the C-suite.
      </p>
      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">What are soft skills? </h4>
      <p className="mt-4">
        Soft skills, also called emotional intelligence (EI), refer to
        transferable skills, traits and personal attributes that help you work
        and interact with other people more effectively. According to a{" "}
        <a
          href="https://www.linkedin.com/business/learning/blog/top-skills-and-courses/the-skills-companies-need-most-in-2019-and-how-to-learn-them"
          target="_blank"
          rel="noreferrer"
        >
          survey by LinkedIn
        </a>
        , 57% of senior executives in the workplace thought that soft skills
        were more important than hard skills due to their versatility. Little
        wonder that these soft skills are the most sought after by employers and
        organizations in today’s employment pool. As a professional, building
        these soft skills will go a long way in helping you stand out from your
        peers as well as inform career growth. Some of these soft skills
        include:{" "}
      </p>
      <ul className="mt-4">
        {skills.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>
      <p className="mt-5">
        Recognizing these skill gaps and seeking solutions to bridge the gaps
        are what make soft skills even more important in today’s workplace. Are
        you a professional thinking of ways to develop your soft skills? Take a
        soft skills course online with VigiLearn. This course is
        offered in partnership with the MENA College of Management, Dubai and is
        a five-week blended programme where you will learn communication,
        cultural diversity, perception, barriers to effective listening,
        dynamics of interpersonal communication and conflict resolution for
        effective management. Students are given a certificate upon completion.
      </p>
      <p className="mt-4">
      Click here <a href="https://bit.ly/3jigPoJ" target="_blank" rel="noreferrer">https://bit.ly/3jigPoJ</a> to get started and update your professional skills. 
      </p>
    </>
  );
};

export default SoftSkills;
