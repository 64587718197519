import React from "react";
// import quoteImg from "../assets/images/quote.svg";
// import { BsFillPersonFill } from "react-icons/bs";
import PropTypes from "prop-types";
import "../assets/styles/componentStyles/testimonial-card.scss";

const TestimonialCard = ({ quote, author, title, image }) => {
  return (
    <div className="testimonial">
      {image && (
        <div className="testimoniali-img " style={{ width: "70%" }}>
          <img src={image} alt={author} style={{ width: "100%" }} />
        </div>
      )}

      <div className="testimonial-card">
        {/* <img src={quoteImg} alt="" className="img-fluid" /> */}
        <h1 className="dark-text pr-5">
          What People say <span className="green-text">about</span>{" "}
          <span className="orange-text">us</span>
        </h1>
        <p className="quote-text">{quote}</p>

        {/* <div className="mt-4 testimonial-author-section d-flex align-items-center">
          <div className="person-icon-wrapper d-flex justify-content-center align-items-center">
            <BsFillPersonFill size="24px" color="#FFFFFF" />
          </div>{" "}
          &nbsp; &nbsp;
          <div>
            <span className="author">{author}</span> <br />
            <span className="title">{title}</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

TestimonialCard.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TestimonialCard;
