import React from "react";
import { useHistory, useParams } from "react-router";
import Button from "../components/Button";

import { institutions } from "../data/instututionsAndCourses";

const InstitutionsComponent = ({otherSchools, schoolId}) => {
  const history = useHistory();

  const { id } = useParams();

  const otherInstitutionsList = institutions.filter((institution) => 
  institution.id !== Number(id) && !institution.parentInstitution)

  const list = id ? otherInstitutionsList.slice(0, 3) : institutions

  return (
    <div className="row">
      {list.map((institution, index) => (
          <div className="col-md-6 col-lg-4" key={index}>
            <div className={`box ${id ? "box--bordered" : ""}`}>
              <div className="content">
                <img
                  src={institution.logo}
                  alt={institution.name}
                  style={{ width: institution.id >= 5  ? "15rem" : "" }}
                />
              </div>
              <div className="content-hover text-center">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h4>{institution.name}</h4>
                  <Button
                    label="View Courses"
                    size="sm"
                    onClick={() => {
                      if(otherSchools) {
                        history.push({
                          pathname: `/partner/institutions/${id}/program`,
                          state: {
                              program: institution
                          }
                      })
                      }else if (institution.otherProgram) {
                        history.push({
                          pathname: `/partner/institutions/${institution.id}/program`,
                        })
                      } else {
                        history.push({
                          pathname: `/partner/${institution.id}`,
                        })
                      }
                    }}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default InstitutionsComponent;
