import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import goalImg from "../assets/images/about-goal.png";
import aboutOne from "../assets/images/about-image-one.png";
import aboutTwo from "../assets/images/about-image-two.png";
import aboutThree from "../assets/images/about-image-three.png";
import "../assets/styles/pageStyles/about.scss";

const AboutUs = () => {
  const focusItems = [
    {
      title: "Online Programs Logistics",
      body: "We provide all the vital logistical components for any online program, including comprehensive student support services from student enrollment through to graduation and beyond as well as practical learning experiences within distant communities around the continent.",
    },
    {
      title: "Observation and Analysis",
      body: "We carry out extensive research, observation and analysis of tertiary educational systems with respect to the streamlining and improvement in delivery of learning content to students using state of the art Web 2.0 nology as it intersects with the unique challenges of the continent.",
    },
    {
      title: "Creating Access to Learning",
      body: "We work to increase student access to degree granting institutions whilst mitigating the current constraints that exist for working adults within the African context. We partner with some of the best institutions on the continent to ensure no student is left behind in the quest to educate Africa.",
    },
    {
      title: "Upholding Integrity & Quality",
      body: "We are very committed to working with our partners to uphold the quality of learning and integrity of the tertiary educational system in Africa, thereby encouraging the production of world class students that can stand brow to brow with their international counterparts.",
    },
  ];

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 1,
      slidesToSlide: 2,
      partialVisibilityGutter: 200,
    },

    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
      slidesToSlide: 2,
    },
  };

  const aboutImages = [aboutOne, aboutTwo, aboutThree];

  return (
    <div>
      <div className="introduction bg-blue-primary">
        <div className="container about-intro-text">
          <div className="text-center">
            <h2>About Us</h2>
            <p className="detail-text">
              VigiLearn is an end-to-end suite of products designed to provide a
              faster and more efficient way of managing administrative and
              academic related processes like payments, virtual classroom,
              examination, e-library, and many more.
            </p>
          </div>
        </div>
      </div>
      <div className="our-company bg-blue-secondary">
        <div className=" container">
        <h2>Our Company</h2>
        <p>
          We started our unique journey by helping African traditional
          universities take their on-campus degree programs online through the
          implementation of the very best of technology, process management,
          admissions and advisory. Through VigiLearn, we offer a host of
          features and functionalities that cater to academic institutions who
          are looking to leverage technology to provide learning to students,
          corporates who are either looking to provide access to learning to
          their staff or for profit, and the everyday individual who wants to
          learn something new.{" "}
        </p>
      </div>
      </div>
      <div className="container">
        <div className="d-none d-lg-block">
          <div
            className="row about-banner-images"
           
          >
            {aboutImages.map((image, index) => (
              <div className="col-lg-4" key={index}>
                <div className="about-banner-image">
                  <img src={image} alt="vigilearn-team" width="100%" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="d-block d-lg-none about-banner-carousel"
        >
          <Carousel
            responsive={responsive}
            arrows={false}
            partialVisible={true}
          >
            {aboutImages.map((image, index) => (
              <div className="banner-carousel-image" key={index}>
                <img src={image} alt="vigilearn-team" width="90%" />
              </div>
            ))}
          </Carousel>
        </div>
      </div>


      <div className="our-goal">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <div className="goal-image-container">
                <img src={goalImg} alt="Goal" width="100%" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="goal-text">
                <h3>Our Goal</h3>
                <p>
                  Our goal is to provide a better learning experience for
                  individuals and drive effective management of all related
                  processes to significantly improve learning delivery for
                  institutions. This effort aims to strike a balance between the
                  demand for quality education and the supply that exists
                  globally, but with a core focus on the African continent, by
                  opening up access to top-quality education to all.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="focus-section bg-blue-tetiary">
        <div className="container">
          <div className=" focus-about-intro-text mb-4">
            <div className="text-center ">
              <h2 className="light-blue-text">Our Focus</h2>
              <p className="detail-text">
                We are focused on combining the best technology with the best
                user experience to deliver a robust solution to the problem of
                access to quality education on the African continent and beyond
              </p>
            </div>
          </div>
          <div className="focus-items-container">
            {focusItems.map(({ title, body }, key) => (
              <div className="focus-item" key={key}>
                <h4 className="">{title}</h4>
                <p>{body}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
