import React from "react";
import playImage from "../../assets/images/play-image.png";
import TestimonialCard from "../../components/TestimonialCard";

const Testimonails = ({ setPopUpOpen, open }) => {
  const data = [
    {
      quote:
        "By taking advantage of the VigiLearn platform, individuals of different backgrounds have gotten one step closer to actualizing their dreams.  Here is what some of them had to say.",

      author: "Mariam Adeyemi",
      title: "OAU B.Sc. Nursing Student",
    },
    {
      quote:
        "Engaging EduTech as consultants is certainly one of the best decisions we have made. This partnership has facilitated our meeting the requirements of our regulators and timely deployment of our Programmes.",
      author: "Prof. A.Z. Hassan",
      title: "Former Director, ABU DLC",
    },
  ];

  return (
    <div className="testimonials bg-blue-secondary">
      <div className="container">
        <div className="row no-gutters testimonial-content flex-row-reverse">
          <div className="col-sm-6 d-flex  align-items-center">
            <div className="testimonial-text">
              <TestimonialCard
                quote={data[0].quote}
                author={data[0].author}
                title={data[0].title}
              />
            </div>
          </div>
          <div className="col-sm-6 ">
            <div className="testimonial-image-container d-flex  align-items-center justify-content-center">
              <div
                className="d-flex align-items-center justify-content-center"
                onClick={() => setPopUpOpen(!open)}
              >
                <img src={playImage} alt="" width="35px" height="40px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonails;
