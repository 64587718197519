import React, { useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { blogs } from "../data/blog";
import { useHistory } from "react-router-dom";
import { BiSearch } from "react-icons/bi";

const BlogsComponent = ({ blogId }) => {
  const history = useHistory();

  const [blogTitle, setBlogTitle] = useState("");

  const newBlogs = blogId
    ? blogs
        .filter((blog) => blog.id !== Number(blogId))
        .splice(blogs[Number(blogId) - 1], 3)
    : blogs;

  return (
    <div className="container ">
      {!blogId && (
        <div className="blog-search d-flex justify-content-end">
          <div
            className="form-group has-feedback"
            style={{ position: "relative" }}
          >
            <input
              type="text"
              className={`form-control search-input-field`}
              name="programName"
              id="programName"
              placeholder="Search Blog"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
            <div className="search-icon">
              <BiSearch fontSize="20px" />
            </div>
          </div>
        </div>
      )}

      <div className="row mt-4">
        {newBlogs
          .filter((blog) =>
            blog.title.toLowerCase().includes(blogTitle.toLowerCase())
          )
          .map(({ id, image, title, date, content }, index) => (
            <div className="col-md-6 col-lg-4" key={index}>
              <div className="blog-card">
                <img src={image} width="100%" height="50px" alt="" />
                <div className="blog-card-content">
                  <p>{date}</p>
                  <h4 onClick={() => history.push(`/blog/${id}`)}>{title}</h4>
                  <div className="blog-card-main">
                    <LinesEllipsis
                      text={content}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BlogsComponent;
