import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { useLocation, Redirect } from "react-router-dom";
import PartnerInstitutionsCard from "../components/partnerInstituionsCard";
import CoursesComponent from "../components/CoursesComponent";
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import InstitutionsComponent from "../components/InstitutionsComponent";
import Button from "../components/Button";

const ProgramGroupCard = ({ programGroup, firstJotForm, secondJotForm, noJotForm, link, showForm, setShowForm }) => {
    const [showPrograms, setShowPrograms] = React.useState(false)
    return (
        <div onClick={() => setShowPrograms(!showPrograms)} style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.16)', width: '100%', height: 'auto', backgroundColor: 'white', padding: '2rem', marginBottom: '2rem', cursor: 'pointer' }}>
            <div style={{ marginBottom: showPrograms ? '2rem' : '0px', display: 'flex', justifyContent: 'space-between' }}>
                <h5>{programGroup.title}</h5>
                <div>
                    {showPrograms ? <RiArrowUpSLine size="20px" /> : <RiArrowDownSLine size="20px" />}
                </div>
            </div>
            {showPrograms && <>
                {programGroup.programGroups.map(j => {
                    return (
                        <div className="program-individual-card">
                            <div className="flex">
                                <h5>{j.title}</h5>
                                {noJotForm ? <a href={link} target="_blank" rel="noreferrer">
                                    <Button
                                        label="Apply"
                                        variant="primary"
                                        size="sm"
                                        style={{ padding: '5px 40px' }}
                                    />
                                </a> : <Button
                                    label="Apply"
                                    variant="primary"
                                    size="sm"
                                    style={{ padding: '5px 40px' }}
                                    onClick={() => {
                                        if (firstJotForm) {
                                            setShowForm("jotform-1");
                                        } else if (secondJotForm) {
                                            setShowForm("jotform-2");
                                        } else {
                                            setShowForm("jotform-3");
                                        }
                                    }}
                                />}

                            </div>
                            {j.programmes && <p style={{ marginTop: '10px' }}>
                                {j.programmes.join(', ')}
                            </p>}
                        </div>
                    )
                })}
            </>}
        </div>
    );
};

export default ProgramGroupCard;
