import React, { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { RiCloseFill } from "react-icons/ri";
import { Formik, Form } from "formik";

import Button from "../../components/Button";

const PartnerForm = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    variant: "",
    msg: "",
  });

  const removeAlert = () => setAlert({ msg: "", variant: "" });

  const makeAlert = (msg, variant) => {
    setLoading(false);
    setAlert({ msg, variant });
    setTimeout(() => removeAlert(), 5000);
  };

  const initialValues = {
    name: "",
    companyName: "",
    email: "",
    phoneNo: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "name",
              })
            : true;
        },
      })
      .min(3, "Minimum of 3 characters")
      .required("Name is empty"),
    companyName: Yup.string()
      .test({
        name: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "companyName",
              })
            : true;
        },
      })
      .min(3, "Minimum of 3 characters")
      .required("Company Name is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),

    phoneNo: Yup.string()

      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .min(9, "Minimum of 9 characters")
      .required("Phone Number is empty"),
    message: Yup.string()
      .min(50, "Minimum of 50 characters")
      .max(300, "Not more than 300 characters")
      .required("Message is empty"),
  });

  const onSubmit = async (
    { name, companyName, phoneNo, email, message },
    resetForm
  ) => {
    setLoading(true);
    try {
      const payload = {
        recipientEmail: "support@edutechng.com",
        name: "Edutech Support",
        message: `Name :   ${name}
                      <br/><br/>
                      Company:   ${companyName}
                      <br/><br/>
                      Phone Number:  ${phoneNo}
                      <br/><br/>
                      Email:   ${email}
                      <br/><br/>
                      Message: ${message}`,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/MailingList/sendmail`,
        payload
      );
      if (data.requestSuccessful === true) {
        resetForm();
        makeAlert("Message sent successfully", "success");
      } else {
        makeAlert("Something went wrong", "error");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container" id="partner-form">
      <div className="row partner-form-container">
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h1 className="mb-4 dark-text">Become a Partner</h1>
            <p className="dark-text">
              VigiLearn allows you enjoy a wealth of features and
              functionalities that simplify every key process along the chain of
              effective education administration
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="partner-form">
            {alert.msg !== "" && (
              <div
                class={`alert alert-${alert.variant} fade show mb-4 d-flex justify-content-between `}
                role="alert"
              >
                {alert.msg}
                <RiCloseFill
                  className="icon"
                  size="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeAlert()}
                />
              </div>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
            >
              {({ errors, touched, getFieldProps, values }) => (
                <Form noValidate autoComplete="off">
                  <div className="row">
                    <div className="col-lg-6 form-group">
                      <label htmlFor="name" className="input-label">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        name="name"
                        {...getFieldProps("name")}
                        id="name"
                        placeholder="Jane Doe"
                      />
                      <small className="text-danger">
                        {errors.name && touched.name && errors.name}
                      </small>
                    </div>
                    <div className="col-lg-6 form-group">
                      <label htmlFor="name" className="input-label">
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        name="companyName"
                        {...getFieldProps("companyName")}
                        id="name"
                        placeholder="FUTA"
                      />
                      <small className="text-danger">
                        {errors.companyName &&
                          touched.companyName &&
                          errors.companyName}
                      </small>
                    </div>
                    <div className="col-lg-6 form-group">
                      <label htmlFor="name" className="input-label">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-control input-field"
                        name="name"
                        {...getFieldProps("phoneNo")}
                        id="phoneNo"
                        placeholder="08038574285"
                      />
                      <small className="text-danger">
                        {errors.phoneNo && touched.phoneNo && errors.phoneNo}
                      </small>
                    </div>
                    <div className="col-lg-6 form-group">
                      <label htmlFor="email" className="input-label">
                        Email Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        size="sm"
                        className="form-control input-field"
                        name="email"
                        {...getFieldProps("email")}
                        id="email"
                        placeholder="janedoe@example.com"
                      />
                      <small className="text-danger">
                        {errors.email && touched.email && errors.email}
                      </small>
                    </div>

                    <div className="col-md-12 form-group">
                      <label htmlFor="message" className="input-label">
                        Request Message <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        className="form-control input-field"
                        name="messagee"
                        {...getFieldProps("message")}
                        id="message"
                        placeholder="Enter message here"
                      />
                      <small className="text-danger d-block">
                        {errors.message && touched.message && errors.message}
                      </small>
                    </div>
                  </div>

                  <div className="mt-4">
                    <Button
                      fullWidth
                      disabled={loading}
                      variant="tetiary"
                      type="submit"
                      label="Send Message"
                      disabledText="Submitting..."
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
