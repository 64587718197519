import React from "react";
import StatisticsCard from "../../components/StatisticsCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Statistics = ({data, transparent, wrap}) => {
  
  const responsive = {
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
      partialVisibilityGutter: 45,
      slidesToSlide: 2
    },
  };

  return (
    <div className={transparent ? "" : "bg-blue-secondary"}>
      <div className="container">
        <div className={wrap ? "homepage-statistics d-sm-block" : "homepage-statistics  d-none d-sm-block"}>
          <div className="row">
            {data.map((stat, index) => (
              <div className="col-md-6 col-lg-3 statistic-column" key={index}>
                <StatisticsCard data={stat} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {!wrap && <div className="d-block d-sm-none homepage-statistics">
        <Carousel responsive={responsive} arrows={false} partialVisible={true}>
          {data.map((stat, index) => (
            <StatisticsCard data={stat} key={index} />
          ))}
        </Carousel>
      </div>}
    </div>
  );
};

export default Statistics;
