import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Button from "../../components/Button";
import DetailCard from "../../components/DetailCard";
import accessIcon from "../../assets/images/access-icon.svg";
import lightIcon from "../../assets/images/light-icon.svg";
import logisticsIcon from "../../assets/images/logistics-icon.svg";
import searchIcon from "../../assets/images/search-icon.svg";

const PartnerWithUs = () => {
  const partnerData = [
    {
      title: "Observation and Analysis",
      content:
        "We carry out extensive research, observation and analysis of tertiary educational systems with respect to the streamlining and improvement in delivery of learning content to students using state of the art Web 2.0 nology as it intersects with the unique challenges of the continent.",
      image: searchIcon,
    },
    {
      title: "Online Programs Logistics",
      content:
        "We provide all the vital logistical components for any online program, including comprehensive student support services from student enrollment through to graduation and beyond as well as practical learning experiences within distant communities around the continent.",
      image: logisticsIcon,
    },

    {
      title: "Creating Access to Learning",
      content:
        "We work to increase student access to degree granting institutions whilst mitigating the current constraints that exist for working adults within the African context. We partner with some of the best institutions on the continent to ensure no student is left behind in the quest to educate Africa.",
      image: accessIcon,
    },
    {
      title: "Upholding Integrity & Quality",
      content:
        "We are very committed to working with our partners to uphold the quality of learning and integrity of the tertiary educational system in Africa, thereby encouraging the production of world class students that can stand brow to brow with their international counterparts.",
      image: lightIcon,
    },
  ];

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 55,
    },
  };

  return (
    <div className="home-partner-with-us container">
      <div className="row">
        <div className="col-xs-12 col-lg-4">
          <div className="d-none d-lg-block">
            <div style={{ width: "85%" }} className="partner-cards">
              {partnerData.map((partner, index) => (
                <div className="space-top-1" key={index}>
                  <DetailCard data={partner} />
                </div>
              ))}
            </div>
          </div>
          <div className="d-block d-lg-none space-top-1 space-bottom-2">
            <Carousel
              responsive={responsive}
              arrows={false}
              slidesToSlide={2}
              partialVisible={true}
              showDots={true}
              dotListClass="slider-dots"
            >
              {partnerData.map((data, index) => (
                <div
                  style={{ width: "92%" }}
                  key={index}
                  className="space-bottom-2"
                >
                  <DetailCard data={data} key={index} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div
          className="dark-text col-xs-12 col-lg-8"
          style={{ display: "flex", alignItems: "center", maxHeight: "100%" }}
        >
          <div className="mx-auto home-partner-text">
            <h1>Ready to join the league of world class schools?</h1>
            <p className="space-top-1">
              We are an end-to-end suite of products designed to provide a
              faster and more efficient way of mana
            </p>
            <div className="space-top-1">
              <Link to="/partners">
                <Button label="Partner With Us" size="md" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
