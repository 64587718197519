import React from "react";
import babcockLogo from "../../assets/images/babcock-logo.png";
import menaLogo from "../../assets/images/mena-logo.png";
import oauLogo from "../../assets/images/oau-logo.png";
import abuLogo from "../../assets/images/abu-logo.png";
import iXperienceLogo from "../../assets/images/iXperience-logo.png";
import manipalLogo from "../../assets/images/manipal-logo.png";

const Partners = () => {
  const partnerLogos = [
    abuLogo,
    oauLogo,
    babcockLogo,
    iXperienceLogo,
    menaLogo,
    manipalLogo,
  ];
  return (
    <div className="bg-blue-secondary home-partners-wrapper">
      <div className="container home-partners">
        <h1 className="dark-text text-center">Some of Our Academic Partners</h1>
        <div className="home-partners-logos">
          {partnerLogos.map((partner, index) => (
            <div key={index}>
              <div
                className="partner-logo"
                style={{ backgroundImage: `url(${partner})` }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
