import React from "react";
import { Link } from "react-router-dom";

const NursingDegree = () => {
  const reasons = [
    {
      title: "Earn degrees from Prestigious Universities",
      content:
        "We are dedicated to working with our Prestigious partners to uphold the quality of education and integrity of tertiary education in Africa. We also encourage the development of world class students who can compete with their international counterparts.",
    },
    {
      title: "Learn anywhere, anytime",
      content:
        "Our distance learning courses offer the convenience of learning in your comfort zone while accessing our user-friendly platform wherever and whenever.",
    },
    {
      title: "Learn at your own pace",
      content:
        "You choose how fast you wish to learn, and Vigilearn helps make that happen",
    },
    {
      title: "Flexibility in payment",
      content:
        "Courses can be paid for in instalments and students on Vigilearn have access to various education loans through our partner business, EduTech and repayments are convenient.",
    },
    {
      title:
        "Some of the prestigious universities offering Nursing on our platform include",
      content:
        "Ahmadu Bello University Zaria and Obafemi Awolowo University, Ile-Ife. Students can apply for admission at these universities with their O/A levels and admissions are based on merit.",
    },
  ];

  return (
    <>
      <p>
        Nurses are in high demand. Not just locally but also across the globe.
        However, for many students, scaling through Joint Admissions and
        Matriculation Board (JAMB) exams and getting the much-needed admission
        is a whole different hassle. How then can students pursue their dreams
        of getting a bachelor’s degree in Nursing without the accompanying
        admissions stress?
      </p>
      <p className="mt-4">
        With Vigilearn, students can now earn an advanced nursing degree and
        take the next steps towards their future in healthcare. A Nursing
        programme on Vigilearn prepares students for the multi-faceted demands
        of changing healthcare landscape through an innovative, online
        curriculum. Our partner universities (Obafemi Awolowo University and
        Ahmadu Bello University) are committed to meeting the increase in demand
        in healthcare needs by offering an online option for earning your
        Bachelor of Nursing Science (BNSc.).
      </p>
      <p>
        At Vigilearn, we understand the challenges students face when trying to
        get university admission, especially into a school of Nursing. We also
        understand so well the disappointment felt when students cannot pursue
        their dream course and achieve their life goals. This is why Vigilearn
        has partnered with top Universities locally and abroad to provide
        students access to various higher education degrees from prestigious
        universities around the world. Courses available on Vigilearn include
        undergraduate, postgraduate and professional diploma certificates.
      </p>
      <p>
        That way, students can access quality education regardless of age,
        social class, location or gender.
      </p>
      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">
        Five Reasons Why You Should Consider a Nursing degree using Vigilearn
      </h4>
      <ul>
        {reasons.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>
      <p className="mt-5">
        To learn more about our Nursing programs, click{" "}
        <Link to="/programs">here</Link> and you are well on your way to
        fulfilling your dreams.
      </p>
      <p className="mt-4">
        Not Interested in Nursing? There are other undergraduate, postgraduate
        and professional courses also available on Vigilearn. For professionals
        who want to learn more about occupational safety, you can earn your
        Security, Health, and Safety certification from the MENA College of
        Management in just five weeks. To learn more, please click{" "}
        <Link to="/partner/4">here</Link> .
      </p>
    </>
  );
};

export default NursingDegree;
