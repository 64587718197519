import React from "react";
import "../assets/styles/componentStyles/opening.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Openings = ({ openings }) => {

    const responsive = {
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 2,
            partialVisibilityGutter: 45,
            slidesToSlide: 2
        },
    };

    return (
        <>
            <div className="row opening-root">
                <div className="col-lg-6 card">
                    <p className="mx-autp">
                        {openings.info}
                    </p>
                </div>
                <div className="col-lg-6 row px-auto mx-auto">
                    <div className="col-lg-12">
                        <h6 className="title"> RIT Dubai offers </h6>
                    </div>
                    {openings.offerings.map((offering, index) => {
                        return (
                            <div className="col-6 start">
                                <div className="">
                                    <img src={offering.image} alt="offering" className="mb-2"/>
                                    <h6> {offering.title} </h6>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default Openings;
