import React from "react";
import BlogsComponent from "../components/BlogsComponent";
import "../assets/styles/pageStyles/blog.scss";

const Blogs = () => {
  return (
    <div className="blogs-container ">
      <div className="blogs-header bg-blue-secondary all-blogs-header">
        <h2 className="text-center dark-text" data-aos="fade">
          Our Blog
        </h2>
      </div>
      <div className="blog-content-wrapper">
        <BlogsComponent />
      </div>
    </div>
  );
};

export default Blogs;
