import React from "react";

const DataScience = () => {
  const reasons = [
    {
      title: "In-demand skills",
      content:
        "Gain experience with an in-demand skill from an industry expert with years of work experience.",
    },
    {
      title: "Flexible time",
      content:
        "Learn by watching high quality videos at your own schedule at the comfort of your space",
    },
    {
      title: "High-touch support",
      content:
        "Weekly live support sessions led by a Course Mentor, working in that industry, to help answer any questions you have and provide additional guidance.",
    },
    {
      title: "Real-world project",
      content:
        "Throughout the course, you will complete a real-world project that you can add to your portfolio and/or resume.",
    },
    {
      title: "Certificate of completion",
      content:
        "A Certificate of Completion is awarded upon completing the videos and the project. Attending Mentor sessions and doing outstanding work on the project will also earn you a Certificate of Merit, as well as a LinkedIn recommendation from your Mentor.",
    },
  ];
  return (
    <>
      <p>
        In 2022, will data science still be a rising career? The answer is a
        resounding Yes. Globally, the need for data scientists is increasing,
        but supply is significantly low. As a result of the lack of competition
        in the field, it makes sense to become skilled in Data science,
        especially since the field includes related positions such as research
        engineers and machine learning engineers.
      </p>

      <p className="mt-4">
        You can fast track your career in data science with the iXperience Flex
        program on VigiLearn , working with high-quality recorded versions of
        our class content at your convenience, along with live support from
        expert instructors on the weekends.
      </p>

      <p className="mt-4">
        Among the many reasons to pursue a Data Science course on Vigilearn,
        there are probably three that stand out the most to you: career growth,
        higher earnings and the ability to work anywhere in the world.
      </p>

      <p className="mt-4">
        Vigilearn, in partnership with IXperience, enables you to kickstart your
        Data Science career with a course that runs over 8 weeks, 2 hours of
        video and 2 hours of homework each week, making a total of 15 hours of
        total video content, and 15 hours of homework. We have new Cohorts
        starting approximately every month, capped at 25-30 students , a 2-hour
        TA-led, live support sessions each weekend for feedback and support and
        Finally a weekend reserved for project assessment and Merit Evaluation
        for your certificate.
      </p>

      <h4 className="mt-5 mb-3 font-weight-bold blog-subtitle">
        Why choose IXperience Flex on Vigilearn?
      </h4>

      <ul className="mt-4">
        {reasons.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>

      <p>
        Is Data Science the career for you? Or would you rather start out as a
        UI/UX professional or take Management Consulting courses? Please visit{" "}
        <a href="https://vigilearn.com/" target="_blank" rel="noreferrer">
          https://vigilearn.com{" "}
        </a>{" "}
        for more information
      </p>

      <p className="mt-5">
        Not interested in a Data Science course? VigiLearn also offers
        undergraduate, postgraduate, and professional courses. To choose a
        program, visit{" "}
        <a href="https://vigilearn.com/" target="_blank" rel="noreferrer">
          https://vigilearn.com{" "}
        </a>{" "}
      </p>
    </>
  );
};

export default DataScience;
