import React from "react";
import { Link } from "react-router-dom";
// import Typewriter from "typewriter-effect";
import Button from "../../components/Button";

import homeHeroImage from "../../assets/images/home-hero.png";

const HomeBanner = () => {
  return (
    <div className="bg-blue-primary home-intro" data-aos="fade-zoom-in">
      <div className="d-flex m-0 home-intro-container">
        <div className=" home-hero-text">
          <h1>
            {/* We Are <span className="green-text">Pushing</span> The Boundaries Of{" "}
            <span className="orange-text">Learning</span> */}
            We Are Pushing The Boundaries Of Learning
            {/* <span className="banner-typewriter">
          <Typewriter
            options={{
              strings: ["Pushing The Boundaries Of Learning", "taking institutions online", "enabling access to quality education"],
              autoStart: true,
              loop: true,
            }}
          />
          </span> */}
          </h1>

          <p>
          We connect students to quality learning through a variety of prestigious institutions worldwide
          </p>

          <div>
            <Link to="/programs">
              <Button label="Pick a course" />
            </Link>
          </div>
        </div>
        <div className="d-none d-lg-block">
          <img
            src={homeHeroImage}
            alt="Home Banner"
            width="100%"
            className="home-hero-image"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
