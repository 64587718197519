import React from "react";
import { Link } from "react-router-dom";

const MastersDegree = () => {
  const reasons = [
    {
      title: "Boost your hiring prospects",
      content:
        "Getting a master degree from an accredited university on VigiLearn will help you enter the job market with a greater sense of competence and mastery in your chosen field. It will also help your resume stand out, increase your chances of landing that job interview as well as give you leverage to negotiate employment terms with prospective employers.",
    },
    {
      title: "Accelerate your career",
      content:
        "While your Bachelor’s degree might have gotten you through the door, a master degree in your field can advance your career, get you to qualify for a promotion, get you a raise in salary and position you for more opportunities within and outside your organisation.",
    },
    {
      title: "Job security",
      content:
        "Do you love your current job and your role in your organisation? Get a master degree on VigiLearn and you could improve your value to the organisation and enjoy job security.",
    },
    {
      title: "Switch careers",
      content:
        "If you are looking to switch careers, something that guarantees better potential for growth, you should consider getting a master degree in any of the programs offered by VigiLearn. A master’s degree via VigiLearn allows for flexible learning experiences and can equip you with the needed skills and knowledge to chart a new career course.",
    },
    {
      title: "Higher earning potential",
      content:
        "Another great benefit of getting a master degree is that it makes you more marketable, thereby increasing your chances of earning more. A master’s degree also proves to your employers that you are a low risk and high yielding employee and can save them on costs associated with employee retention.",
    },
  ];
  return (
    <>
      <p>
        Many are often faced with the million-dollar question after completing a
        Bachelors program; Should I get a Master degree or should I dive into
        the workplace immediately? If you are in this situation, you should know
        that while getting a job after school has its perks, obtaining a Master
        degree exposes you to opportunities that can generally improve your
        quality of life. Again, while acquiring a master degree may feel very
        rewarding, it can be very strenuous for working professionals. This is
        why VigiLearn is offering a way for professionals to pursue a master’s
        degree while working
      </p>
      <p className="mt-4">
        VigiLearn is a platform that facilitates out-of-classroom learning to
        give students and working professionals a chance to achieve their
        educational goals regardless of age, gender or geographical barriers.
        With VigiLearn, individuals gain access to quality education from
        accredited universities, both in Nigeria and abroad at a lesser cost
        compared to getting an education from brick-and-mortar institutions.
      </p>
      <p className="mt-4">
        Still need a little bit of convincing on why you should get a master
        degree with VigiLearn? Below are some pretty good reasons;
      </p>

      <ul className="mt-4">
        {reasons.map(({ title, content }, index) => (
          <li key={index} className="mt-3">
            <span className="font-weight-bold">{title}:</span>{" "}
            <span>{content}</span>
          </li>
        ))}
      </ul>

      <p className="mt-5">
        Thinking of pursuing a master’s degree soon and looking for flexibility
        and convenient learning? Choose VigiLearn. Whether you want to undertake
        your master’s program full time or work while learning, our platform
        allows the luxury of both options as courses are fully online.
      </p>
      <p className="mt-4">
        We offer master’s degree programs ranging from the Sciences to Business
        and the Arts from prestigious Nigerian universities like Ahmadu Bello
        University, Obafemi Awolowo University and Babcock University. Learn
        more about our programs <Link to="/programs">here</Link>.
      </p>
    </>
  );
};

export default MastersDegree;
