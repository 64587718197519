import React from "react";
import "../assets/styles/pageStyles/multipleschpartner.scss";
import { institutions } from "../data/instututionsAndCourses";
import { useParams, Redirect } from "react-router-dom";
import PartnerInstitutionsCard from "../components/partnerInstituionsCard";

const MultipleSchPartner = () => {
    const { id } = useParams();

    const institution = institutions.find(
        (institution) => institution.id === Number(id)
    );

    const { name, logo } = institution || {};
    const partnerInstitutions = institution.institutions.map(institution => institutions.find(i => i.id === institution))

    if (!id || !institution) {
        return <Redirect to={"/"} />;
    }

    return (
        <>
            <div className="multiple-institution-partner-banner">
                <div className="container">
                    <h2>{name} </h2>
                    <p></p>
                </div>
            </div>
            <div className="container about-university">
                {partnerInstitutions.map(institution => <PartnerInstitutionsCard id={id} logo={logo} institution={institution} />)}
            </div>
        </>
    );
};

export default MultipleSchPartner;
